<template>
  <div :class="$style.tooltip">
    <div
      ref="targetEl"
      :class="$style.target"
      @mouseenter="isFloatingElVisible = true"
      @mouseleave="isFloatingElVisible = false"
    >
      <slot name="target" />
    </div>

    <div
      ref="floatingEl"
      :class="[$style.floating, isFloatingElVisible && $style.floatingVisible]"
    >
      <div ref="arrowEl" :class="$style.arrow" />
      <slot name="content" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Placement } from '@floating-ui/dom'
import {
  arrow,
  autoUpdate,
  computePosition,
  flip,
  limitShift,
  offset,
  shift,
} from '@floating-ui/dom'
import { assign, split } from 'lodash-es'
import { onBeforeUnmount, PropType, ref, watch } from 'vue'

const props = defineProps({
  placement: {
    type: String as PropType<Placement>,
    default: 'top',
  },
})

const isFloatingElVisible = ref(false)
const targetEl = ref<HTMLElement>()
const floatingEl = ref<HTMLElement>()
const arrowEl = ref<HTMLElement>()

const computeTooltipPosition = async () => {
  const { x, y, middlewareData, placement } = await computePosition(
    targetEl.value!,
    floatingEl.value!,
    {
      placement: props.placement,
      middleware: [
        offset(10),
        flip(),
        shift({ limiter: limitShift(), padding: 5 }),
        arrow({ element: arrowEl.value!, padding: 5 }),
      ],
    }
  )

  assign(floatingEl.value!.style, {
    left: `${x}px`,
    top: `${y}px`,
  })

  if (!middlewareData.arrow || !arrowEl.value) return

  const { x: arrowX, y: arrowY } = middlewareData.arrow

  const side = split(placement, '-')[0]
  const oppositeSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[side]

  assign(arrowEl.value.style, {
    left: x != null ? `${arrowX}px` : '',
    top: y != null ? `${arrowY}px` : '',
    right: '',
    bottom: '',
    [oppositeSide!]: '-4px',
    [side]: '',
  })
}

let cleanup: () => void

watch(isFloatingElVisible, (val) => {
  if (val) {
    if (!targetEl.value || !floatingEl.value) return

    cleanup = autoUpdate(
      targetEl.value,
      floatingEl.value,
      computeTooltipPosition,
      { animationFrame: true }
    )
    return
  }

  if (!cleanup) return

  cleanup()
})

onBeforeUnmount(() => {
  if (!cleanup) return
  cleanup()
})
</script>

<style lang="scss" module>
.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
}

.target {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.floating {
  @include typo-sub-text;

  position: absolute;
  top: 0;
  left: 0;

  z-index: 1000;

  color: $gray-1000;

  text-align: left;

  background: $white;
  box-shadow: 0px 2px 16px rgba(44, 35, 2, 0.12);
  visibility: hidden;

  &.floatingVisible {
    min-width: 150px;

    max-width: 256px;

    padding: 16px;
    border-radius: 8px;

    visibility: visible;
  }
}

.arrow {
  position: absolute;

  width: 8px;

  height: 8px;

  background-color: $white;
  transform: rotate(45deg);
}
</style>
