enum PaymentMethodType {
  CARD = 'card',
  SEPA_DEBIT = 'sepa_debit',
}

enum PaymentServiceProvider {
  GOCARDLESS = 'gocardless',
  STRIPE = 'stripe',
}

enum PaymentStep {
  FORM,
  TERMS,
  REVIEW,
}

enum PaymentFrequency {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

enum PaymentStatus {
  FAILED = 'failed',
  PAID = 'paid',
  UNPAID = 'unpaid',
}

enum PaymentMethodStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  DELETED = 'DELETED_BY_CUSTOMER',
}

interface PaymentMethod {
  bank_code: string | null
  bank_name: string
  card_brand: string
  card_description: string
  card_issuer: string
  country: string
  created_at: string
  expiration_at: string
  id: number
  iin: string
  is_authorized: boolean
  is_default: boolean
  last4: string
  last_digits: string
  mandate_pdf_url: string | null
  mandate_reference: string | null
  masked_iban: string
  payment_service_provider_id: number
  status: PaymentMethodStatus
  type: PaymentMethodType
}

interface Mandate {
  payment_service_provider: PaymentServiceProvider
  customer_bank_account_token: any
  email: string
  first_name: string
  last_name: string
  address: string
  city: string
  postal_code: string
  country_code: string
  psp_id: null
}

export {
  Mandate,
  PaymentMethod,
  PaymentServiceProvider,
  PaymentStep,
  PaymentFrequency,
  PaymentStatus,
  PaymentMethodType,
  PaymentMethodStatus,
}
