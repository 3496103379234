import { NumberFormats } from '@intlify/core-base'
import { reduce, values } from 'lodash-es'
import { createI18n } from 'vue-i18n'

import de from 'src/i18n/locales/de.json'
import en from 'src/i18n/locales/en.json'
import es from 'src/i18n/locales/es.json'
import fr from 'src/i18n/locales/fr.json'
import {
  AVAILABLE_LOCALES,
  DEFAULT_LOCALE,
  FALLBACK_LOCALE,
} from 'src/store/locale'
import { Language } from 'src/types/user'

const numberFormats = {
  ...reduce(
    values(Language),
    (acc: NumberFormats, locale) => {
      acc[locale] = {
        currency: {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol',
          minimumFractionDigits: 0,
        },
        price: {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
        percent: {
          style: 'percent',
          useGrouping: false,
          maximumFractionDigits: 2,
        },
      }
      return acc
    },
    {}
  ),
}

const i18n = createI18n({
  availableLocales: AVAILABLE_LOCALES,
  fallbackLocale: FALLBACK_LOCALE,
  legacy: false,
  locale: DEFAULT_LOCALE,
  globalInjection: true,
  messages: { de, en, es, fr },
  numberFormats,
})

const setI18nLanguage = (lang: Language) => {
  const { locale } = i18n.global
  locale.value = lang
  document.querySelector('html')?.setAttribute('lang', lang)
}

export { i18n, setI18nLanguage }
