import { Api } from 'src/api'

import { LoginParams, LoginResponse, SignupParams } from './types'

const login = async (payload: LoginParams) => {
  const { data } = await Api.post<LoginResponse>('/v1/auth/login', payload)
  return data
}

const sendOtp = async (partnerJwt: string) =>
  Api.get('/v1/auth/otp', { headers: { 'partner-jwt': partnerJwt } })

const verifyOtp = async (code: string, partnerJwt: string) =>
  Api.post(
    '/v1/auth/otp/verify',
    { verification_code: code },
    { headers: { 'partner-jwt': partnerJwt } }
  )

const logoutAPI = () => Api.post('/v1/auth/logout')

const ssoLogin = async (token: string) =>
  Api.get(`/v1/auth/sso/login?token=${token}`)

const signup = (data: SignupParams) =>
  Api.post('/v1/auth/signup', data, { params: { lang: true } })

export { sendOtp, verifyOtp, login, ssoLogin, logoutAPI, signup }
