import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'
import { useNotification } from 'src/composables/useNotification'
import { i18n } from 'src/i18n'
import { useWorkspace } from 'src/store/workspace'

export const workspaceRoutes: RouteRecordRaw[] = [
  {
    path: '/workspace/settings',
    name: 'workspaceSettings',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const workspaceSettingstModule = await import(
          '../WorkspaceSettings.vue'
        )
        return workspaceSettingstModule
      } finally {
        await finishProgress()
      }
    },
    meta: {
      titleKey: 'WorkspaceSettings.title',
    },
    beforeEnter: async () => {
      const { invitedWsId, currentWsId, set: setWs } = useWorkspace()

      if (invitedWsId.value) {
        await setWs.currentWorkspace(invitedWsId.value)
        setWs.invitedWsId(null)
      }
      const wsId = currentWsId.value

      if (wsId) return

      const { t } = i18n.global
      const { showNotification } = useNotification()

      showNotification({
        message: t('Errors.roleAuth'),
        theme: 'danger',
      })

      return { name: 'newQuote' }
    },
  },
]
