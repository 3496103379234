import { RouteRecordRaw } from 'vue-router'

import { accountRoutes } from 'src/modules/Account/routes'
import { authRoutes } from 'src/modules/Auth/routes'
import { billingRoutes } from 'src/modules/Billing/routes'
import { consumerDetailsRoutes } from 'src/modules/ConsumerDetails/routes'
import { consumersRoutes } from 'src/modules/Consumers/routes'
import { contractDetailsRoutes } from 'src/modules/ContractDetails/routes'
import { contractDocumentRoutes } from 'src/modules/ContractDocument/routes'
import { contractsRoutes } from 'src/modules/Contracts/routes'
import { couponDetailsRoutes } from 'src/modules/CouponDetails/routes'
import { couponsRoutes } from 'src/modules/Coupons/routes'
import { dashboardRoutes } from 'src/modules/Dashboard/routes'
import { developersRoutes } from 'src/modules/Developers/routes'
import { errorRoutes } from 'src/modules/Error/routes'
import { findRoutes } from 'src/modules/Find/routes'
import { newCouponRoutes } from 'src/modules/NewCoupon/routes'
import { quoteRoutes } from 'src/modules/NewQuote/routes'
import { partnerDetailsRoutes } from 'src/modules/PartnerDetails/routes'
import { partnersRoutes } from 'src/modules/Partners/routes'
import { prospectsRoutes } from 'src/modules/Prospects/routes'
import { resourcesRoutes } from 'src/modules/Resources/routes'
import { settingsRoutes } from 'src/modules/Settings/routes'
import { sunsetRoutes } from 'src/modules/Sunset/routes'
import { tenantFileDetailsRoutes } from 'src/modules/TenantFileDetails/routes'
import { tenantFilesRoutes } from 'src/modules/TenantFiles/routes'
import { workspaceRoutes } from 'src/modules/WorkspaceSettings/routes'

export const routes: RouteRecordRaw[] = [
  ...authRoutes,
  ...quoteRoutes,
  ...prospectsRoutes,
  ...contractsRoutes,
  ...contractDetailsRoutes,
  ...contractDocumentRoutes,
  ...developersRoutes,
  ...errorRoutes,
  ...findRoutes,
  ...settingsRoutes,
  ...accountRoutes,
  ...workspaceRoutes,
  ...resourcesRoutes,
  ...consumersRoutes,
  ...consumerDetailsRoutes,
  ...dashboardRoutes,
  ...billingRoutes,
  ...tenantFilesRoutes,
  ...tenantFileDetailsRoutes,
  ...partnersRoutes,
  ...partnerDetailsRoutes,
  ...sunsetRoutes,
  ...couponsRoutes,
  ...newCouponRoutes,
  ...couponDetailsRoutes,
]
