<template>
  <div v-if="circle" :class="$style.lukoProgressBarCircle"></div>
  <div v-else :class="$style.lukoProgressBar">
    <div :class="$style.filler"></div>
  </div>
</template>

<script setup lang="ts">
import { useTheme } from '@demain-es/lukompo'

const props = defineProps({
  modelValue: { type: Number, default: 0 }, // as percentage
  circle: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  theme: { type: String, default: undefined },
})

const { getThemeColors } = useTheme()
const colors = getThemeColors(props.theme, { disabled: props.disabled })
</script>

<style lang="scss" module>
.lukoProgressBar {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  border-radius: 4px;

  background-color: $gray-100;
}

.filler {
  width: calc(v-bind(modelValue) * 1%);
  height: 100%;
  border-radius: 4px;

  background-color: v-bind('colors.backgroundColor');

  transition: width 0.2s ease-in-out;
}

.lukoProgressBarCircle {
  width: 24px;
  height: 24px;
  border-radius: 50%;

  color: v-bind('colors.backgroundColor');

  background: conic-gradient(
    v-bind('colors.backgroundColor') calc(v-bind(modelValue) * 1%),
    transparent 0
  );
}
</style>
