import { createGtm } from '@gtm-support/vue-gtm'
import { App } from 'vue'

import { router } from 'src/router'
import { IS_DEV } from 'src/store/constants'

export const install = (app: App) => {
  app.use(
    createGtm({
      id: IS_DEV ? 'GTM-5BXGRZX' : 'GTM-55T493N',
      defer: false,
      vueRouter: router,
    })
  )
}
