import { ref } from 'vue'

interface TypeformOptions {
  formId: string
  height: number
  width: number
  hidden?: Record<string, string>
  onSubmit?: Function
}

const isTypeformDisplayed = ref(false)
const typeformOptions = ref<TypeformOptions>()

const showTypeform = (options: TypeformOptions) => {
  isTypeformDisplayed.value = true
  typeformOptions.value = options
}

export const useEmbeddedTypeform = () => ({
  isTypeformDisplayed,
  showTypeform,
  typeformOptions,
})
