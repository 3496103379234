import { RouteRecordRaw } from 'vue-router'

import { sleep } from 'src/lib/webUtils'
import { usePartner } from 'src/store/partner'

export const tenantFileDetailsRoutes: RouteRecordRaw[] = [
  {
    path: '/tenant-file/:tenantFileUuid',
    component: () => import('../TenantFileDetails.vue'),
    beforeEnter: async () => {
      const { currentPartner, isRealEstatePartner } = usePartner()

      while (!currentPartner.value) {
        await sleep(100)
      }

      if (!isRealEstatePartner.value) return

      return { name: 'sunset' }
    },
  },
]
