import { find, includes, uniqBy } from 'lodash-es'
import { computed, ref, watch } from 'vue'

import {
  getPartner,
  getPartnerKey,
  regeneratePartnerKey,
  uploadLogo,
} from 'src/api/partner'
import { getUserPartners } from 'src/api/user/partners'
import { UserPartnersSearchParams } from 'src/api/user/types'
import { useLocalStorage } from 'src/lib/localStorage'
import { launchIntercom, shutdownIntercom } from 'src/plugins/intercom'
import { Country } from 'src/types/address'
import {
  Partner,
  PartnerId,
  PartnerIndustry,
  SimpleUserPartner,
} from 'src/types/partner'
import { ProductCode } from 'src/types/product'
import { Acl } from 'src/types/user'
import { WorkspaceId } from 'src/types/workspace'

import { useProduct } from './product'
import { useUser } from './user'
import { useWorkspace } from './workspace'

const currentUserPartner = ref<SimpleUserPartner | null>()
const currentUserPartners = ref<SimpleUserPartner[]>([])
const totalCurrentUserPartners = ref(0)
const currentPartner = ref<Partner | null>(null)
const currentKey = ref('')
const currentPartnerId = ref<number>(
  Number(useLocalStorage().getStorageItem('currentPartnerId'))
)
const currentPartnerUuid = computed(() => currentPartner.value?.uuid)

watch(currentPartner, () => {
  currentPartner.value?.workspace_id === WorkspaceId.NOVELIA ||
  isLukoPartner.value ||
  isRealEstatePartner.value
    ? shutdownIntercom()
    : launchIntercom()
})

const isLukoPartner = computed(() => currentPartnerId.value === PartnerId.LUKO)

const isTestPartner = computed(
  () => currentPartnerId.value === PartnerId.FRONT_TEST
)

const isCheck24Partner = computed(
  () => currentPartnerId.value === PartnerId.CHECK24
)

const isRealEstatePartner = computed(
  () => currentPartner.value?.industry === PartnerIndustry.REAL_ESTATE
)

const hasGroupContract = computed(
  () => currentPartner.value?.has_group_contract
)

const isGermanPartner = computed(
  () => currentPartner.value?.country === Country.GERMANY
)

const isCommissionEnabled = computed(() => {
  const { allow_commission, min_commission, max_commission } =
    currentPartner.value?.config ?? {}
  if (!allow_commission || min_commission === max_commission) return false

  const { currentProduct } = useProduct()
  const { isNoveliaWs } = useWorkspace()
  return (
    isNoveliaWs.value ||
    includes(
      [
        ProductCode.FR_HOME_FLAT,
        ProductCode.FR_LANDLORD_FLAT,
        ProductCode.FR_HOME,
        ProductCode.FR_LANDLORD,
      ],
      currentProduct.value
    )
  )
})

const updateCurrentUserPartners = async (params?: UserPartnersSearchParams) => {
  const { currentWorkspace } = useWorkspace()
  const { partners, total } = await getUserPartners({
    limit: params?.limit ?? 10,
    offset: params?.offset ?? 0,
    ...(currentWorkspace.value?.id && {
      workspace_id: currentWorkspace.value.id,
    }),
    ...(params?.name && { name: params.name }),
  })

  currentUserPartners.value = uniqBy(
    [
      ...currentUserPartners.value,
      ...partners,
      ...(currentUserPartner.value ? [currentUserPartner.value] : []),
    ],
    'id'
  )
  totalCurrentUserPartners.value = total
}

const getUserPartnerById = async (partnerId: number) => {
  const { partners } = await getUserPartners({ id: partnerId })

  return partners[0] ?? null
}

const isPartnerAssociated = async (partnerId: number) => {
  if (!partnerId) return false

  let userPartner = find(
    currentUserPartners.value,
    ({ id }) => id === partnerId
  )

  if (userPartner) {
    currentUserPartner.value = userPartner
    return true
  }

  userPartner = await getUserPartnerById(partnerId)

  if (!userPartner) return false

  currentUserPartner.value = userPartner

  return true
}

const setCurrentPartner = async (partnerId?: number) => {
  if (partnerId) {
    if (currentPartner.value?.id === partnerId) return

    if (!(await isPartnerAssociated(partnerId))) return

    updateCurrentPartnerId(partnerId)
    await refreshPartner()

    return
  }

  if (await isPartnerAssociated(currentPartnerId.value)) {
    partnerId = currentPartnerId.value

    if (currentPartner.value?.id === partnerId) return

    updateCurrentPartnerId(partnerId)
    await refreshPartner()

    return
  }

  const { partners } = await getUserPartners({ offset: 0, limit: 1 })

  partnerId = partners[0]?.id

  if (
    !partnerId ||
    currentPartner.value?.id === partnerId ||
    !(await isPartnerAssociated(partnerId))
  )
    return

  updateCurrentPartnerId(partnerId)
  await refreshPartner()
}

const refreshPartner = async () => {
  const partner = await getPartner(currentPartnerId.value)
  currentPartner.value = partner

  const { currentACL } = useUser()

  currentACL.value = (currentUserPartner.value?.acl as Acl) || null

  const { set: setWs } = useWorkspace()
  setWs.mainColorFromPartner(currentPartner.value?.main_color)
  setWs.currentWorkspaceId(currentPartner.value?.workspace_id ?? null)

  await refreshCurrentKey()
}

const regenerateCurrentKey = async () => {
  if (!currentPartnerUuid.value) return

  const { key } = await regeneratePartnerKey(currentPartnerUuid.value)
  currentKey.value = key
}

const refreshCurrentKey = async () => {
  if (!currentPartnerId.value) return

  const { key } = await getPartnerKey(currentPartnerId.value)
  currentKey.value = key
}

const updateCurrentPartnerId = (partnerId: number) => {
  currentPartnerId.value = partnerId

  const { setStorageItem } = useLocalStorage()
  setStorageItem('currentPartnerId', partnerId.toString())
}

const uploadPartnerLogo = async (file: File) => {
  await uploadLogo(currentPartner.value!.id, file)
  await refreshPartner()
}

const resetPartner = () => {
  currentKey.value = ''
  currentPartner.value = null
  currentUserPartner.value = null
  currentUserPartners.value = []
}

const hasOrias = computed(() => !!currentPartner?.value?.orias_number)

export const usePartner = () => ({
  hasOrias,
  currentPartner,
  currentKey,
  currentPartnerId,
  isLukoPartner,
  isTestPartner,
  isCheck24Partner,
  isRealEstatePartner,
  isGermanPartner,
  isCommissionEnabled,
  currentUserPartner,
  currentUserPartners,
  totalCurrentUserPartners,
  setCurrentPartner,
  refreshCurrentKey,
  uploadPartnerLogo,
  hasGroupContract,
  regenerateCurrentKey,
  refreshPartner,
  resetPartner,
  updateCurrentUserPartners,
  currentPartnerUuid,
})
