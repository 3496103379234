import { find, split, values } from 'lodash-es'
import { computed } from 'vue'

import { getCodeForCountry } from 'src/lib/address'
import { CountryCode } from 'src/types/address'

import { usePartner } from './partner'
import { useProduct } from './product'

const currentCountryCode = computed(() => {
  const { currentPartner, isLukoPartner, isTestPartner } = usePartner()

  if (!currentPartner.value) return

  if (!(isLukoPartner.value || isTestPartner.value))
    return getCodeForCountry(currentPartner.value.country)

  const { currentProduct } = useProduct()
  if (!currentProduct.value) return CountryCode.FRANCE

  const productCountryCode = split(currentProduct.value, '_')[0]

  return find(values(CountryCode), (code) => code === productCountryCode)
})

const isFrance = computed(() => currentCountryCode.value === CountryCode.FRANCE)

const isGermany = computed(
  () => currentCountryCode.value === CountryCode.GERMANY
)

export const useCountry = () => ({
  currentCountryCode,
  isGermany,
  isFrance,
})
