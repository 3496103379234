import Color from 'color'

const CONTRASTS = [
  0.1, 0.15, 0.25, 0.3, 0.4, 0.5, 0.7, 0.85, 1, 0.85, 0.7, 0.5, 0.3, 0.15,
]
const PALETTE_SIZE = CONTRASTS.length

const generatePalette = (mainColor: Color): string[] => {
  const colorPalette = []

  for (let i = 0; i < PALETTE_SIZE; i++) {
    if (i < 8) {
      colorPalette.push(Color('white').mix(mainColor, CONTRASTS[i]).hex())
    } else if (i === 8) {
      colorPalette.push(mainColor.hex())
    } else if (i > 8) {
      colorPalette.push(Color('black').mix(mainColor, CONTRASTS[i]).hex())
    }
  }
  return colorPalette
}

export { generatePalette }
