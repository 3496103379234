import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'

export const couponDetailsRoutes: RouteRecordRaw[] = [
  {
    path: '/coupon/:couponCode',
    name: 'couponDetails',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const couponDetails = await import('../CouponDetails.vue')
        return couponDetails
      } finally {
        await finishProgress()
      }
    },
    meta: {
      titleKey: 'Coupons.Details.title',
    },
  },
]
