<template>
  <div
    :class="[
      $style.option,
      isVertical && $style.optionVertical,
      isDisabled && $style.isDisabled,
    ]"
    :data-luko-tracking="dataLukoTracking"
    @click="$emit('click', $event)"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MenuOption',
  props: {
    isVertical: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    dataLukoTracking: {
      type: String,
      default: 'MenuOption',
    },
  },
  emits: ['click'],
})
</script>

<style lang="scss" module>
.option {
  @include typo-sub-text;
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;

  color: $gray-700;

  &:hover {
    color: $gray-1000;

    background-color: $gray-50;
  }
}

.optionVertical {
  flex-direction: column;
  align-items: flex-start;
}

.isDisabled {
  color: $gray-200;

  pointer-events: none;
}
</style>
