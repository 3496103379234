import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'

export const partnersRoutes: RouteRecordRaw[] = [
  {
    path: '/partners',
    name: 'partners',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const partnersModule = await import('../Partners.vue')
        return partnersModule
      } finally {
        await finishProgress()
      }
    },
    meta: {
      titleKey: 'Partners.metaTitle',
    },
  },
]
