<template>
  <div data-luko-tracking="NavBar" :class="$style.navbar">
    <CompanyMenu />
    <div :key="String(navbarKey)" :class="$style.navbarItems">
      <div v-if="isNoveliaWs" :class="$style.cobrandingText">
        <img
          :class="$style.noveliaIcon"
          src="../../assets/images/novelia-logo.svg"
          alt="Novelia logo"
        />
        {{ t('Navbar.noveliaCobrandingText') }}
        <img
          :class="$style.lukoIcon"
          src="../../assets/images/luko-logo.svg"
          alt="Luko logo"
        />
      </div>
      <div
        v-if="
          !isWorkspaceContext &&
          !isGermanPartner &&
          !isRealEstatePartner &&
          canCreateNewQuote
        "
        :class="$style.newQuoteBtn"
      >
        <Link :to="{ name: 'newQuote' }" :disabled="isNoveliaWs">
          <LukoButton
            small
            :disabled="isNoveliaWs"
            :icon="AddDocument"
            @click.stop.prevent="onNewQuoteClick"
          >
            {{ t('Navbar.button') }}
          </LukoButton>
        </Link>
        <Tooltip v-if="isNoveliaWs" placement="bottom">
          <template #target>
            <LukoIcon :svg="Info" width="16" height="16" color="gray300" />
          </template>
          <template #content>
            <div :class="$style.infoText">
              {{ t('Errors.currentlyUnavailable') }}
            </div>
          </template>
        </Tooltip>
      </div>
      <NavList v-if="isWorkspaceContext" :list="workspaceList" />
      <NavList v-else-if="hasShortList" :list="shortList" />
      <template v-else>
        <NavList :list="isRealEstatePartner ? realEstateMainList : mainList" />
        <NavList :list="techList" />
        <NavList :list="secondaryList" />
      </template>
      <div :class="$style.navBottom">
        <NavList
          v-if="!isWorkspaceContext || isNoveliaWs"
          :list="externalLinksList"
        />
      </div>
    </div>
    <UserMenu />
  </div>
</template>

<script setup lang="ts">
import { AddDocument, Info, LukoButton, LukoIcon } from '@demain-es/lukompo'
import { includes } from 'lodash-es'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { Link } from 'src/components/Link'
import { Tooltip } from 'src/components/Tooltip'
import { isAuthorized } from 'src/store/auth'
import { usePartner } from 'src/store/partner'
import { useWorkspace } from 'src/store/workspace'
import { PartnerType } from 'src/types/partner'

import { useSteps } from '../NewQuote/composables/useSteps'
import { CompanyMenu, NavList, UserMenu } from './components'
import { useLists } from './composables'

const { t } = useI18n()
const { isWorkspaceContext, isNoveliaWs } = useWorkspace()
const {
  currentKey,
  currentPartner,
  isCheck24Partner,
  isRealEstatePartner,
  isGermanPartner,
} = usePartner()

const hasShortList = computed(() => {
  if (isRealEstatePartner.value) return false

  return (
    includes(
      [PartnerType.QUOTE_PROVIDER, PartnerType.TRAFFIC_PROVIDER],
      currentPartner.value?.type
    ) || isCheck24Partner.value
  )
})

const {
  mainList,
  realEstateMainList,
  secondaryList,
  techList,
  shortList,
  externalLinksList,
  workspaceList,
} = useLists()

const { currentRoute, push, replace } = useRouter()

const onNewQuoteClick = () => {
  const { initStepper } = useSteps()
  initStepper()

  if (
    currentRoute.value.name === 'newQuote' &&
    !currentRoute.value.params?.key
  ) {
    replace({ name: 'newQuote', force: true })
    return
  }
  push({ name: 'newQuote', force: true })
}

const canCreateNewQuote = computed(() => isAuthorized('newQuote'))

const navbarKey = ref(0)
const { currentWorkspace } = useWorkspace()
watch([currentKey, currentWorkspace], () => {
  navbarKey.value = 1 - navbarKey.value
})
</script>

<style lang="scss" module>
.navbar {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  width: 256px;
  min-width: 256px;
  min-height: 100vh;
  max-height: 100vh;
  padding: 24px 16px;

  background: $gray-50;
}

.navbarItems {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  overflow: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.lukoIcon {
  width: 25px;

  vertical-align: middle;
}
.noveliaIcon {
  width: 65px;
}
.cobrandingText {
  display: block;
  max-width: 155px;
  margin-left: 10px;

  color: $gray-500;
  text-align: left;
  @include typo-sub-text;
}
.navBottom {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
}
.newQuoteBtn {
  display: flex;
  gap: 8px;
  align-items: center;

  cursor: v-bind('isNoveliaWs ? `not-allowed` : `pointer`');
}
</style>
