import { Warning } from '@demain-es/lukompo'
import { differenceInCalendarMonths } from 'date-fns'
import {
  entries,
  filter,
  forEach,
  includes,
  indexOf,
  isEmpty,
  keys,
  slice,
  some,
  toLower,
} from 'lodash-es'
import { computed, nextTick, reactive, ref } from 'vue'

import { getProspectById } from 'src/api/prospect'
import { useNotification } from 'src/composables/useNotification'
import { i18n } from 'src/i18n'
import { useLocalStorage } from 'src/lib/localStorage'
import { useDefaults } from 'src/modules/NewQuote/composables/useDefaults'
import { useProspect } from 'src/modules/NewQuote/composables/useProspect'
import { useQuote } from 'src/modules/NewQuote/composables/useQuote'
import {
  // AFTER_PAYMENT_STEPS,
  getProductCode,
  PRODUCT_TYPE_SELECTION_STEP,
  skipStep,
  STEPS,
} from 'src/modules/NewQuote/lib'
import { router } from 'src/router'
import { useConfig } from 'src/store/config'
import { usePartner } from 'src/store/partner'
// import { usePartner } from 'src/store/partner'
import { useProduct } from 'src/store/product'
import { PolicyType, ResidenceType } from 'src/types/enums'
import { ProductCode } from 'src/types/product'
import { Prospect } from 'src/types/prospect'

import { ClaimNumber } from '../types'

const {
  getProspectData,
  setProspectData,
  saveProspectData,
  validateProspectData,
  populateProspectDataFromContract,
} = useProspect()
const {
  currentProduct,
  setDefaultProduct,
  setProduct,
  isCurrentProductFrLandlord,
} = useProduct()

const currentProspectKey = ref('')
const currentStep = ref('')
const stepHistory = ref<string[]>([])
const allowSubscription = computed(() => {
  const now = new Date()
  const startDate = new Date(getProspectData('start_date'))

  if (differenceInCalendarMonths(startDate, now) >= 7) return false

  return true
})
const completedSteps: Record<string, any> = reactive({})
const setCompletedSteps = (step: string, isComplete: boolean) =>
  (completedSteps[step] = isComplete)

const stepList = computed(() => {
  // const { hasOrias } = usePartner()

  if (!currentProduct.value) return [PRODUCT_TYPE_SELECTION_STEP]

  const currentStepList = [...(STEPS.value[currentProduct.value] ?? [])]

  const claims = getProspectData('home.main_asset.claim_history')
  const isHouse =
    getProspectData('home.main_asset.type') === ResidenceType.HOUSE
  if (
    isCurrentProductFrLandlord.value && isHouse
      ? includes(
          [ClaimNumber.ONE, ClaimNumber.TWO, ClaimNumber.THREE_PLUS],
          claims
        )
      : claims === ClaimNumber.THREE_PLUS
  ) {
    const claimHistoryPosition = indexOf(currentStepList, 'ClaimHistory')
    return slice(currentStepList, 0, claimHistoryPosition + 1)
  }

  const hasFormerCancellation = getProspectData('formerCancellation')
  if (hasFormerCancellation) {
    const formerCancellationPosition = indexOf(
      currentStepList,
      'FormerCancellation'
    )
    return slice(currentStepList, 0, formerCancellationPosition + 1)
  }

  const borrowerCovered = getProspectData('user.borrower')
  if (borrowerCovered && borrowerCovered.length > 1) {
    const PersonCoveredPosition = indexOf(currentStepList, 'PersonCovered')
    return slice(currentStepList, 0, PersonCoveredPosition + 1)
  }

  const now = new Date()
  const startDate = new Date(getProspectData('start_date'))

  if (differenceInCalendarMonths(startDate, now) >= 7) {
    const guaranteesPosition = indexOf(currentStepList, 'Guarantees')
    return slice(currentStepList, 0, guaranteesPosition + 1)
  }

  // const formerDetailsPosition = currentStepList.indexOf('FormerDetails')

  // if (!allowSubscription.value) {
  //   return [...currentStepList.slice(0, formerDetailsPosition + 1)]
  // }

  // if (!hasOrias.value)
  //   return [
  //     ...currentStepList.slice(0, formerDetailsPosition + 1),
  //     ...AFTER_PAYMENT_STEPS,
  //   ]

  return currentStepList
})

const isAfterGuarantees = computed(() => {
  const currentPosition = indexOf(stepList.value, currentStep.value)
  const guaranteesPosition = indexOf(stepList.value, 'Guarantees')

  if (guaranteesPosition === -1) return false

  return guaranteesPosition <= currentPosition
})

const isStepAfterGuarantees = (stepName?: string) => {
  if (!stepName) return isAfterGuarantees.value

  const currentPosition = indexOf(stepList.value, stepName)
  const guaranteesPosition = indexOf(stepList.value, 'Guarantees')

  if (guaranteesPosition === -1) return false

  return guaranteesPosition <= currentPosition
}

const setLastEditedStep = (stepName: string) => {
  const { setStorageItem } = useLocalStorage()
  setStorageItem(currentProspectKey.value, stepName)
}

const stepFuture = computed(() =>
  filter(stepList.value, (step) =>
    includes(stepHistory.value, step) ? false : !skipStep(step)
  )
)

const goNext = async (): Promise<boolean> => {
  await nextTick()
  if (currentStep.value && !completedSteps[currentStep.value]) return false
  const nextStep = stepList.value
    .filter((step) => !stepHistory.value.includes(step))
    .find((step) => !skipStep(step))

  if (!nextStep) return false

  if (nextStep === 'Guarantees') {
    const { doQuote, doQuoteNow } = useQuote()
    stepHistory.value.push(nextStep)
    currentStep.value = nextStep

    const product = getProductCode(
      getProspectData('home.main_asset.is_main'),
      getProspectData('home.main_asset.type'),
      getProspectData('home.main_asset.owner_type'),
      !!getProspectData('nvei.main_asset')
    )

    if (product !== currentProduct.value) setProduct(product)

    const isPaidQuote = !!getProspectData('paid_date')

    if (isPaidQuote) {
      await doQuoteNow({ isPaidQuoteCall: isPaidQuote })
      return true
    }

    const { mergeDefaults } = useDefaults()
    await mergeDefaults()

    if (!getProspectData('key')) await saveProspect()

    doQuote()
    return true
  }

  if (isAfterGuarantees.value) setLastEditedStep(nextStep)

  stepHistory.value.push(nextStep)
  currentStep.value = nextStep

  if (
    toLower(getProspectData('termination_reason')) ===
      toLower(PolicyType.MOVING_WITH_LUKO) &&
    includes(['FormerContract', 'FormerDetails'], currentStep.value)
  )
    return await goNext()
  return true
}

const revertTo = (step: string) => {
  if (!stepHistory.value.includes(step)) return

  while (stepHistory.value[stepHistory.value.length - 1] !== step) {
    stepHistory.value.pop()
    currentStep.value = stepHistory.value[stepHistory.value.length - 1]
  }

  if (isAfterGuarantees.value) setLastEditedStep(currentStep.value)
}

const parseProspectData = (query: Record<string, string>) => {
  const exclusionFromParse = [
    'home.main_asset.postcode',
    'home.main_asset.claim_history',
    'user.subscriber.phone',
  ]

  const parseValueFromObject = (key: string, value: string) => {
    if (['true', 'false'].includes(value)) {
      if (value === 'false') return false
      return true
    }
    if (value === 'null') return null
    if (!exclusionFromParse.includes(key) && !isNaN(Number(value)))
      return Number(value)
    return value
  }

  const cleanQueryData: Record<string, any> = {}

  forEach(entries(query), ([key, value]) => {
    const cleanKey = key.replace('$.', '')
    const cleanValue = parseValueFromObject(cleanKey, value)

    cleanQueryData[cleanKey] = cleanValue
  })

  if (cleanQueryData['product'] === ProductCode.FR_MORTGAGE) {
    const { currentPartner } = usePartner()
    const isProductTypeStepIncluded = some(
      currentPartner.value?.config?.eligible_product_funnel,
      (val) =>
        includes(
          [
            ProductCode.FR_MORTGAGE,
            ProductCode.FR_ESCOOTER,
            ProductCode.FR_RENT,
          ],
          val
        )
    )

    goFast(
      isProductTypeStepIncluded
        ? PRODUCT_TYPE_SELECTION_STEP
        : stepList.value[0]
    )

    const { t } = i18n.global
    const { showNotification } = useNotification()
    showNotification({
      message: t('Errors.notAvailable', {
        resource: t('App.product.FR_MORTGAGE'),
      }),
      icon: Warning,
      theme: 'danger',
    })

    return
  }

  forEach(entries(cleanQueryData), ([key, value]) => {
    setProspectData(key, value)
  })

  validateProspectData()
  goFast('StartDate')
}

const { resetQuote } = useQuote()
const initStepper = (query?: Record<string, any>) => {
  setProspectData('', {})
  clearStepData()
  setDefaultProduct()

  if (!isEmpty(query)) parseProspectData(query!)

  if (currentProduct.value) {
    forEach(STEPS.value[currentProduct.value], (step) =>
      setCompletedSteps(step, false)
    )
  }

  goNext()
}

const clearStepData = () => {
  currentStep.value = ''
  stepHistory.value = []
  currentProspectKey.value = ''
  if (currentProduct.value) {
    forEach(STEPS.value[currentProduct.value], (step) =>
      setCompletedSteps(step, false)
    )
  }
  resetQuote()
}

const lastStepSaved = ref('')
const hasSaved = computed(
  () =>
    !!getProspectData('paid_date') || lastStepSaved.value === currentStep.value
)

const isKeyWatcherToBeSkipped = ref(false)
const saveProspect = async () => {
  if (getProspectData('paid_date')) return

  lastStepSaved.value = currentStep.value
  await saveProspectData()

  const { currentRoute } = router
  if (currentRoute.value.params?.key) return

  currentProspectKey.value = getProspectData('key')

  currentRoute.value.params['key'] = getProspectData('key')
  isKeyWatcherToBeSkipped.value = true
  const { replace } = router
  replace({
    name: currentRoute.value.name!,
    params: currentRoute.value.params,
    force: true,
  })
}

const downloadProspectRecap = async () => {
  const { doQuoteNow } = useQuote()
  await doQuoteNow()

  const { apiBaseUrl } = useConfig()
  const key = getProspectData('key')
  const url = new URL(`/v1/prospect/${key}/documents/offer.pdf`, apiBaseUrl)
  window.open(url, '_blank')
}

const goFast = async (stepName?: string | null) => {
  if (currentStep.value === stepName) return

  const canPass = await goNext()
  if (canPass) goFast(stepName)
}

const isLoadingProspect = ref(false)
const loadProspect = async (prospectKey: string) => {
  isLoadingProspect.value = true

  setProspectData('', {})
  clearStepData()
  setDefaultProduct()
  currentProspectKey.value = prospectKey
  const prospect = await getProspectById(prospectKey)
  forEach(keys(prospect), (key) => {
    setProspectData(key, prospect[key as keyof Prospect])
  })
  const { getStorageItem } = useLocalStorage()
  const lastEditedStep = getStorageItem(prospectKey) || 'Guarantees'
  validateProspectData()

  isLoadingProspect.value = false

  await goFast(getProspectData('sent_date') ? 'Signature' : lastEditedStep)
}

const getCurrentStepCounter = computed(() => (stepName: string) => {
  let countHistory = stepHistory.value.findIndex(
    (step: string) => step === stepName
  )
  let countFuture = stepFuture.value.findIndex(
    (step: string) => step === stepName
  )

  countHistory = countHistory !== -1 ? countHistory : stepHistory.value.length
  countFuture = countFuture !== -1 ? countFuture : 0

  return countHistory + countFuture + 1
})

const loadProspectFromContract = async (contractUuid: string) => {
  isLoadingProspect.value = true

  setProspectData('', {})
  await populateProspectDataFromContract(contractUuid)
  validateProspectData()
  clearStepData()
  window.LukoTracking.trackEvent({ id: 'Moving flow Started' })

  isLoadingProspect.value = false

  await goFast('StartDate')
}

export const useSteps = () => ({
  currentStep,
  stepHistory,
  stepFuture,
  completedSteps,
  setCompletedSteps,
  hasSaved,
  saveProspect,
  loadProspect,
  downloadProspectRecap,
  initStepper,
  goNext,
  goFast,
  revertTo,
  isAfterGuarantees,
  allowSubscription,
  isStepAfterGuarantees,
  currentProspectKey,
  isLoadingProspect,
  getCurrentStepCounter,
  isKeyWatcherToBeSkipped,
  loadProspectFromContract,
})
