import jwtDecode from 'jwt-decode'

interface InvitationJwtPayload {
  partner?: number
  workspace?: number
  role: string
  email: string
  is_registered?: boolean
}

interface OtpJwtPayload {
  user_id: number
  is_2fa_enabled: boolean
}

const getInvitationJwtPayload = (token: string) =>
  jwtDecode<InvitationJwtPayload>(token)
const getOtpJwtPayload = (token: string) => jwtDecode<OtpJwtPayload>(token)

export { getInvitationJwtPayload, getOtpJwtPayload }
