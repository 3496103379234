import { includes } from 'lodash-es'
import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'
import { useCountry } from 'src/store/country'
import { usePartner } from 'src/store/partner'
import { useWorkspace } from 'src/store/workspace'
import { PartnerType } from 'src/types/partner'

export const dashboardRoutes: RouteRecordRaw[] = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        return await import('../Dashboard.vue')
      } finally {
        await finishProgress()
      }
    },
    meta: {
      titleKey: 'Dashboard.title',
    },
    beforeEnter: () => {
      const { isNoveliaWs } = useWorkspace()
      const { isFrance } = useCountry()

      if (isFrance.value && !isNoveliaWs.value) return

      const { currentPartner, isCheck24Partner } = usePartner()
      const { CONTRACT_PROVIDER, DISTRIBUTOR, SUBSCRIBER } = PartnerType

      let redirectionRoute = 'settings'
      if (currentPartner.value?.type === SUBSCRIBER || isCheck24Partner.value)
        redirectionRoute = 'contracts'
      else if (
        includes([CONTRACT_PROVIDER, DISTRIBUTOR], currentPartner.value?.type)
      )
        redirectionRoute = 'prospects'

      return { name: redirectionRoute }
    },
  },
]
