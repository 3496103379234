import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'
import { sleep } from 'src/lib/webUtils'
import { usePartner } from 'src/store/partner'

export const contractDetailsRoutes: RouteRecordRaw[] = [
  {
    path: '/contract/:contractUuid',
    component: () => import('../ContractDetails.vue'),
    children: [
      {
        path: '',
        name: 'contractDetails',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const contractOverview = await import(
              '../components/ContractOverview/ContractOverview.vue'
            )
            return contractOverview
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'ContractDetails.title',
        },
      },
      {
        path: 'formerTermination',
        name: 'formerTermination',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const formerContractTermination = await import(
              '../components/FormerContractTermination/FormerContractTermination.vue'
            )
            return formerContractTermination
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'FormerTermination.title',
        },
      },
      {
        path: 'claim/:claimId',
        name: 'claim',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const openClaim = await import(
              '../components/OpenClaim/OpenClaim.vue'
            )
            return openClaim
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'ContractDetails.ClaimsArea.navigateToClaims',
        },
      },
    ],
    beforeEnter: async () => {
      const { currentPartner, isRealEstatePartner } = usePartner()

      while (!currentPartner.value) {
        await sleep(100)
      }

      if (!isRealEstatePartner.value) return

      return { name: 'sunset' }
    },
  },
]
