<template>
  <LukoPopin
    v-model="isDisplayed"
    :title="confirmationPopinOptions.title"
    :subtitle="confirmationPopinOptions.description"
    :icon="confirmationPopinOptions.icon"
    :data-luko-tracking="
      confirmationPopinOptions.dataLukoTracking ?? 'ConfirmationPopin'
    "
    :unclosable="!!confirmationPopinOptions.unclosable || isLoading"
    :theme="confirmationPopinOptions.theme"
  >
    <div v-if="hasContent" :class="$style.content">
      <FramedOption
        v-if="confirmationPopinOptions.checkboxLabel"
        is-secondary-light
        @click="isCheckboxSelected = !isCheckboxSelected"
      >
        <template #left>
          <LukoCheckbox
            v-model="isCheckboxSelected"
            data-test="CheckboxConfirm"
          />
        </template>
        <template #content>
          {{ confirmationPopinOptions.checkboxLabel }}
        </template>
      </FramedOption>
      <LukoInfoBox v-if="confirmationPopinOptions.info" small theme="secondary">
        {{ confirmationPopinOptions.info }}
      </LukoInfoBox>
    </div>
    <div
      :class="[
        $style.buttonsWrapper,
        confirmationPopinOptions?.hasColumnCta && $style.columnCta,
      ]"
    >
      <LukoButton
        data-luko-tracking="Cancel"
        :disabled="isLoading"
        theme="secondary"
        @click="resolveValue(false)"
      >
        {{ confirmationPopinOptions.ctaCancel }}
      </LukoButton>
      <LukoButton
        data-test="Confirm"
        data-luko-tracking="Confirm"
        :disabled="isConfirmationDisabled"
        :loading="isLoading"
        :theme="confirmationPopinOptions.theme"
        @click="resolveValue(true)"
      >
        {{ confirmationPopinOptions.ctaValid }}
      </LukoButton>
    </div>
  </LukoPopin>
</template>

<script setup lang="ts">
import {
  LukoButton,
  LukoCheckbox,
  LukoInfoBox,
  LukoPopin,
} from '@demain-es/lukompo'
import { isUndefined } from 'lodash-es'
import { computed, ref } from 'vue'

import { FramedOption } from 'src/components/FramedOption'
import { useConfirmationPopin } from 'src/composables/useConfirmationPopin'

const {
  isConfirmationPopinDisplayed,
  resolvePopin,
  confirmationPopinOptions,
  refreshOptions,
} = useConfirmationPopin()
const isLoading = ref(false)

const isDisplayed = computed({
  get: () => isConfirmationPopinDisplayed.value,
  set: (val) => {
    if (val || confirmationPopinOptions.value.resolveOnClose) {
      resolveValue(val)
      return
    }

    resolveValue(undefined)
  },
})

const hasContent = computed(
  () =>
    !!confirmationPopinOptions.value.checkboxLabel ||
    !!confirmationPopinOptions.value.info
)

const isCheckboxSelected = ref(false)

const isConfirmationDisabled = computed(
  () =>
    !!confirmationPopinOptions.value.checkboxLabel && !isCheckboxSelected.value
)

const resolveValue = async (val: boolean | undefined) => {
  try {
    isLoading.value = true

    if (isUndefined(val)) {
      resolvePopin.value = undefined
      return
    }

    if (!val) {
      resolvePopin.value = false
      return
    }

    if (!confirmationPopinOptions.value.action) {
      resolvePopin.value = true
      return
    }

    const isActionExecutedSuccessfully =
      await confirmationPopinOptions.value.action()

    resolvePopin.value = isActionExecutedSuccessfully
  } catch (e) {
    resolvePopin.value = false
    throw e
  } finally {
    refreshOptions()
    isLoading.value = false
    refreshOptions()
  }
}
</script>

<style lang="scss" module>
.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: stretch;
}
.buttonsWrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;

  & > button {
    flex: 1;
  }
}

.columnCta {
  flex-direction: column;
  gap: 8px;
}
</style>
