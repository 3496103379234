import { NavigationGuard, RouteLocationNormalized } from 'vue-router'

import { launchIntercom, shutdownIntercom } from 'src/plugins/intercom'
import { usePartner } from 'src/store/partner'
import { useWorkspace } from 'src/store/workspace'

const intercomGuard: NavigationGuard = async (to: RouteLocationNormalized) => {
  const { isNoveliaWs, isLukoWs } = useWorkspace()
  const { isLukoPartner, isRealEstatePartner } = usePartner()

  if (to.name === 'workspaceSettings')
    isLukoWs.value ? shutdownIntercom() : launchIntercom()
  else {
    isNoveliaWs.value || isLukoPartner.value || isRealEstatePartner.value
      ? shutdownIntercom()
      : launchIntercom()
  }
}

export { intercomGuard }
