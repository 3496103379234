import * as Sentry from '@sentry/vue'
import { repeat } from 'lodash-es'
import { App } from 'vue'

import { useError } from 'src/composables/useError'
import { useNotification } from 'src/composables/useNotification'
import { i18n } from 'src/i18n'
import { IS_DEV } from 'src/store/constants'

export const install = (app: App) => {
  app.config.errorHandler = (error: any, vm: any, info: string): void => {
    const componentName = getComponentName(vm)
    const trace = vm ? getTrace(vm) : ''
    const metadata: Record<string, any> = {
      componentName,
      info,
      trace,
      props: vm.$props,
    }

    reportException(error, metadata)

    // eslint-disable-next-line no-console
    IS_DEV && console.error(`Error in ${info}: "${error?.toString()}${trace}"`)
  }

  window.onunhandledrejection = (event) => {
    const error = event.reason

    reportException(error)
  }
}

const reportException = (error: any, vueMetadata?: Record<string, any>) => {
  const { parseError } = useError()
  const extra = { ...parseError(error) }

  if (!extra.api) {
    const { t } = i18n.global
    const { showNotification } = useNotification()
    showNotification({ message: t('Errors.generic'), theme: 'danger' })
  }

  setTimeout(() => {
    Sentry.withScope((scope) => {
      if (vueMetadata) scope.setContext('vue', vueMetadata)

      if (error.response) {
        scope.setFingerprint([error.response.data.type])
        scope.setTag('api_error', true)
        error.message = error.response.data.message
        error.name = error.response.data.type
      }
      Sentry.captureException(error, { extra })
    })
  })
}

const getComponentName = (vm: any): string => {
  if (!vm) return '<Unknown>'

  if (vm.$root === vm) return '<Root>'

  const options = vm.$options

  const name = options.name || options._componentTag

  if (name) return `<${name}>`

  const file = options.__file

  if (!file) return '<Unknown>'

  const match = file.match(/([^/\\]+)\.vue$/)

  if (!match || match.length < 2) return '<Unknown>'

  return match[1]
}

const getTrace = (vm: any): string => {
  if (!vm?.__isVue || !vm?.$parent)
    return `\n\n(found in ${getComponentName(vm)})`

  const tree = []
  let currentRecursiveSequence = 0
  while (vm) {
    if (tree.length > 0) {
      const last: any = tree[tree.length - 1]
      if (last.constructor === vm.constructor) {
        currentRecursiveSequence += 1
        vm = vm.$parent
        continue
      } else if (currentRecursiveSequence > 0) {
        tree[tree.length - 1] = [last, currentRecursiveSequence]
        currentRecursiveSequence = 0
      }
    }
    tree.push(vm)
    vm = vm.$parent
  }

  const formattedTree = tree
    .map(
      (vm_, i) =>
        `${
          (i === 0 ? '---> ' : repeat(' ', 5 + i * 2)) +
          (Array.isArray(vm_)
            ? `${getComponentName(vm_[0])}... (${vm_[1]} recursive calls)`
            : getComponentName(vm_))
        }`
    )
    .join('\n')

  return `\n\nfound in\n\n${formattedTree}`
}
