<template>
  <component
    :is="component"
    :data-luko-tracking="dataLukoTracking"
    v-bind="{
      ...(component === 'a' ? { href: to } : { to }),
      ...(newTab && { target: '_blank' }),
    }"
    :class="[$style.link, disabled && $style.linkDisabled]"
  >
    <slot v-if="iconRight" />
    <LukoIcon
      v-if="icon"
      :svg="icon"
      :class="$style.icon"
      :color="disabled ? 'gray500' : ''"
      width="16"
      height="16"
    />
    <slot v-if="!iconRight" />
  </component>
</template>

<script setup lang="ts">
import { LukoIcon } from '@demain-es/lukompo'
import { computed, PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'

import { useTheme } from 'src/composables/useTheme'

const props = defineProps({
  to: {
    type: [String, Object] as PropType<RouteLocationRaw | string>,
    required: true,
  },
  icon: { type: Function, default: undefined },
  iconRight: { type: Boolean, default: false },
  newTab: { type: Boolean, default: false },
  dataLukoTracking: { type: String, default: 'Link' },
  disabled: { type: Boolean, default: false },
})

const component = computed(() => {
  if (typeof props.to === 'string') return 'a'

  return 'router-link'
})

const { currentTheme } = useTheme()
</script>

<style lang="scss" module>
.link {
  display: flex;

  gap: 8px;
  align-items: flex-start;

  color: v-bind('currentTheme.backgroundColor');

  cursor: pointer;

  @include typo-sub-text;
}

.linkDisabled {
  color: $gray-500;

  cursor: not-allowed;

  pointer-events: none;
}

.icon {
  transform: translateY(1px);
}
</style>
