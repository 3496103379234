import { Language } from 'src/types/user'
const env = import.meta.env

const AVAILABLE_LOCALES = env.VITE_AVAILABLE_LOCALES
  ? env.VITE_AVAILABLE_LOCALES.split(',')
  : []

const DEFAULT_LOCALE = env.VITE_DEFAULT_LOCALE || Language.FR

const FALLBACK_LOCALE = env.VITE_FALLBACK_LOCALE || Language.EN

export { AVAILABLE_LOCALES, DEFAULT_LOCALE, FALLBACK_LOCALE }
