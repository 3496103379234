import { filter, includes, replace, values } from 'lodash-es'

import { i18n } from 'src/i18n'
import { useLocalStorage } from 'src/lib/localStorage'
import { ApiErrorStatusCode, ApiErrorType, IErrorInfo } from 'src/types/error'

const redirectionRoutes: Partial<Record<ApiErrorStatusCode, string>> = {
  [ApiErrorStatusCode.UNAUTHORIZED]: 'login',
  [ApiErrorStatusCode.FORBIDDEN]: 'prospects',
}

const actionOnError: Partial<Record<ApiErrorStatusCode, Function>> = {
  [ApiErrorStatusCode.UNAUTHORIZED]: () => {
    const { removeStorageItem } = useLocalStorage()
    removeStorageItem('isLogged')
  },
}

const getRedirectionRoute = (
  status: ApiErrorStatusCode,
  type: ApiErrorType
) => {
  if (
    status === ApiErrorStatusCode.FORBIDDEN &&
    type === ApiErrorType.NOT_ELIGIBLE_TO_LINK_TO_CONTRACT
  )
    return

  return redirectionRoutes[status]
}

const parseError = (error: any): IErrorInfo => {
  const { t } = i18n.global

  if (!error.response) {
    return {
      message: error.message ?? t('Errors.generic'),
      api: false,
      type: error.name,
    }
  }

  const { response } = error
  const { data, status } = response

  let message = t('Errors.generic')
  if (data?.message) {
    // remove info about params ($.a.b.c) from a bad request error message
    if (status === ApiErrorStatusCode.BAD_REQUEST)
      message = replace(data.message, /\s*\(\$\.[^)]+\)/, '')
    else message = data.message
  }

  const errorStatusesWithStaticMessage = filter(
    values(ApiErrorStatusCode),
    (c) => c !== ApiErrorStatusCode.BAD_REQUEST
  )

  if (
    status === ApiErrorStatusCode.UNAUTHORIZED &&
    data?.type === ApiErrorType.UNAUTHORIZED_IP_OPERATOR
  )
    message = t('Errors.unauthorizedIpOperator')
  else if (
    includes(errorStatusesWithStaticMessage, status) &&
    !(
      status === ApiErrorStatusCode.FORBIDDEN &&
      data?.type === ApiErrorType.PROSPECT_LOCKED
    )
  )
    message = t(`Errors.${status}`)

  return {
    message,
    type: data?.type ?? error.name,
    status,
    api: true,
    redirectTo: getRedirectionRoute(status, data.type),
    action: actionOnError[status as ApiErrorStatusCode],
  }
}

export const useError = () => ({
  parseError,
})
