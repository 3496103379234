import {
  find,
  includes,
  isEmpty,
  isNil,
  isNumber,
  reduce,
  remove,
} from 'lodash-es'
import { computed, reactive, ref } from 'vue'

import { getPaidProspectPrice, getProspectPrice } from 'src/api/prospect/price'
import { usePriceParsing } from 'src/composables/usePriceParsing'
import { useProspect } from 'src/modules/NewQuote/composables/useProspect'
import { useSteps } from 'src/modules/NewQuote/composables/useSteps'
import { CurrentQuote, Price, Quote, QuoteCallOptions } from 'src/types/quote'
const currentQuote: Partial<CurrentQuote> = reactive({})
const isDoingQuote = ref(false)
const isDoingQuoteForCommission = ref(false)

const currentPrice = computed((): Price => {
  const monthlyPrice = currentQuote.data?.price_monthly_billing ?? 0
  const yearlyPrice = currentQuote.data?.price_yearly_billing ?? 0

  const { parsedPrice: monthly } = usePriceParsing(monthlyPrice / 1200)
  const { parsedPrice: yearly } = usePriceParsing(yearlyPrice / 100)

  return {
    monthly,
    yearly,
  }
})

const resetQuote = () => {
  const keys = Object.keys(currentQuote) as Array<keyof CurrentQuote>
  keys.forEach((key) => delete currentQuote[key])
  isDoingQuote.value = false
  isDoingQuoteForCommission.value = false
}

const selectedPricingId = ref<string>()
const doQuoteNow = async (options?: QuoteCallOptions): Promise<void> => {
  const { getProspectData, setProspectData, isProspectSaved } = useProspect()
  const { revertTo, stepHistory } = useSteps()
  try {
    isDoingQuote.value = true

    if (options?.isCommissionCall) isDoingQuoteForCommission.value = true

    selectedPricingId.value = getProspectData('key')
    const priceData = options?.isPaidQuoteCall
      ? await getPaidProspectPrice(getProspectData('key'))
      : await getProspectPrice(getProspectData())

    if (isEmpty(priceData)) return

    setProspectData('key', priceData.key)

    if (isNumber(priceData.deductible) && isNil(getProspectData('deductible')))
      setProspectData('deductible', priceData.deductible)

    const subscribedPackage = find(
      priceData.packages,
      (pack) => pack.subscribed
    ) as Quote['packages'][0]

    if (isNil(getProspectData('package'))) {
      setProspectData('package', subscribedPackage.code)

      const subscribedOptionalBundles = reduce(
        subscribedPackage.optional_bundles,
        (acc: string[], bundle) => {
          if (bundle.subscribed) acc.push(bundle.code)
          return acc
        },
        []
      )
      setProspectData('optional_bundles', subscribedOptionalBundles)
    }

    let trackingId: string = ''
    if (isEmpty(currentQuote)) trackingId = 'Quote Seen'
    else if (options?.isCommissionCall) trackingId = 'Commission Updated'
    else trackingId = 'Quote Updated'

    Object.assign(currentQuote, {
      data: priceData,
      product: {
        title: priceData?.title,
        description: priceData?.large_description,
      },
      currentPackage: subscribedPackage,
    })

    window.LukoTracking.trackEvent({ id: trackingId })

    if (
      !currentQuote.currentPackage?.optional_bundles?.some((bundle) =>
        includes(bundle.code, 'valuables')
      )
    )
      remove(stepHistory.value, (step) => step === 'Valuables')

    isProspectSaved.value = true
  } catch (e) {
    const guaranteesPosition = stepHistory.value.indexOf('Guarantees')
    revertTo(stepHistory.value[Math.max(0, guaranteesPosition - 1)])
    throw e
  } finally {
    isDoingQuote.value = false
    isDoingQuoteForCommission.value = false
  }
}

let debounceTimeout: ReturnType<typeof setTimeout>

const doQuote = (options?: QuoteCallOptions) => {
  clearTimeout(debounceTimeout)
  debounceTimeout = setTimeout(() => {
    doQuoteNow(options)
  }, 500)
}

export const useQuote = () => ({
  currentQuote,
  isDoingQuote,
  isDoingQuoteForCommission,
  resetQuote,
  doQuote,
  doQuoteNow,
  currentPrice,
  selectedPricingId,
})
