import { Plugin } from '@demain-es/lukosiris'

import { router } from 'src/router'
import { IS_DEV } from 'src/store/constants'

import { getEventData, getUserData } from './data'

export const install = () => {
  if (!import.meta.env?.VITE_APP_AMPLITUDE_KEY) {
    window.LukoTracking = {
      trackEvent: () => {
        // do not track
      },
    }

    return
  }

  new Plugin({
    projectId: 'Companion',
    identificationEvents: 'always',
    cleanIdentificationPii: false,
    vueRouter: router,
    loadEventData: getEventData,
    loadUserData: getUserData,
    amplitudeInstance: IS_DEV ? 'lukosiris-dev' : 'lukosiris',
    amplitudeCoverKey: import.meta.env.VITE_APP_AMPLITUDE_COVER_KEY,
    amplitudeKey: import.meta.env.VITE_APP_AMPLITUDE_KEY,
  }).initialize()
}
