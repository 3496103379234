import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'

export const sunsetRoutes: RouteRecordRaw[] = [
  {
    path: '/sunset',
    name: 'sunset',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const sunsetModule = await import('../Sunset.vue')
        return sunsetModule
      } finally {
        await finishProgress()
      }
    },
    meta: {
      titleKey: 'Sunset.title',
    },
  },
]
