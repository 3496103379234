<template>
  <img v-if="logoUrl?.length" :src="logoUrl" :class="$style.logoImg" alt="" />
  <div v-else :class="$style.logo">
    <span v-if="!!initials">{{ initials }}</span>
    <LukoIcon v-else :svg="Person" color="gray300" width="16" height="16" />
  </div>
</template>

<script setup lang="ts">
import { LukoIcon, Person } from '@demain-es/lukompo'
import { split } from 'lodash-es'
import { computed } from 'vue'

const props = defineProps({
  size: { type: [String, Number], default: 36 },
  name: { type: String, default: '' },
  logoUrl: { type: String, default: '' },
})

const initials = computed(() => {
  const isComposed = split(props.name, /\s+/).length > 1

  if (isComposed) {
    const firstLetter = split(props.name, /\s+/)[0].charAt(0)
    const secondLetter = split(props.name, /\s+/)[1].charAt(0)
    return `${firstLetter}${secondLetter}`
  }

  return props.name.charAt(0)
})
</script>

<style module lang="scss">
.logo {
  @include typo-sub-text-bold;

  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  width: v-bind('`${size}px`');
  height: v-bind('`${size}px`');
  border-radius: 50%;

  color: $gray-300;

  background-color: $gray-100;
}

.logoImg {
  width: v-bind('`${size}px`');
  height: v-bind('`${size}px`');
  border-radius: 50%;
}
</style>
