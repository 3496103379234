import { omit } from 'lodash-es'
import { NavigationGuard, RouteLocationNormalized } from 'vue-router'

import { useLocalStorage } from 'src/lib/localStorage'
import { sleep } from 'src/lib/webUtils'
import { getCurrentPartnerDefaultRoute } from 'src/store/auth'
import { usePartner } from 'src/store/partner'
import { useUser } from 'src/store/user'

import { router } from '..'

const authRoutes = [
  '/auth/login',
  '/auth/signup',
  '/auth/reset-password',
  '/auth/forgot-password',
]

const authGuard: NavigationGuard = async (to: RouteLocationNormalized) => {
  const { getStorageItem } = useLocalStorage()
  const isLogged = getStorageItem('isLogged')
  const isAuthRoute = authRoutes.includes(to.path)
  const isRootRoute = to.path === '/'

  const ssoToken = to.query.token as string
  if (ssoToken) {
    const { ssoLoginUser, postLoginUser } = useUser()
    await ssoLoginUser(ssoToken)
    await postLoginUser()
    delete to.query.token
    return {
      name: to.name as string,
      ...(!!to.params && { params: to.params }),
      ...(!!to.query && { query: to.query }),
    }
  }

  if (!isLogged)
    return isAuthRoute || { name: 'login', query: { redirect: to.fullPath } }

  if (isLogged && (isAuthRoute || isRootRoute)) {
    const { currentPartner } = usePartner()

    while (!currentPartner.value?.type) {
      await sleep(100)
    }

    const { push } = router
    push({ name: getCurrentPartnerDefaultRoute() })
  }

  const partnerId = to.query?.['partner.id']
    ? Number(to.query['partner.id'])
    : 0
  if (to.name === 'newQuote' && partnerId) {
    const { setCurrentPartner } = usePartner()
    setCurrentPartner(partnerId)

    return {
      name: 'newQuote',
      query: omit(to.query, 'partner.id'),
      params: to.params,
      replace: true,
    }
  }

  return true
}

export { authGuard }
