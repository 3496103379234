enum Floor {
  GROUND = 'ground',
  MIDDLE = 'middle',
  TOP = 'top',
}

enum ResidenceType {
  FLAT = 'flat',
  HOUSE = 'house',
}

enum OccupantOrOwnerType {
  OWNER = 'owner',
  TENANT = 'tenant',
  LANDLORD = 'landlord',
  LANDLORD_FOR_TENANT = 'landlord_for_tenant',
}

enum PolicyType {
  MOVING = 'moving',
  MOVING_WITH_LUKO = 'moving_with_luko',
  SWITCHING = 'switching',
  NON_ADDRESSABLE = 'non_addressable',
  DUPLICATE = 'duplicate',
}

enum Year {
  YEAR0 = 'Unknown',
  YEAR1 = 'before 1960',
  YEAR2 = '1960-1979',
  YEAR3 = '1980-1999',
  YEAR4 = '2000-2015',
  YEAR5 = 'after 2015',
}

enum OutbuildingSize {
  NONE = 'none',
  SMALL = 'small',
  BIG = 'big',
  BIGGER = 'bigger',
}

enum BundleCode {
  FR_HOME_OUTBUILDINGS = 'france-home-outbuildings',
  FR_LANDLORD_OUTBUILDINGS = 'france-landlord-liag-outbuilding',
  FR_HOME_REPLACEMENT = 'france-home-full-new-replacement',
  FR_HOME_PIPE_REPAIR = 'france-home-pipe-repair',
  FR_HOME_PARENTS = 'france-home-parents',
  FR_HOME_OUTDOOR_FACILITIES = 'france-home-outdoor-facilities',
  FR_HOME_AIRBNB = 'france-home-airbnb',
  FR_LANDLORD_REPLACEMENT = 'france-landlord-liag-replacement-value-10-years',
  FR_FLAT_OUTBUILDING = 'france-flat-outbuildings',
  FR_SECONDARY_OUTBUILDING = 'france-secondary-outbuilding',
  FR_LANDLORD_HOUSE_OUTBUILDING = 'france-landlord-house-outbuilding',
  FR_LEGAL_PROTECTION = 'france-legal-protection',
  FR_LEGAL_PROTECTION_EXT = 'france-legal-protection-extended',
  FR_RENT_MIN_LEG = 'france-fr-rent-b2c-minleg',
}

enum PackageCode {
  FR_HOME = 'france-home-pom',
  FR_HOME_MINLEG = 'france-home-minleg',
  FR_HOME_FLAT = 'france-flat-pom',
  FR_HOME_HOUSE = 'france-house-pom',
  FR_LANDLORD = 'france-landlord-pom',
  FR_ESCOOTER = 'france-nvei-pom',
  FR_LANDLORD_MINLEG = 'france-landlord-minleg',
  FR_LANDLORD_FLAT = 'france-cbno-pom',
  FR_LANDLORD_HOUSE = 'france-landlord-house-pom',
  FR_SECONDARY = 'france-secondary-pom',
  FR_MORTGAGE = 'france-mortgage-adaptive-package',
  FR_RENT = 'france-fr-rent-b2c-mila',
  FR_RENT_B2B_SADA = 'france-fr-rent-b2b-sada',
}

enum Role {
  BORROWER = 'borrower',
  CHILD = 'child',
  LOAN = 'loan',
  MAIN_ASSET = 'main_asset',
  MAIN_BENEFICIARY = 'main_beneficiary',
  ROOMMATE = 'roommate',
  SPOUSE = 'spouse',
  SUBSCRIBER = 'subscriber',
  TENANT = 'tenant',
}

enum Channel {
  API = 'api',
  COMPANION = 'companion',
  ONBOARDING = 'onboarding',
}

enum ConversionChannel {
  CALL_CENTER = 'Call center',
  WEB = 'Web',
}

enum Gender {
  FEMALE = 'female',
  MALE = 'male',
}

enum SignatureRequestMode {
  LUKOSIGN_EMBEDDED = 'luko_embedded',
  LUKO_EMAIL = 'luko_email',
}

enum RiskCarrier {
  MILA = 'MILA',
  SADA = 'SADA',
}

enum NveiBrand {
  AIRWHEEL = 'airwheel',
  ALFAWISE = 'alfawise',
  DUALTRON = 'dualtron',
  EGRET = 'egret',
  ETWOW = 'etwow',
  GOTWAY = 'gotway',
  IPS = 'ips',
  INMOTION = 'inmotion',
  ITROTT = 'itrott',
  IZIROD = 'izirod',
  KINGSONG = 'kingsong',
  LHOTZ = 'lhotz',
  MACFLY = 'macfly',
  NINEBOT = 'ninebot',
  REVOE = 'revoe',
  SAMSUNG = 'samsung',
  SEGWAY = 'segway',
  SPEEDWAY = 'speedway',
  TILGREEN = 'tilgreen',
  TROTLUX = 'trotlux',
  WEEBOY = 'weebot',
  XIAOMI = 'xiaomi',
  YUNEEC = 'yuneec',
  OTHER = 'other',
}

enum NveiCategory {
  ESCOOTER = 'escooter',
  MONOCYCLE = 'monocycle',
  HOVERBOARD = 'hoverboard',
  LONGBOARD = 'longboard',
  ULTRALIGHTESCOOTER = 'ultralightescooter',
  GYROPODE = 'gyropode',
}

enum EScooterIndividualAccidentLimit {
  LEVEL_1 = 'level_1',
}

export {
  Floor,
  ResidenceType,
  OccupantOrOwnerType,
  PolicyType,
  Year,
  OutbuildingSize,
  BundleCode,
  PackageCode,
  Role,
  Channel,
  NveiBrand,
  NveiCategory,
  Gender,
  SignatureRequestMode,
  RiskCarrier,
  ConversionChannel,
  EScooterIndividualAccidentLimit,
}
