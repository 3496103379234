import { Check, Warning } from '@demain-es/lukompo'
import { find, pick } from 'lodash-es'

import { createCoupon as createCouponApi } from 'src/api/coupon'
import { useNotification } from 'src/composables/useNotification'
import { i18n } from 'src/i18n'
import { useCouponDetails } from 'src/modules/CouponDetails/composables/useCouponDetails'
import { CouponDetails } from 'src/modules/CouponDetails/types'
import { useCoupons } from 'src/modules/Coupons/composables/useCoupons'

import { useCouponData } from './useCouponData'

const createCoupon = async () => {
  const { getCouponData } = useCouponData()
  const couponData = getCouponData()
  return await createCouponApi(couponData)
}

const duplicateCoupon = (couponUuid: string) => {
  let couponToDuplicate: CouponDetails | undefined

  const { couponDetails } = useCouponDetails()
  if (couponDetails.value?.uuid === couponUuid)
    couponToDuplicate = couponDetails.value
  else {
    const { coupons } = useCoupons()
    couponToDuplicate = find(coupons.value, ({ uuid }) => uuid === couponUuid)
  }

  const { t } = i18n.global
  const { showNotification } = useNotification()

  if (!couponToDuplicate) {
    const { t } = i18n.global
    const { showNotification } = useNotification()
    showNotification({
      message: t('Coupons.Messages.duplicationFailed'),
      icon: Warning,
      theme: 'danger',
    })
    return
  }

  const { setCouponData } = useCouponData()
  const keysToDuplicate = [
    'acquisition_channel',
    'banner_msg',
    'description',
    'redeem_amount',
    'redeem_type',
    'starting_date',
    'ending_date',
    'success_msg',
    'max_shares',
    'validity_fcts',
  ]
  setCouponData('', pick(couponToDuplicate, keysToDuplicate))
  showNotification({
    message: t('Coupons.Messages.duplicated'),
    icon: Check,
    theme: 'success',
  })
}

export const useActions = () => ({
  createCoupon,
  duplicateCoupon,
})
