import { Warning } from '@demain-es/lukompo'
import { replace } from 'lodash-es'
import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'
import { useNotification } from 'src/composables/useNotification'
import { i18n } from 'src/i18n'
import { sleep } from 'src/lib/webUtils'
import { usePartner } from 'src/store/partner'
import { useWorkspace } from 'src/store/workspace'

export const prospectsRoutes: RouteRecordRaw[] = [
  {
    path: '/prospects',
    name: 'prospects',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const prospectsModule = await import('../Prospects.vue')
        return prospectsModule
      } finally {
        await finishProgress()
      }
    },
    meta: {
      titleKey: 'Prospects.metaTitle',
    },
    beforeEnter: async (to, _, next) => {
      const { currentPartner } = usePartner()

      while (!currentPartner.value) {
        await sleep(100)
      }

      const { isNoveliaWs } = useWorkspace()
      if (isNoveliaWs.value) {
        const { t } = i18n.global
        const { showNotification } = useNotification()
        showNotification({
          message: t('Errors.currentlyUnavailable'),
          icon: Warning,
          theme: 'danger',
        })
        next({ name: 'contracts' })
      }

      if (to.query.status) {
        to.meta['status'] = to.query.status
        delete to.query.status
        to.fullPath = replace(to.fullPath, /\?status=.*/, '')
      }

      next()
    },
  },
]
