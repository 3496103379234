import { includes, reduce } from 'lodash-es'

import { consumerDetailsRoutes } from 'src/modules/ConsumerDetails/routes'
import { PartnerType } from 'src/types/partner'
import { Acl, AuthRole } from 'src/types/user'

import { useCountry } from './country'
import { usePartner } from './partner'
import { useUser } from './user'
import { useWorkspace } from './workspace'

const AUTH_ROLES: Record<string, AuthRole> = {
  account: {
    userRole: [
      Acl.ADMIN,
      Acl.CS_ADMIN,
      Acl.CS_USER,
      Acl.CS_LEAD,
      Acl.SALES,
      Acl.DEVELOPER,
      Acl.SALES_LEAD,
    ],
    userRoleRealEstate: [
      Acl.ADMIN,
      Acl.ACCOUNTANT,
      Acl.PROPERTY_MANAGER,
      Acl.EXTERNAL_PROPERTY_MANAGER,
    ],
    userRoleNovelia: [Acl.ADMIN, Acl.SALES, Acl.SALES_LEAD],
    userRoleCheck24: [Acl.ADMIN, Acl.SALES, Acl.SALES_LEAD],
    partnerRole: [
      PartnerType.TRAFFIC_PROVIDER,
      PartnerType.QUOTE_PROVIDER,
      PartnerType.CONTRACT_PROVIDER,
      PartnerType.SUBSCRIBER,
      PartnerType.DISTRIBUTOR,
    ],
  },
  newQuote: {
    userRole: [
      Acl.ADMIN,
      Acl.CS_ADMIN,
      Acl.CS_USER,
      Acl.CS_LEAD,
      Acl.SALES,
      Acl.DEVELOPER,
      Acl.SALES_LEAD,
    ],
    userRoleRealEstate: [],
    userRoleNovelia: [Acl.ADMIN, Acl.SALES, Acl.SALES_LEAD],
    userRoleCheck24: [],
    partnerRole: [
      PartnerType.CONTRACT_PROVIDER,
      PartnerType.SUBSCRIBER,
      PartnerType.DISTRIBUTOR,
    ],
  },
  dashboard: {
    userRole: [Acl.ADMIN, Acl.SALES, Acl.SALES_LEAD, Acl.ACCOUNTANT],
    userRoleRealEstate: [],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [
      PartnerType.TRAFFIC_PROVIDER,
      PartnerType.QUOTE_PROVIDER,
      PartnerType.CONTRACT_PROVIDER,
      PartnerType.DISTRIBUTOR,
    ],
  },
  resources: {
    userRole: [
      Acl.ADMIN,
      Acl.CS_ADMIN,
      Acl.CS_USER,
      Acl.CS_LEAD,
      Acl.SALES,
      Acl.SALES_LEAD,
    ],
    userRoleRealEstate: [
      Acl.ADMIN,
      Acl.ACCOUNTANT,
      Acl.PROPERTY_MANAGER,
      Acl.EXTERNAL_PROPERTY_MANAGER,
    ],
    userRoleNovelia: [Acl.ADMIN, Acl.SALES, Acl.DEVELOPER, Acl.SALES_LEAD],
    userRoleCheck24: [],
    partnerRole: [PartnerType.CONTRACT_PROVIDER, PartnerType.DISTRIBUTOR],
  },
  contracts: {
    userRole: [
      Acl.ADMIN,
      Acl.CS_ADMIN,
      Acl.CS_USER,
      Acl.CS_LEAD,
      Acl.SALES,
      Acl.DEVELOPER,
      Acl.SALES_LEAD,
    ],
    userRoleRealEstate: [
      Acl.ADMIN,
      Acl.ACCOUNTANT,
      Acl.PROPERTY_MANAGER,
      Acl.EXTERNAL_PROPERTY_MANAGER,
    ],
    userRoleNovelia: [Acl.ADMIN, Acl.SALES, Acl.SALES_LEAD],
    userRoleCheck24: [Acl.ADMIN, Acl.SALES, Acl.SALES_LEAD],
    partnerRole: [
      PartnerType.CONTRACT_PROVIDER,
      PartnerType.SUBSCRIBER,
      PartnerType.DISTRIBUTOR,
    ],
  },
  contractDetails: {
    userRole: [
      Acl.ADMIN,
      Acl.CS_ADMIN,
      Acl.CS_USER,
      Acl.CS_LEAD,
      Acl.SALES,
      Acl.DEVELOPER,
      Acl.SALES_LEAD,
    ],
    userRoleRealEstate: [
      Acl.ADMIN,
      Acl.ACCOUNTANT,
      Acl.PROPERTY_MANAGER,
      Acl.EXTERNAL_PROPERTY_MANAGER,
    ],
    userRoleNovelia: [Acl.ADMIN, Acl.SALES, Acl.SALES_LEAD],
    userRoleCheck24: [Acl.ADMIN, Acl.SALES, Acl.SALES_LEAD],
    partnerRole: [
      PartnerType.CONTRACT_PROVIDER,
      PartnerType.SUBSCRIBER,
      PartnerType.DISTRIBUTOR,
    ],
  },
  contractAmends: {
    userRole: [
      Acl.ADMIN,
      Acl.CS_ADMIN,
      Acl.CS_USER,
      Acl.CS_LEAD,
      Acl.SALES,
      Acl.DEVELOPER,
      Acl.SALES_LEAD,
    ],
    userRoleRealEstate: [
      Acl.ADMIN,
      Acl.PROPERTY_MANAGER,
      Acl.EXTERNAL_PROPERTY_MANAGER,
    ],
    userRoleNovelia: [Acl.ADMIN, Acl.SALES, Acl.SALES_LEAD],
    userRoleCheck24: [Acl.ADMIN, Acl.SALES, Acl.SALES_LEAD],
    partnerRole: [
      PartnerType.SUBSCRIBER,
      PartnerType.DISTRIBUTOR,
      PartnerType.CONTRACT_PROVIDER,
    ],
  },
  contractTerminations: {
    userRole: [
      Acl.ADMIN,
      Acl.CS_ADMIN,
      Acl.CS_USER,
      Acl.CS_LEAD,
      Acl.SALES,
      Acl.DEVELOPER,
      Acl.SALES_LEAD,
    ],
    userRoleRealEstate: [
      Acl.ADMIN,
      Acl.PROPERTY_MANAGER,
      Acl.EXTERNAL_PROPERTY_MANAGER,
    ],
    userRoleNovelia: [Acl.ADMIN, Acl.SALES, Acl.SALES_LEAD],
    userRoleCheck24: [Acl.ADMIN, Acl.SALES, Acl.SALES_LEAD],
    partnerRole: [
      PartnerType.SUBSCRIBER,
      PartnerType.DISTRIBUTOR,
      PartnerType.CONTRACT_PROVIDER,
    ],
  },
  contractsExtract: {
    userRole: [Acl.ADMIN],
    userRoleRealEstate: [],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [PartnerType.DISTRIBUTOR, PartnerType.CONTRACT_PROVIDER],
  },
  contractDocument: {
    userRole: [Acl.ADMIN, Acl.CS_ADMIN, Acl.CS_USER, Acl.CS_LEAD],
    userRoleRealEstate: [],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [PartnerType.SUBSCRIBER],
  },
  contractsChannel: {
    userRole: [Acl.ADMIN, Acl.SALES_LEAD],
    userRoleRealEstate: [],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [PartnerType.DISTRIBUTOR, PartnerType.CONTRACT_PROVIDER],
  },
  formerTermination: {
    userRole: [
      Acl.ADMIN,
      Acl.CS_ADMIN,
      Acl.CS_USER,
      Acl.CS_LEAD,
      Acl.SALES,
      Acl.DEVELOPER,
      Acl.SALES_LEAD,
    ],
    userRoleRealEstate: [],
    userRoleNovelia: [Acl.ADMIN, Acl.SALES, Acl.SALES_LEAD],
    userRoleCheck24: [],
    partnerRole: [
      PartnerType.CONTRACT_PROVIDER,
      PartnerType.SUBSCRIBER,
      PartnerType.DISTRIBUTOR,
    ],
  },
  prospects: {
    userRole: [
      Acl.ADMIN,
      Acl.CS_ADMIN,
      Acl.CS_USER,
      Acl.CS_LEAD,
      Acl.SALES,
      Acl.DEVELOPER,
      Acl.SALES_LEAD,
    ],
    userRoleRealEstate: [],
    userRoleNovelia: [Acl.ADMIN, Acl.SALES, Acl.SALES_LEAD],
    userRoleCheck24: [],
    partnerRole: [
      PartnerType.CONTRACT_PROVIDER,
      PartnerType.SUBSCRIBER,
      PartnerType.DISTRIBUTOR,
    ],
  },
  developers: {
    userRole: [Acl.ADMIN, Acl.DEVELOPER],
    userRoleRealEstate: [],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [
      PartnerType.TRAFFIC_PROVIDER,
      PartnerType.QUOTE_PROVIDER,
      PartnerType.CONTRACT_PROVIDER,
      PartnerType.SUBSCRIBER,
      PartnerType.DISTRIBUTOR,
    ],
  },
  /*  webhooks: {
      userRole: [Acl.ADMIN, Acl.DEVELOPER],
      userRoleRealEstate: [Acl.ADMIN, Acl.PROPERTY_MANAGER],
      userRoleNovelia: [],
      partnerRole: [
        PartnerType.TRAFFIC_PROVIDER,
        PartnerType.QUOTE_PROVIDER,
        PartnerType.CONTRACT_PROVIDER,
        PartnerType.SUBSCRIBER,
        PartnerType.DISTRIBUTOR,
      ],
    }, */
  settings: {
    userRole: [Acl.ADMIN, Acl.CS_ADMIN, Acl.DEVELOPER],
    userRoleRealEstate: [Acl.ADMIN],
    userRoleNovelia: [Acl.ADMIN],
    userRoleCheck24: [Acl.ADMIN],
    partnerRole: [
      PartnerType.TRAFFIC_PROVIDER,
      PartnerType.QUOTE_PROVIDER,
      PartnerType.CONTRACT_PROVIDER,
      PartnerType.SUBSCRIBER,
      PartnerType.DISTRIBUTOR,
    ],
  },
  billing: {
    userRole: [Acl.ADMIN, Acl.ACCOUNTANT],
    userRoleRealEstate: [Acl.ADMIN, Acl.ACCOUNTANT],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [
      PartnerType.TRAFFIC_PROVIDER,
      PartnerType.QUOTE_PROVIDER,
      PartnerType.CONTRACT_PROVIDER,
      PartnerType.DISTRIBUTOR,
    ],
  },
  consumer: {
    userRole: [Acl.ADMIN, Acl.CS_ADMIN, Acl.CS_USER, Acl.CS_LEAD],
    userRoleRealEstate: [],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [PartnerType.SUBSCRIBER],
  },
  consumers: {
    userRole: [Acl.ADMIN, Acl.CS_ADMIN, Acl.CS_USER, Acl.CS_LEAD],
    userRoleRealEstate: [],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [PartnerType.SUBSCRIBER],
  },
  tenantFiles: {
    userRole: [],
    userRoleRealEstate: [
      Acl.ADMIN,
      Acl.PROPERTY_MANAGER,
      Acl.EXTERNAL_PROPERTY_MANAGER,
    ],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [],
  },
  tenantFileDetails: {
    userRole: [Acl.ADMIN, Acl.CS_ADMIN, Acl.CS_USER, Acl.CS_LEAD],
    userRoleRealEstate: [
      Acl.ADMIN,
      Acl.PROPERTY_MANAGER,
      Acl.EXTERNAL_PROPERTY_MANAGER,
    ],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [PartnerType.SUBSCRIBER],
  },
  tenantFileAmend: {
    userRole: [],
    userRoleRealEstate: [
      Acl.ADMIN,
      Acl.PROPERTY_MANAGER,
      Acl.EXTERNAL_PROPERTY_MANAGER,
    ],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [],
  },
  tenantFileDelete: {
    userRole: [],
    userRoleRealEstate: [
      Acl.ADMIN,
      Acl.PROPERTY_MANAGER,
      Acl.EXTERNAL_PROPERTY_MANAGER,
    ],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [],
  },
  partners: {
    userRole: [Acl.ADMIN, Acl.CS_ADMIN, Acl.CS_USER, Acl.CS_LEAD],
    userRoleRealEstate: [],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [PartnerType.SUBSCRIBER],
  },
  partnerDetails: {
    userRole: [Acl.ADMIN, Acl.CS_ADMIN, Acl.CS_USER, Acl.CS_LEAD],
    userRoleRealEstate: [],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [PartnerType.SUBSCRIBER],
  },
  coupons: {
    userRole: [Acl.ADMIN, Acl.CS_ADMIN, Acl.CS_USER],
    userRoleRealEstate: [],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [PartnerType.SUBSCRIBER],
  },
  newCoupon: {
    userRole: [Acl.ADMIN, Acl.CS_ADMIN, Acl.CS_USER],
    userRoleRealEstate: [],
    userRoleNovelia: [],
    userRoleCheck24: [],
    partnerRole: [PartnerType.SUBSCRIBER],
  },
  movingOutFlow: {
    userRole: [
      Acl.ADMIN,
      Acl.CS_ADMIN,
      Acl.CS_USER,
      Acl.CS_LEAD,
      Acl.SALES,
      Acl.DEVELOPER,
      Acl.SALES_LEAD,
    ],
    userRoleRealEstate: [],
    userRoleNovelia: [Acl.ADMIN, Acl.SALES, Acl.SALES_LEAD],
    userRoleCheck24: [],
    partnerRole: [PartnerType.SUBSCRIBER, PartnerType.DISTRIBUTOR],
  },
  ...reduce(
    consumerDetailsRoutes[0].children,
    (acc: Record<string, Record<string, string[]>>, route) => {
      acc[route.name as string] = {
        userRole: [Acl.ADMIN, Acl.CS_ADMIN, Acl.CS_USER, Acl.CS_LEAD],
        userRoleNovelia: [],
        partnerRole: [PartnerType.SUBSCRIBER],
      }
      return acc
    },
    {}
  ),
} as Record<string, AuthRole>

const isAuthorized = (routeOrAction: string) => {
  const { isLukoPartner, isRealEstatePartner } = usePartner()
  if (includes(['consumers', 'partners'], routeOrAction))
    return isLukoPartner.value

  const { isFrance } = useCountry()
  if (
    ['resources', 'dashboard', 'billing'].includes(routeOrAction) &&
    !isFrance.value
  )
    return false

  const { currentACL } = useUser()
  const {
    userRole,
    partnerRole,
    userRoleNovelia,
    userRoleCheck24,
    userRoleRealEstate,
  } = AUTH_ROLES[routeOrAction]

  if (isRealEstatePartner.value) {
    return userRoleRealEstate.includes(currentACL.value!)
  }

  const { isNoveliaWs } = useWorkspace()
  const { isCheck24Partner, currentPartner } = usePartner()

  const hasPartnerAccess = partnerRole.includes(currentPartner.value?.type!)

  let hasUserAccess = userRole.includes(currentACL.value!)
  if (isNoveliaWs.value)
    hasUserAccess = userRoleNovelia.includes(currentACL.value!)
  if (isCheck24Partner.value)
    hasUserAccess = userRoleCheck24.includes(currentACL.value!)

  return hasUserAccess && hasPartnerAccess
}

const getCurrentPartnerDefaultRoute = () => {
  const {
    isCheck24Partner,
    isLukoPartner,
    currentPartner,
    isRealEstatePartner,
  } = usePartner()

  const { currentACL } = useUser()

  if (!currentPartner.value?.type) return 'logout'

  if (isLukoPartner.value) {
    const hasUserAccess = includes(
      AUTH_ROLES['consumers'].userRole,
      currentACL.value
    )
    return hasUserAccess ? 'consumers' : 'contracts'
  }

  if (isRealEstatePartner.value) return 'sunset'

  if (
    currentPartner.value?.type === PartnerType.SUBSCRIBER ||
    isCheck24Partner.value
  )
    return 'contracts'

  const { isNoveliaWs } = useWorkspace()
  if (isNoveliaWs.value) return 'contracts'

  if (isAuthorized('dashboard')) return 'dashboard'
  if (isAuthorized('prospects')) return 'prospects'
  return 'settings'
}

export { AUTH_ROLES, isAuthorized, getCurrentPartnerDefaultRoute }
