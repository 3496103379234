import { ApiRequestName } from 'src/types/api'
import { Prospect } from 'src/types/prospect'

import { Api } from '..'
import {
  EditProspectEmailPayload,
  EditProspectStartDatePayload,
  PricingProspectResponse,
  ProspectRemindResponse,
  ProspectResponse,
  ProspectSavedResponse,
  ProspectsQueryParams,
  ProspectsResponse,
} from './types'

const getProspects = async (params: ProspectsQueryParams) => {
  const { data } = await Api.get<ProspectsResponse>('/v1/partner/prospects', {
    params: {
      ...params,
      abortSignal: true,
      requestName: ApiRequestName.GET_PROSPECTS,
    },
    headers: { bearer: true },
  })

  return data
}

const getProspectById = async (prospectKey: string) => {
  const { data } = await Api.get<ProspectResponse>(
    `/v1/prospect/${prospectKey}`,
    {
      headers: { bearer: true },
    }
  )
  return data
}

const storeProspect = async (prospectData: any) => {
  const { data } = await Api.post<ProspectSavedResponse>(
    `/v1/prospect`,
    prospectData,
    {
      headers: { bearer: true },
    }
  )
  return data
}

const remindProspect = async (
  prospectkey: string,
  payload: { is_payment: boolean; bcc?: boolean }
) => {
  const { data } = await Api.post<ProspectRemindResponse>(
    `/v1/prospect/${prospectkey}/reminder`,
    { ...payload },
    { params: { lang: true }, headers: { bearer: true } }
  )
  return data
}

const archiveProspects = async (prospectKeys: Prospect['key'][]) =>
  Api.patch(
    `/v1/prospect/archive`,
    { prospects: prospectKeys },
    { headers: { bearer: true } }
  )

const editProspectEmail = (
  payload: EditProspectEmailPayload,
  key: Prospect['key']
) =>
  Api.patch<ProspectResponse>(`/v1/prospect/${key}/email`, payload, {
    headers: { bearer: true },
  })

const editProspectStartDate = (
  payload: EditProspectStartDatePayload,
  key: Prospect['key']
) =>
  Api.patch<ProspectResponse>(`/v1/prospects/${key}/start_date`, payload, {
    headers: { bearer: true },
  })

const getContentValues = async (prospectData: any) => {
  const { data } = await Api.post(`/v1/prospect/content_values`, prospectData, {
    headers: { bearer: true },
  })
  return data
}

const getExistingPaymentMethods = async (prospectEmail: string) => {
  const { data } = await Api.get('/v1/prospect/payment_methods', {
    params: {
      email: prospectEmail,
      get_all_statuses: true,
      abortSignal: true,
      requestName: ApiRequestName.GET_EXISTING_PAYMENT_METHODS,
    },
    headers: { bearer: true },
  })
  return data
}

const getPricingFromProspect = async (prospect_key: string) => {
  const { data } = await Api.get<PricingProspectResponse>(
    `v1/partners/prospects/${prospect_key}/pricing_states`,
    { headers: { bearer: true } }
  )

  return data
}

export {
  archiveProspects,
  getProspects,
  remindProspect,
  storeProspect,
  getProspectById,
  editProspectEmail,
  getContentValues,
  getExistingPaymentMethods,
  editProspectStartDate,
  getPricingFromProspect,
}
