import { useColors, useLukompoConfiguration } from '@demain-es/lukompo'
import Color from 'color'
import { toLower } from 'lodash-es'
import { ref } from 'vue'

import { generatePalette } from 'src/lib/color'

const { colors } = useColors()

// Default palette uses the bluko color scheme
const DEFAULT_PALETTE = [
  colors.bluko25,
  colors.bluko50,
  colors.bluko75,
  colors.bluko100,
  colors.bluko150,
  colors.bluko200,
  colors.bluko300,
  colors.bluko400,
  colors.bluko500,
  colors.bluko600,
  colors.bluko700,
  colors.bluko800,
  colors.bluko900,
  colors.bluko1000,
]

const currentTheme = ref({
  backgroundColor: colors.bluko500,
  textColor: colors.white,
  shadowColor: colors.bluko100,
  hoveredBackgroundColor: colors.bluko400,
  backgroundColorLight: colors.bluko100,
  textColorLight: colors.bluko500,
  shadowColorLight: colors.bluko200,
  hoveredBackgroundColorLight: colors.bluko75,
  infoBoxTextColor: colors.bluko500,
  inputBorderColor: colors.gray100,
  inputHoveredBorderColor: colors.gray300,
  inputFocusedBorderColor: colors.bluko500,
  inputDisabledBorderColor: colors.gray100,
  inputDisabledBackgroundColor: colors.gray25,
  inputDisabledTextColor: colors.gray500,
  inputDisabledPlaceholderColor: colors.gray300,
  tagTextColor: colors.bluko700,
  toggleChipColor: colors.white,
  toggleOffDisabledChipColor: colors.gray200,
  toggleOnDisabledChipColor: colors.bluko200,
  toggleOffBackgroundColor: colors.gray200,
  toggleOnDisabledBackgroundColor: colors.bluko300,
  toggleOffHoveredBackgroundColor: colors.gray300,
  toggleOffShadowColor: colors.gray150,
  toggleOffDisabledBackgroundColor: colors.gray100,
  hoveredCheckerBackgroundColor: colors.bluko150,
  grayElementBackgroundColor: colors.gray100,
  dropFileHoverColor: colors.bluko50,
})

const updateTheme = (colorValue?: string) => {
  let colorPalette: string[]
  if (toLower(colorValue) !== toLower(colors.bluko500)) {
    const mainColor = Color(colorValue).rgb()
    colorPalette = generatePalette(mainColor)
  } else {
    colorPalette = DEFAULT_PALETTE
  }

  currentTheme.value = {
    backgroundColor: colorPalette[8],
    textColor: colors.white,
    shadowColor: colorPalette[3],
    hoveredBackgroundColor: colorPalette[7],
    backgroundColorLight: colorPalette[3],
    textColorLight: colorPalette[8],
    shadowColorLight: colorPalette[5],
    hoveredBackgroundColorLight: colorPalette[3],
    infoBoxTextColor: colorPalette[8],
    inputBorderColor: colors.gray100,
    inputHoveredBorderColor: colors.gray300,
    inputFocusedBorderColor: colorPalette[8],
    inputDisabledBorderColor: colors.gray100,
    inputDisabledBackgroundColor: colors.gray50,
    inputDisabledTextColor: colors.gray500,
    inputDisabledPlaceholderColor: colors.gray300,
    tagTextColor: colorPalette[10],
    toggleChipColor: colors.white,
    toggleOffDisabledChipColor: colors.gray200,
    toggleOnDisabledChipColor: colorPalette[5],
    toggleOffBackgroundColor: colors.gray200,
    toggleOnDisabledBackgroundColor: colorPalette[6],
    toggleOffHoveredBackgroundColor: colors.gray300,
    toggleOffShadowColor: colors.gray150,
    toggleOffDisabledBackgroundColor: colors.gray100,
    hoveredCheckerBackgroundColor: colorPalette[4],
    grayElementBackgroundColor: colors.gray100,
    dropFileHoverColor: colorPalette[1],
  }

  const { configure } = useLukompoConfiguration()
  configure({ theme: currentTheme.value })
}

export const useTheme = () => ({
  updateTheme,
  currentTheme,
})
