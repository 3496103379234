import { filter, includes, map, values } from 'lodash-es'
import { computed, ref } from 'vue'

import {
  isEscooterProduct,
  isFrHomeProduct,
  isFrLandlordFlatProduct,
  isFrLandlordHouseProduct,
  isFrLandlordProduct,
  isInvertedRentProduct,
  isMortgageProduct,
  isRentProduct,
  isSciProduct,
} from 'src/lib/product'
import { Product, ProductCode } from 'src/types/product'

import { usePartner } from './partner'

const productIconMap = {
  [ProductCode.FR_HOME]: 'House',
  [ProductCode.FR_HOME_FLAT]: 'Apartment',
  [ProductCode.FR_HOME_HOUSE]: 'House',
  [ProductCode.FR_LANDLORD]: 'House',
  [ProductCode.FR_LANDLORD_FLAT]: 'Apartment',
  [ProductCode.FR_LANDLORD_HOUSE]: 'House',
  [ProductCode.FR_SECONDARY]: 'House',
  [ProductCode.FR_ESCOOTER]: 'eScooter',
  [ProductCode.FR_MORTGAGE]: 'Bank',
  [ProductCode.FR_RENT]: 'Cash',
  [ProductCode.FR_INVERTED_RENT]: 'Cash',
  [ProductCode.DE_BIKE]: 'Bike',
  [ProductCode.DE_EBIKE]: 'Bike',
  [ProductCode.DE_LIABILITY_DOG]: 'Dog',
  [ProductCode.DE_LIABILITY_PRIVATE]: 'Person',
  [ProductCode.DE_LIABILITY_PRIVATE_BROKER]: 'Person',
  [ProductCode.DE_HOME_CONTENT]: 'Couch',
  [ProductCode.DE_HOME_CONTENT_BROKER]: 'Couch',
  [ProductCode.DE_PET_HEALTH]: 'Bandaid',
}

const products: Product[] = map(values(ProductCode), (code) => ({
  code,
  icon: productIconMap[code],
}))

const currentProduct = ref<ProductCode>()

const isCurrentProductSci = computed(() => isSciProduct(currentProduct.value))

const isCurrentProductMortgage = computed(() =>
  isMortgageProduct(currentProduct.value)
)

const isCurrentProductFrHome = computed(() =>
  isFrHomeProduct(currentProduct.value)
)

const isCurrentProductFrLandlord = computed(() =>
  isFrLandlordProduct(currentProduct.value)
)

const isCurrentProductFrLandlordHouse = computed(() =>
  isFrLandlordHouseProduct(currentProduct.value)
)

const isCurrentProductFrLandlordFlat = computed(() =>
  isFrLandlordFlatProduct(currentProduct.value)
)

const isCurrentProductRent = computed(() => isRentProduct(currentProduct.value))

const isCurrentProductInvertedRent = computed(() =>
  isInvertedRentProduct(currentProduct.value)
)

const isCurrentProductEScooter = computed(() =>
  isEscooterProduct(currentProduct.value)
)

const supportedProducts = computed((): Product[] => {
  const { currentPartner, currentUserPartner, isLukoPartner } = usePartner()

  return filter(products, (product) => {
    if (isLukoPartner.value)
      return includes(currentUserPartner.value?.products, product.code)
    return includes(
      currentPartner.value?.config?.eligible_products,
      product.code
    )
  })
})

const setDefaultProduct = () => {
  currentProduct.value = ProductCode.FR_HOME
}

const setProduct = (code: ProductCode) => {
  if (!code) setDefaultProduct()

  currentProduct.value = code
}

export const useProduct = () => ({
  supportedProducts,
  currentProduct,
  isCurrentProductMortgage,
  isCurrentProductRent,
  isCurrentProductInvertedRent,
  isCurrentProductSci,
  isCurrentProductFrLandlord,
  isCurrentProductFrHome,
  isCurrentProductEScooter,
  isCurrentProductFrLandlordHouse,
  isCurrentProductFrLandlordFlat,
  setDefaultProduct,
  setProduct,
})
