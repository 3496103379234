import { computed } from 'vue'

import { useApp } from './app'
import { IS_DEV } from './constants'
const env = import.meta.env

const STRIPE_PUBLIC_KEY_DEV = env.VITE_APP_STRIPE_KEY_DEV
const STRIPE_PUBLIC_KEY_PROD = env.VITE_APP_STRIPE_KEY_PROD

const GOCARDLESS_KEY_DEV = env.VITE_APP_GOCARDLESS_KEY_DEV
const GOCARDLESS_KEY_PROD = env.VITE_APP_GOCARDLESS_KEY_PROD
const GOCARDLESS_URL_DEV = env.VITE_APP_GOCARDLESS_URL_DEV
const GOCARDLESS_URL_PROD = env.VITE_APP_GOCARDLESS_URL_PROD

const ONBOARDING_URL_DEV = env.VITE_APP_ONBOARDING_URL_DEV
const ONBOARDING_URL_PROD = env.VITE_APP_ONBOARDING_URL_PROD

const NOVELIA_IADVIZE_URL_DEV = env.VITE_APP_NOVELIA_IADVIZE_URL_DEV

const NOVELIA_IADVIZE_URL_PROD = env.VITE_APP_NOVELIA_IADVIZE_URL_PROD

const FOREST_COLLECTION_ID_CONTRACT_DEV =
  env.VITE_APP_FOREST_COLLECTION_ID_CONTRACT_DEV
const FOREST_COLLECTION_ID_CONTRACT_PROD =
  env.VITE_APP_FOREST_COLLECTION_ID_CONTRACT_PROD

const ARTEMIS_URL_PROD = env.VITE_APP_ARTEMIS_PROD
const ARTEMIS_URL_DEV = env.VITE_APP_ARTEMIS_DEV

const MY_LUKO_URL_PROD = env.VITE_APP_MY_LUKO_URL_PROD
const MY_LUKO_URL_DEV = env.VITE_APP_MY_LUKO_URL_DEV

const VALETUDO_URL_PROD = env.VITE_APP_VALETUDO_URL_PROD
const VALETUDO_URL_DEV = env.VITE_APP_VALETUDO_URL_DEV

const VALETUDO_TENANTFILE_URL_PROD = env.VITE_APP_VALETUDO_TENANTFILE_URL_PROD
const VALETUDO_TENANTFILE_URL_DEV = env.VITE_APP_VALETUDO_TENANTFILE_URL_DEV

const HYGIE_URL_PROD = env.VITE_APP_HYGIE_URL_PROD
const HYGIE_URL_DEV = env.VITE_APP_HYGIE_URL_DEV

const ADMIN_FOR_PARTNER_PROD = env.VITE_APP_ADMIN_FOR_PARTNER_URL_PROD
const ADMIN_FOR_PARTNER_DEV = env.VITE_APP_ADMIN_FOR_PARTNER_URL_DEV

const SELF_URL_PROD = env.VITE_APP_SELF_PROD
const SELF_URL_DEV = env.VITE_APP_SELF_DEV

const KHARON_URL_PROD = env.VITE_APP_KHARON_URL_PROD
const KHARON_URL_DEV = env.VITE_APP_KHARON_URL_DEV

const intercomKey = env.VITE_APP_INTERCOM_KEY

const apiBaseUrl = env.VITE_API_URL

const forestBaseUrl = env.VITE_APP_FOREST_URL

const stripeKey = computed(() => {
  if (IS_DEV) return STRIPE_PUBLIC_KEY_DEV

  const { hasTestData } = useApp()
  if (hasTestData.value) return STRIPE_PUBLIC_KEY_DEV

  return STRIPE_PUBLIC_KEY_PROD
})

const goCardlessKey = computed(() => {
  if (IS_DEV) return GOCARDLESS_KEY_DEV

  const { hasTestData } = useApp()
  if (hasTestData.value) return GOCARDLESS_KEY_DEV

  return GOCARDLESS_KEY_PROD
})

const goCardlessUrl = computed(() => {
  if (IS_DEV) return GOCARDLESS_URL_DEV

  const { hasTestData } = useApp()
  if (hasTestData.value) return GOCARDLESS_URL_DEV

  return GOCARDLESS_URL_PROD
})

const onboardingUrl = computed(() => {
  if (IS_DEV) return ONBOARDING_URL_DEV

  const { hasTestData } = useApp()
  if (hasTestData.value) return ONBOARDING_URL_DEV

  return ONBOARDING_URL_PROD
})

const artemisUrl = computed(() => {
  if (IS_DEV) return ARTEMIS_URL_DEV
  return ARTEMIS_URL_PROD
})

const myLukoUrl = computed(() => {
  if (IS_DEV) return MY_LUKO_URL_DEV
  return MY_LUKO_URL_PROD
})

const forestContractKey = computed(() => {
  if (IS_DEV) return FOREST_COLLECTION_ID_CONTRACT_DEV

  return FOREST_COLLECTION_ID_CONTRACT_PROD
})

const forestUserKey = env.VITE_APP_FOREST_COLLECTION_ID_USER

const forestTerminationKey = env.VITE_APP_FOREST_COLLECTION_ID_TERMINATION

const noveliaIadvizeBaseUrl = computed(() => {
  if (IS_DEV) return NOVELIA_IADVIZE_URL_DEV
  return NOVELIA_IADVIZE_URL_PROD
})

const valetudoBaseUrl = computed(() => {
  if (IS_DEV) return VALETUDO_URL_DEV
  return VALETUDO_URL_PROD
})

const valetudoTenantFileBaseUrl = computed(() => {
  if (IS_DEV) return VALETUDO_TENANTFILE_URL_DEV
  return VALETUDO_TENANTFILE_URL_PROD
})

const hygieBaseUrl = IS_DEV ? HYGIE_URL_DEV : HYGIE_URL_PROD
const selfBaseUrl = IS_DEV ? SELF_URL_DEV : SELF_URL_PROD
const kharonBaseUrl = IS_DEV ? KHARON_URL_DEV : KHARON_URL_PROD

const adminForPartnerUrl = computed(() => {
  if (IS_DEV) return ADMIN_FOR_PARTNER_DEV
  return ADMIN_FOR_PARTNER_PROD
})

export const useConfig = () => ({
  apiBaseUrl,
  intercomKey,
  stripeKey,
  goCardlessKey,
  goCardlessUrl,
  forestContractKey,
  forestUserKey,
  forestTerminationKey,
  noveliaIadvizeBaseUrl,
  onboardingUrl,
  artemisUrl,
  myLukoUrl,
  forestBaseUrl,
  valetudoBaseUrl,
  valetudoTenantFileBaseUrl,
  hygieBaseUrl,
  adminForPartnerUrl,
  selfBaseUrl,
  kharonBaseUrl,
})
