import { cloneDeep } from 'lodash-es'

import { usePayment } from 'src/modules/NewQuote/composables/usePayment'
import { useProspect } from 'src/modules/NewQuote/composables/useProspect'
import { useQuote } from 'src/modules/NewQuote/composables/useQuote'
import { IS_DEV } from 'src/store/constants'
import { usePartner } from 'src/store/partner'
import { useUser } from 'src/store/user'
import { useWorkspace } from 'src/store/workspace'

export const getEventData = () => {
  const { currentQuote, currentPrice } = useQuote()
  const { prospectData } = useProspect()
  const { currentPaymentMethod } = usePayment()

  const data = cloneDeep(prospectData)

  return {
    ...data,
    pricing: {
      pack: currentQuote.currentPackage?.code,
      monthly_price: currentPrice.value?.monthly?.full || null,
      yearly_price: currentPrice.value?.yearly?.full || null,
      furniture_capital: prospectData?.home?.main_asset?.content_value,
      deductible: prospectData?.deductible,
      start_date: prospectData?.start_date,
      coupon: {
        amount: currentQuote?.data?.coupon?.amount,
        code: currentQuote?.data?.coupon?.code,
        message: currentQuote?.data?.coupon?.message,
        success: currentQuote?.data?.coupon?.success,
      },
      commission: {
        sur_pricing: prospectData.broker_surcommission_percentage ?? null,
      },
      sign_url: prospectData.sign_url,
      ltv: currentQuote?.data?.commercial?.ltv,
      payment_existing: undefined,
      payment_method: prospectData.paid_date
        ? currentPaymentMethod.value
        : undefined,
      billing_frequency: prospectData.paid_date
        ? prospectData.billing_frequency
        : undefined,
      discount_latitude_percentage: Math.abs(
        currentQuote?.data?.discount_latitude_percentage ?? 0
      ),
      funnel_segmentation_group: currentQuote?.data?.funnel_segmentation_group,
      funnel_segmentation_set: currentQuote?.data?.funnel_segmentation_set,
      funnel_segmentation_version:
        currentQuote?.data?.funnel_segmentation_version,
    },
  }
}

export const getUserData = () => {
  const { user, currentACL } = useUser()
  const { currentPartner, currentPartnerId } = usePartner()
  const { currentWorkspace, currentWsId } = useWorkspace()

  return {
    email: user?.value?.email,
    firstname: user?.value?.firstname,
    lastname: user?.value?.lastname,
    user_role: currentACL.value,
    partner: {
      name: currentPartner?.value?.name,
      id: currentPartnerId.value,
      role: currentPartner?.value?.type,
      orias: currentPartner?.value?.orias_number,
      industry: currentPartner?.value?.industry,
    },
    workspace: {
      environment: IS_DEV ? 'test mode' : 'live mode',
      name: currentWorkspace.value?.name,
      id: currentWsId.value,
    },
  }
}
