<template>
  <Menu
    v-model:isOpened="isOpened"
    :is-disabled="isNoveliaSalesUser"
    data-luko-tracking="UserMenu"
    size="small"
    :class="[
      $style.menu,
      isOpened && $style.menuOpened,
      !isNoveliaSalesUser && $style.menuHover,
    ]"
  >
    <template #selector>
      <div :class="$style.selector">
        <Logo
          :logo-url="user?.avatar_url ?? ''"
          :name="`${user?.firstname} ${user?.lastname}`"
          size="24"
          :class="$style.avatar"
        />
        <span>{{ user?.firstname }}</span>
        <LukoIcon
          :svg="ChevronDown"
          color="gray400"
          :size="16"
          :class="[$style.chevronIcon, isOpened && $style.chevronIconReversed]"
        />
      </div>
    </template>
    <template #dropdown>
      <div :class="$style.options">
        <MenuOption
          data-luko-tracking="Account"
          :class="$style.option"
          @click="push({ name: 'account' })"
        >
          <LukoIcon color="gray700" :svg="Person" :size="16" />
          <span>
            {{ t('Navbar.UserMenu.account') }}
          </span>
        </MenuOption>
        <Separator thin light />
        <MenuOption
          data-luko-tracking="Logout"
          :class="$style.option"
          @click="push({ name: 'logout' })"
        >
          <LukoIcon color="gray700" :svg="Door" :size="16" />
          <span>
            {{ t('Navbar.UserMenu.logout') }}
          </span>
        </MenuOption>
      </div>
    </template>
  </Menu>
</template>

<script setup lang="ts">
import { ChevronDown, Door, LukoIcon, Person } from '@demain-es/lukompo'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { Logo } from 'src/components/Logo'
import { Menu } from 'src/components/Menu'
import { MenuOption } from 'src/components/MenuOption'
import { Separator } from 'src/components/Separator'
import { useUser } from 'src/store/user'

const { t } = useI18n()
const { push } = useRouter()
const { user, isNoveliaSalesUser } = useUser()

const isOpened = ref(false)
</script>

<style lang="scss" module>
.menu {
  padding: 6px 8px;
  border: 4px solid transparent;

  transition: border-color 0.3s ease-in;
}
.menuHover {
  &:hover {
    background: $gray-100;
  }
}
.menuOpened {
  border: 4px solid $gray-200;

  background: $gray-100;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
}

.option:hover > svg > path {
  fill: $gray-1000;
}

.selector {
  @include typo-sub-text;

  display: flex;
  gap: 4px;
  align-items: center;
}

.avatar {
  border: 2px solid $gray-200;
  border-radius: 50%;
}

.chevronIcon {
  transition: transform 0.3s ease;
}

.chevronIconReversed {
  transform: rotate(180deg);
}
</style>
