import { Api } from 'src/api'

import {
  PartnerKeyResponse,
  PartnerResponse,
  PartnersSearchParams,
  PartnersSearchResponse,
} from './types'

const getPartner = async (partnerId: number): Promise<PartnerResponse> => {
  const { data } = await Api.get(`v1/user/partners/${partnerId}`)

  return data
}

const getAllPartners = async (params: PartnersSearchParams) => {
  const { data } = await Api.get<PartnersSearchResponse>(`/v1/partners`, {
    ...(params && { params }),
    headers: { bearer: true },
  })
  return data
}

const getPartnerSelf = async (partnerUuid: string) => {
  const { data } = await Api.get<PartnerResponse>(
    `/v1/partners/${partnerUuid}/self`,
    { headers: { bearer: true } }
  )

  return data
}

const getPartnerKey = async (partnerId: number) => {
  const { data } = await Api.get<PartnerKeyResponse>(
    `/v1/partner/${partnerId}/config/key`
  )
  return data
}

const regeneratePartnerKey = async (partnerUuid: string) => {
  const { data } = await Api.post<PartnerKeyResponse>(
    `/v1/partner/${partnerUuid}/config/key/refresh`,
    {},
    { headers: { bearer: true } }
  )
  return data
}

const uploadLogo = async (partnerId: number, file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  const { data: data } = await Api.post(
    `/v1/partner/${partnerId}/logo`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data.logo_url
}

export {
  getPartner,
  getAllPartners,
  getPartnerSelf,
  getPartnerKey,
  uploadLogo,
  regeneratePartnerKey,
}
