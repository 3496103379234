import { Api } from 'src/api'
import { ApiRequestName } from 'src/types/api'
import { Prospect } from 'src/types/prospect'

import { ProspectPriceResponse } from '../types'

const getProspectPrice = async (prospectData: Prospect) => {
  const { data } = await Api.post<ProspectPriceResponse>(
    `/v1/prospect/price`,
    prospectData,
    {
      params: {
        lang: true,
        abortSignal: true,
        requestName: ApiRequestName.GET_PROSPECT_PRICE,
      },
      headers: { bearer: true },
    }
  )

  return data
}

const getPaidProspectPrice = async (key: Prospect['key']) => {
  const { data } = await Api.get<ProspectPriceResponse>(
    `/v1/prospect/${key}/price`,
    {
      params: {
        lang: true,
        abortSignal: true,
        requestName: ApiRequestName.GET_PAID_PROSPECT_PRICE,
      },
      headers: { bearer: true },
    }
  )

  return data
}

export { getProspectPrice, getPaidProspectPrice }
