import { includes, isNil, some } from 'lodash-es'
import { computed } from 'vue'

import { isFRHomeRepriseProduct, isMortgageProduct } from 'src/lib/product'
import { useProspect } from 'src/modules/NewQuote/composables/useProspect'
import { useQuote } from 'src/modules/NewQuote/composables/useQuote'
import { MortgageType, OwnerType } from 'src/modules/NewQuote/types'
import { usePartner } from 'src/store/partner'
import { useWorkspace } from 'src/store/workspace'
import { OutbuildingSize, PackageCode } from 'src/types/enums'
import { PartnerType } from 'src/types/partner'
import { ProductCode } from 'src/types/product'

const PRODUCT_TYPE_SELECTION_STEP = 'ProductType'
const FORMER_CONTRACT_STEPS = ['FormerContract', 'FormerDetails']

const MANDATORY_STEPS = [
  'DutyAdvice',
  'Address',
  'Property',
  'OwnerType',
  'Outbuilding',
  'OutbuildingSize',
  'Status',
  'PersonCovered',
  'HomeInfos',
  'NveiInfos',
  'SpeedInfos',
  'ClaimHistory',
  'FormerCancellation',
  'FormerContract',
  'FormerDetails',
  'ProspectInfos',
  'Company',
  'Guarantees',
  'Goods',
  'Deductible',
  'StartDate',
]

const { currentPartner, isLukoPartner } = usePartner()
const { getProspectData, isProspectMovingWithLuko } = useProspect()

const STEPS = computed((): Partial<Record<ProductCode, string[]>> => {
  const isProductTypeStepIncluded =
    !isProspectMovingWithLuko.value &&
    some(currentPartner.value?.config?.eligible_product_funnel, (val) =>
      includes(
        [ProductCode.FR_MORTGAGE, ProductCode.FR_ESCOOTER, ProductCode.FR_RENT],
        val
      )
    )

  return {
    [ProductCode.FR_HOME]: [
      ...(isProductTypeStepIncluded ? [PRODUCT_TYPE_SELECTION_STEP] : []),
      ...(isProspectMovingWithLuko.value ? ['StartDate'] : []),
      'DutyAdvice',
      'Address',
      'Property',
      ...(isProspectMovingWithLuko.value ? [] : ['OwnerType']),
      'Status',
      'PersonCovered',
      'HomeInfos',
      'HomeFeatures',
      'Outbuilding',
      'OutbuildingSize',
      'ClaimHistory',
      ...(isProspectMovingWithLuko.value ? ['CurrentContractDetails'] : []),
      ...(currentPartner.value?.type === PartnerType.DISTRIBUTOR
        ? ['FormerCancellation']
        : []),
      ...(!isLukoPartner.value || isProspectMovingWithLuko.value
        ? []
        : FORMER_CONTRACT_STEPS),
      'ProspectInfos',
      'Company',
      'Guarantees',
      'Options',
      'Goods',
      'Valuables',
      'Deductible',
      'Beneficiaries',
      ...(isLukoPartner.value || isProspectMovingWithLuko.value
        ? []
        : FORMER_CONTRACT_STEPS),
      ...(isProspectMovingWithLuko.value ? [] : ['StartDate']),
      'Summary',
      'Payment',
      'Signature',
    ],
    [ProductCode.FR_LANDLORD]: [
      ...(isProductTypeStepIncluded ? [PRODUCT_TYPE_SELECTION_STEP] : []),
      'DutyAdvice',
      'Address',
      'Property',
      'OwnerType',
      'PersonCovered',
      'HomeInfos',
      'RentalType',
      'HomeFeatures',
      'Outbuilding',
      'OutbuildingSize',
      'ClaimHistory',
      ...(!isLukoPartner.value ? [] : FORMER_CONTRACT_STEPS),
      'ProspectInfos',
      'Company',
      'Guarantees',
      'Options',
      'Goods',
      'Deductible',
      ...(isLukoPartner.value ? [] : FORMER_CONTRACT_STEPS),
      'StartDate',
      'Summary',
      'Payment',
      'Signature',
    ],
    [ProductCode.FR_MORTGAGE]: [
      ...(isProductTypeStepIncluded ? [PRODUCT_TYPE_SELECTION_STEP] : []),
      'Address',
      'Situation',
      'CurrentInsurance',
      'OwnerType',
      'Bank',
      'PersonCovered',
      'LoanTiers',
      'ProspectInfos',
      'Company',
      'SmokeInfo',
      'SecondProspectInfos',
      'SecondSmokeInfo',
      'MortgageShares',
      'Guarantees',
      'UpgradeShares',
      'Summary',
      'Signature',
    ],
    [ProductCode.FR_ESCOOTER]: [
      ...(isProductTypeStepIncluded ? [PRODUCT_TYPE_SELECTION_STEP] : []),
      'NveiInfos',
      'SpeedInfos',
      'Address',
      'ProspectInfos',
      'Guarantees',
      'SerialNumber',
      'StartDate',
      'Summary',
      'Payment',
      'Signature',
    ],
  }
})

const skippableStepsPredicates = {
  FormerDetails: () => !getProspectData('need_termination'),

  FormerCancellation: () => {
    const { product, other_info } = getProspectData()
    return (
      isFRHomeRepriseProduct(product, other_info?.external_operation) ||
      isProspectMovingWithLuko.value
    )
  },

  OutbuildingSize: () =>
    isNil(getProspectData('home.main_asset.outbuilding_size')) ||
    getProspectData('home.main_asset.outbuilding_size') ===
      OutbuildingSize.NONE,

  Status: () =>
    includes(
      [OwnerType.LANDLORD_FOR_TENANT],
      getProspectData('home.main_asset.owner_type')
    ),

  Options: () => getProspectData('package') === PackageCode.FR_LANDLORD_MINLEG,

  Valuables: () => {
    const { currentQuote } = useQuote()
    return (
      currentQuote.currentPackage &&
      !currentQuote.currentPackage?.optional_bundles?.some((bundle) =>
        includes(bundle.code, 'valuables')
      )
    )
  },

  DutyAdvice: () => {
    const { isNoveliaWs } = useWorkspace()
    return !isNoveliaWs.value
  },

  CurrentInsurance: () =>
    getProspectData('mortgage.main_asset.type') === MortgageType.NEW,

  Company: () => !getProspectData('user.subscriber.is_sci'),

  SecondProspectInfos: () => {
    if (!getProspectData('user.borrower')) return true

    if (getProspectData('user.borrower').length) return false
    return true
  },

  SecondSmokeInfo: () => {
    if (!getProspectData('user.borrower')) return true

    if (getProspectData('user.borrower').length) return false
    return true
  },

  PersonCovered: () => {
    if (isMortgageProduct(getProspectData('product'))) return false
    else {
      return !includes(
        [OwnerType.LANDLORD, OwnerType.LANDLORD_FOR_TENANT],
        getProspectData('home.main_asset.owner_type')
      )
    }
  },

  MortgageShares: () => {
    if (!getProspectData('user.borrower')) return true

    if (getProspectData('user.borrower').length) return false
    return true
  },

  UpgradeShares: () => {
    if (!getProspectData('user.borrower')) return true

    if (getProspectData('user.borrower').length) return false
    return true
  },
}

const skipStep = (stepName: string) => {
  const skippableStepsNames = Object.keys(skippableStepsPredicates)
  if (!skippableStepsNames.includes(stepName)) return false

  return skippableStepsPredicates[
    stepName as keyof typeof skippableStepsPredicates
  ]()
}

export { PRODUCT_TYPE_SELECTION_STEP, STEPS, MANDATORY_STEPS, skipStep }
