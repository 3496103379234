import { createRouter, createWebHistory } from 'vue-router'

import { authGuard } from './guards/auth-guard'
import { intercomGuard } from './guards/intercom-guard'
import { rbacGuard } from './guards/rbac-guard'
import { navHook } from './hooks/nav-hook'
import { routes } from './routes'

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach(authGuard)
router.beforeEach(rbacGuard)
router.beforeEach(intercomGuard)
router.afterEach(navHook)
