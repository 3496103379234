<template>
  <div>
    404 Not found
    <router-link to="/auth/login">{{ t('Errors.home') }}</router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ErrorModule',
  setup() {
    const { t } = useI18n()

    onMounted(() => {
      window.LukoTracking.trackEvent({
        id: 'Page not found',
      })
    })

    return {
      t,
    }
  },
})
</script>
