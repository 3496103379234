import { isNil } from 'lodash-es'

import { OwnerType } from 'src/modules/NewQuote/types'
import { ResidenceType } from 'src/types/enums'
import { ProductCode } from 'src/types/product'

const getProductCode = (
  isMain?: boolean,
  propertyType?: ResidenceType,
  type?: OwnerType,
  isEScooter?: boolean
): ProductCode => {
  if (isEScooter) return ProductCode.FR_ESCOOTER
  if (isNil(isMain) && isNil(propertyType) && isNil(type))
    return ProductCode.FR_MORTGAGE

  if (type === OwnerType.SECONDARY || (!isMain && type === null))
    return ProductCode.FR_HOME

  return type === OwnerType.LANDLORD
    ? ProductCode.FR_LANDLORD
    : ProductCode.FR_HOME
}

export { getProductCode }
