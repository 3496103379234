import { replace } from 'lodash-es'
import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'

import { useActions } from '../composables/useActions'

export const newCouponRoutes: RouteRecordRaw[] = [
  {
    path: '/new-coupon',
    name: 'newCoupon',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const newCoupon = await import('../NewCoupon.vue')
        return newCoupon
      } finally {
        await finishProgress()
      }
    },
    meta: {
      titleKey: 'Coupons.cta.newCoupon',
    },
    beforeEnter: (to, _, next) => {
      if (to.query.action === 'duplicate' && !!to.query.couponUuid) {
        const { duplicateCoupon } = useActions()
        duplicateCoupon(to.query.couponUuid as string)
        delete to.query.action
        delete to.query.couponUuid
        to.fullPath = replace(to.fullPath, /\?action=.*/, '')
      }

      next()
    },
  },
]
