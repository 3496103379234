import { forEach, get, has, keys, set, unset } from 'lodash'
import { reactive } from 'vue'

const couponData = reactive<Record<string, any>>({})

const setCouponData = (path: string, data: any) => {
  if (path) {
    set(couponData, path, data)
    return
  }

  keys(couponData).forEach((key) => {
    if (has(data, key)) setCouponData(key, get(data, key))
    else deleteCouponData(key)
  })

  keys(data).forEach((key) => {
    if (has(couponData, key)) return
    setCouponData(key, get(data, key))
  })
}

const deleteCouponData = (path: string) => unset(couponData, path)

const getCouponData = (path: string = '') =>
  path === '' ? couponData : get(couponData, path)

const clearCouponData = () => {
  forEach(keys(couponData), (k) => delete couponData[k])
}

export const useCouponData = () => ({
  getCouponData,
  setCouponData,
  deleteCouponData,
  clearCouponData,
})
