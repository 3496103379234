import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'
import { sleep } from 'src/lib/webUtils'
import { getCurrentPartnerDefaultRoute, isAuthorized } from 'src/store/auth'
import { usePartner } from 'src/store/partner'

export const contractDocumentRoutes: RouteRecordRaw[] = [
  {
    path: '/contract/:contractUuid/documents/:documentType',
    name: 'contractDocument',
    alias: ['/contract/:contractUuid/users/:userUuid/documents/:documentType'],
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const contractDocument = await import('../ContractDocument.vue')
        return contractDocument
      } finally {
        await finishProgress()
      }
    },
    meta: {
      titleKey: 'ContractDocument.title',
      isPlain: true,
    },
    beforeEnter: async () => {
      const { currentPartner, isLukoPartner } = usePartner()

      while (!currentPartner.value) {
        await sleep(100)
      }

      if (isAuthorized('contractDocument') && isLukoPartner.value) return
      return { name: getCurrentPartnerDefaultRoute() }
    },
  },
]
