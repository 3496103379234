<template>
  <div :class="$style.placeholderContainer">
    <div :class="$style.placeholderAnimation" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Placeholder',
})
</script>

<style lang="scss" module>
.placeholderContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.placeholderAnimation {
  width: 50%;
  height: 100%;

  background-color: white;
  opacity: 0.2;

  filter: blur(10px);

  animation: shine 900ms infinite ease-in-out;
}

@keyframes shine {
  0% {
    transform: translateX(-100%);
    opacity: 0.2;
  }
  100% {
    transform: translateX(300%);
    opacity: 0.2;
  }
}
</style>
