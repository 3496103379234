<template>
  <ProgressBar
    v-if="isProgressing"
    v-model="progressPercentage"
    :class="$style.progressBar"
  />
</template>

<script setup lang="ts">
import { ProgressBar } from 'src/components/ProgressBar'
import { useDownloadProgress } from 'src/composables/useDownloadProgress'

const { isProgressing, progressPercentage } = useDownloadProgress()
</script>

<style lang="scss" module>
.progressBar {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 9999;

  width: 100%;
  height: 4px;
}
</style>
