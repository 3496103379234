<template>
  <div
    v-if="listToDisplay.length"
    :class="$style.navList"
    data-luko-tracking="NavList"
  >
    <NavListItem
      v-for="(item, index) in listToDisplay"
      :key="index"
      :item="item"
    />
  </div>
</template>

<script setup lang="ts">
import { filter } from 'lodash-es'

import { NavItem } from 'src/modules/Navbar/types'

import NavListItem from '../NavListItem/NavListItem.vue'

const props = defineProps({
  list: {
    type: Array as () => NavItem[],
    required: true,
  },
})

const listToDisplay = filter(props.list, 'isAuthorized')
</script>

<style lang="scss" module>
.navList {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
</style>
