import { ref } from 'vue'

import { getCoupon } from 'src/api/coupon'

import { CouponDetails } from '../types'

const isFetchingDetails = ref(false)
const couponDetails = ref<CouponDetails>()

const fetchCouponDetails = async (couponCode: string) => {
  try {
    isFetchingDetails.value = true
    const coupon = await getCoupon(couponCode)

    if (!coupon) return

    couponDetails.value = coupon
  } finally {
    isFetchingDetails.value = false
  }
}

const clearCouponDetails = () => {
  couponDetails.value = undefined
}

export const useCouponDetails = () => ({
  couponDetails,
  isFetchingDetails,
  fetchCouponDetails,
  clearCouponDetails,
})
