import { includes, values } from 'lodash-es'

import { DEFAULT_LOCALE } from 'src/store/locale'
import { Language } from 'src/types/user'

const getBrowserLocale = () => navigator.language

const getDefaultLocale = () => {
  const browserLocale = getBrowserLocale()
  const countryCode = browserLocale.split('-')[0]

  if (includes(values(Language), countryCode as Language))
    return countryCode as Language

  return DEFAULT_LOCALE as Language
}

export { getDefaultLocale }
