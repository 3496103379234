import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'
import { sleep } from 'src/lib/webUtils'
import { getCurrentPartnerDefaultRoute } from 'src/store/auth'
import { useCountry } from 'src/store/country'
import { usePartner } from 'src/store/partner'

export const billingRoutes: RouteRecordRaw[] = [
  {
    path: '/billing',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const BillingModule = await import('../Billing.vue')
        return BillingModule
      } finally {
        await finishProgress()
      }
    },
    name: 'billing',
    meta: {
      titleKey: 'Billing.title',
    },
    beforeEnter: async () => {
      const { currentPartner } = usePartner()
      const { isFrance } = useCountry()

      while (!currentPartner.value) {
        await sleep(100)
      }

      const partnerHasBillingInfo = currentPartner.value?.has_billing_info

      if (partnerHasBillingInfo && isFrance.value) return

      return { name: getCurrentPartnerDefaultRoute() }
    },
  },
]
