enum ProductCode {
  FR_HOME = 'FR_HOME',
  FR_HOME_FLAT = 'FR_HOME_FLAT',
  FR_HOME_HOUSE = 'FR_HOME_HOUSE',
  FR_LANDLORD = 'FR_LANDLORD',
  FR_LANDLORD_FLAT = 'FR_LANDLORD_FLAT',
  FR_LANDLORD_HOUSE = 'FR_LANDLORD_HOUSE',
  FR_SECONDARY = 'FR_SECONDARY',
  FR_ESCOOTER = 'FR_ESCOOTER',
  FR_MORTGAGE = 'FR_MORTGAGE',
  FR_RENT = 'FR_RENT',
  FR_INVERTED_RENT = 'FR_INVERTED_RENT',
  DE_BIKE = 'DE_BIKE',
  DE_EBIKE = 'DE_EBIKE',
  DE_LIABILITY_DOG = 'DE_LIABILITY_DOG',
  DE_LIABILITY_PRIVATE = 'DE_LIABILITY_PRIVATE',
  DE_LIABILITY_PRIVATE_BROKER = 'DE_LIABILITY_PRIVATE_BROKER',
  DE_HOME_CONTENT = 'DE_HOME_CONTENT',
  DE_HOME_CONTENT_BROKER = 'DE_HOME_CONTENT_BROKER',
  DE_PET_HEALTH = 'DE_PET_HEALTH',
}

interface Product {
  code: ProductCode
  icon: string
}

export { Product, ProductCode }
