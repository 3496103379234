<template>
  <div
    v-for="index in 4"
    :key="index"
    :class="$style.listItem"
    :style="{ opacity: 1 - 0.15 * index }"
  >
    <Placeholder :class="$style.logo" />
    <Placeholder
      :class="$style.text"
      :style="{ width: `${Math.floor(Math.random() * 41) + 80}px` }"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { Placeholder } from 'src/components/Placeholder'

export default defineComponent({
  name: 'ListPlaceholder',
  components: { Placeholder },
})
</script>

<style lang="scss" module>
.listItem {
  display: flex;
  gap: 8px;
  margin-top: 4px;
  padding: 6px 12px;
}

.logo {
  width: 24px;
  height: 24px;

  border-radius: 50%;

  background-color: $gray-200;
}

.text {
  align-self: center;
  height: 20px;

  border-radius: 9px;

  background-color: $gray-200;
}
</style>
