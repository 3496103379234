enum ApiRequestName {
  GET_PROSPECTS = 'getProspects',
  GET_PROSPECT_PRICE = 'getProspectPrice',
  GET_PAID_PROSPECT_PRICE = 'getPaidProspectPrice',
  GET_EXISTING_PAYMENT_METHODS = 'getExistingPaymentMethods',
  GET_CONTRACTS = 'getContracts',
  GET_CONSUMERS = 'getConsumers',
  GET_COUPONS = 'getCoupons',
  GET_AMENDS = 'getAmends',
  GET_CONTRACT_DETAILS = 'getContractDetails',
  GET_TERMINATION_DETAILS = 'getTerminationDetails',
  GET_TERMINATION_TIMELINE = 'getTerminationTimeline',
  GET_CONTRACT_VERSIONS = 'getContractVersions',
  CREATE_AMEND_INTENT = 'createIntent',
  GET_EVENT_TRACKINGS = 'getEventTrackings',
}

export { ApiRequestName }
