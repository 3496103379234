import { ref, watch } from 'vue'

import { sleep } from 'src/lib/webUtils'

const isProgressing = ref(false)
const progressPercentage = ref(0)

const initiateProgress = () => {
  isProgressing.value = true
  progressPercentage.value = 1
}

watch(progressPercentage, async (val) => {
  if (!isProgressing.value || val >= 90) return

  await sleep(200)
  if (val > 50) progressPercentage.value += 1
  else progressPercentage.value += 5
})

const finishProgress = async () => {
  if (!isProgressing.value) return

  progressPercentage.value = 100
  await sleep(400)
  isProgressing.value = false
  progressPercentage.value = 0
}

export const useDownloadProgress = () => ({
  isProgressing,
  progressPercentage,
  initiateProgress,
  finishProgress,
})
