import { entries, includes, isEmpty, join, some } from 'lodash-es'
import { computed } from 'vue'

import { i18n } from 'src/i18n'
import { Address, AddressFull, Country, CountryCode } from 'src/types/address'

const formatAddress = (address: Partial<Address | AddressFull>) => {
  if (
    isEmpty(address) ||
    some(
      entries(address),
      ([k, v]) => !includes(['country_code', 'complement'], k) && !v
    )
  )
    return ''

  return join(
    [
      address.address ?? address.street_and_number ?? address.address_main,
      address.postcode,
      address.city,
      address.country,
    ],
    ', '
  )
}

const getCodeForCountry = (country?: Country) => {
  if (country === Country.GERMANY) return CountryCode.GERMANY
  return CountryCode.FRANCE
}

const { t } = i18n.global

const addressFormConfig = computed(() => ({
  label: t('AddressConfig.label'),
  placeholder: t('AddressConfig.placeholder'),
  switchCta: t('AddressConfig.switch_cta'),
  error: {
    postcode: t('AddressConfig.error.missing_postcode'),
  },
}))

const manualAddressFormConfig = computed(() => ({
  title: t('AddressConfig.manual.title'),
  label: {
    addressMain: t('AddressConfig.manual.address_main'),
    postcode: t('AddressConfig.manual.postcode'),
    city: t('AddressConfig.manual.city'),
    country: t('AddressConfig.manual.country'),
  },
  placeholder: {
    addressMain: t('AddressConfig.manual.address_main_placeholder'),
    postcode: t('AddressConfig.manual.postcode_placeholder'),
    city: t('AddressConfig.manual.city_placeholder'),
  },
  cta: {
    cancel: t('App.cta.cancel'),
    submit: t('App.cta.confirm'),
    selectCountry: t('App.cta.select'),
  },
  error: {
    postcode: t('AddressConfig.error.invalid_postcode'),
  },
}))

export {
  addressFormConfig,
  manualAddressFormConfig,
  formatAddress,
  getCodeForCountry,
}
