import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'
import { useFilters } from 'src/composables/useFilters'
export const partnerDetailsRoutes: RouteRecordRaw[] = [
  {
    path: '/partner/:partnerUuid',
    component: () => import('../PartnerDetails.vue'),
    name: 'partner',
    children: [
      {
        path: '',
        name: 'partnerDetails',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const partnerOverview = await import(
              '../components/PartnerOverview/PartnerOverview.vue'
            )
            return partnerOverview
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'PartnerDetails.title',
        },
      },
      {
        path: 'contracts',
        name: 'partnerContracts',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const partnerContractsList = await import(
              '../components/PartnerContracts/PartnerContractsList/PartnerContractsList.vue'
            )
            return partnerContractsList
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'PartnerDetails.title',
        },
        beforeEnter: (_, __, next) => {
          const { clearFilters } = useFilters()
          clearFilters()
          next()
        },
      },
    ],
  },
]
