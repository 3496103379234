const getStorageItem = (key: string) => localStorage.getItem(key)

const setStorageItem = (key: string, value: string) =>
  localStorage.setItem(key, value)

const removeStorageItem = (key: string) => localStorage.removeItem(key)

export const useLocalStorage = () => ({
  getStorageItem,
  setStorageItem,
  removeStorageItem,
})
