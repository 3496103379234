import { Api } from 'src/api'

import { UserPartnersSearchParams, UserPartnersSearchResponse } from '../types'

const getUserPartners = async (params?: UserPartnersSearchParams) => {
  const { data } = await Api.get<UserPartnersSearchResponse>(
    '/v1/user/partners',
    { ...(params && { params }) }
  )
  return data
}

export { getUserPartners }
