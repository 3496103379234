import { ref } from 'vue'

const isLoaderActive = ref(false)

const showLoader = () => (isLoaderActive.value = true)

const hideLoader = () => {
  setTimeout(() => {
    isLoaderActive.value = false
  }, 500)
}

export const useLoader = () => ({
  isLoaderActive,
  showLoader,
  hideLoader,
})
