import { RouteLocationNormalized } from 'vue-router'

import { useWorkspace } from '../../store/workspace'

const wsRoutes = ['workspaceSettings']

export const navHook = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  const isWsContext = !!(
    wsRoutes.includes(to.name as string) ||
    (wsRoutes.includes(from.name as string) && to.name === 'account')
  )

  const { set: setWs } = useWorkspace()

  setWs.isWorkspaceContext(isWsContext)
}
