<template>
  <transition
    name="expand"
    appear
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <slot />
  </transition>
</template>

<script setup lang="ts">
const props = defineProps({ fixedWidth: { type: Boolean, default: false } })

const enter = (element: Element) => {
  const HtmlElement = element as HTMLElement
  const width = getComputedStyle(element).width
  const height = getComputedStyle(HtmlElement).height

  if (props.fixedWidth) HtmlElement.style.width = width
  HtmlElement.style.position = 'absolute'
  HtmlElement.style.visibility = 'hidden'
  HtmlElement.style.height = '0px'

  requestAnimationFrame(() => {
    HtmlElement.style.position = ''
    HtmlElement.style.height = height
    HtmlElement.style.visibility = ''
  })
}

const afterEnter = (element: Element) =>
  ((element as HTMLElement).style.height = 'auto')

const leave = (element: Element) => {
  const HtmlElement = element as HTMLElement

  requestAnimationFrame(() => (HtmlElement.style.height = '0px'))
}
</script>

<style>
.expand-enter-active[class],
.expand-leave-active[class] {
  overflow: hidden;

  transition: height 0.5s ease-in-out;

  will-change: height;
}

.expand-enter[class],
.expand-leave-to[class] {
  height: 0;
}
</style>
