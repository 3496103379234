import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'

export const couponsRoutes: RouteRecordRaw[] = [
  {
    path: '/coupons',
    name: 'coupons',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const couponsModule = await import('../Coupons.vue')
        return couponsModule
      } finally {
        await finishProgress()
      }
    },
    meta: {
      titleKey: 'Coupons.metaTitle',
    },
  },
]
