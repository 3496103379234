import { Warning } from '@demain-es/lukompo'
import { replace } from 'lodash-es'
import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'
import { useNotification } from 'src/composables/useNotification'
import { i18n } from 'src/i18n'
import { sleep } from 'src/lib/webUtils'
import { usePartner } from 'src/store/partner'
import { useWorkspace } from 'src/store/workspace'

export const quoteRoutes: RouteRecordRaw[] = [
  {
    path: '/new-quote/:key?',
    name: 'newQuote',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const newQuoteModule = await import('../NewQuote.vue')
        return newQuoteModule
      } finally {
        await finishProgress()
      }
    },
    meta: { titleKey: 'NewQuote.metaTitle' },
    beforeEnter: async (to, _, next) => {
      const { currentPartner } = usePartner()

      while (!currentPartner.value) {
        await sleep(100)
      }

      const { isNoveliaWs } = useWorkspace()
      if (isNoveliaWs.value) {
        const { t } = i18n.global
        const { showNotification } = useNotification()
        showNotification({
          message: t('Errors.currentlyUnavailable'),
          icon: Warning,
          theme: 'danger',
        })
        next({ name: 'contracts' })
      } else {
        if (to.query.movingOutContractUuid) {
          to.meta['movingOutContractUuid'] = to.query.movingOutContractUuid
          delete to.query.movingOutContractUuid
          to.fullPath = replace(to.fullPath, /\?movingOutContractUuid=.*/, '')
        }
        next()
      }
    },
  },
]
