<template>
  <div
    ref="menuElement"
    :class="$style.menu"
    :data-luko-tracking="dataLukoTracking"
    @click.stop.prevent="toggleIsOpened"
  >
    <slot name="selector" />
    <TransitionExpand>
      <div
        v-if="isOpened && !isDisabled"
        :style="dropdownStyle"
        :class="$style.dropdownContainer"
      >
        <div ref="dropdownElement" :class="$style.dropdown">
          <slot name="dropdown" />
        </div>
      </div>
    </TransitionExpand>
  </div>
</template>

<script setup lang="ts">
import { debounce } from 'lodash-es'
import { computed, ref, watch } from 'vue'

import { TransitionExpand } from 'src/components/TransitionExpand'

const props = defineProps({
  dataLukoTracking: {
    type: String,
    default: 'Menu',
  },
  isOpened: {
    type: Boolean,
    required: true,
  },
  direction: {
    type: String as () =>
      | 'top-left'
      | 'top-right'
      | 'bottom-left'
      | 'bottom-right',
    default: 'top-right',
  },
  size: {
    type: String as () => 'small' | 'medium' | 'large' | 'full',
    default: 'medium',
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:isOpened'])

const dropdownElement = ref<HTMLElement | null>(null)
const menuElement = ref<HTMLElement | null>(null)
const menuHeight = ref(0)
const toggleIsOpened = () => {
  menuHeight.value = menuElement.value?.offsetHeight ?? 0

  const newVal = !props.isOpened
  emit('update:isOpened', newVal)
}

const handleClick = debounce(() => {
  emit('update:isOpened', false)
}, 200)

watch(
  () => props.isOpened,
  (value) => {
    if (value) {
      document.addEventListener('click', handleClick)
      return
    }

    document.removeEventListener('click', handleClick)
  }
)

const directionStyleMap = computed(() => {
  return {
    'top-right': {
      bottom: `${menuHeight.value}px`,
      left: '0px',
      right: 'auto',
    },
    'top-left': { bottom: `${menuHeight.value}px`, right: '0px', left: 'auto' },
    'bottom-right': {
      top: `${menuHeight.value}px`,
      left: '0px',
      right: 'auto',
    },
    'bottom-left': { top: `${menuHeight.value}px`, right: '0px', left: 'auto' },
  }
})

const sizeStyleMap = {
  small: { width: '200px' },
  medium: { width: '300px' },
  large: { width: '400px' },
  full: { width: '100%' },
}

const dropdownStyle = computed(() => ({
  ...directionStyleMap.value[props.direction],
  ...sizeStyleMap[props.size],
}))
</script>

<style lang="scss" module>
.menu {
  position: relative;

  display: flex;
  align-items: center;
  height: fit-content;
  padding: 6px 12px;
  border-radius: 8px;

  white-space: nowrap;

  cursor: v-bind('isDisabled ? `default` : `pointer`');

  pointer-events: v-bind('isDisabled ? `none`: `auto`');

  &:hover {
    background-color: $gray-100;
  }
}

.dropdownContainer {
  position: absolute;
  z-index: 3;

  height: auto;
  overflow: hidden;

  box-shadow: 0px 2px 16px 0px rgba($gray-1000, 0.12);
}

.dropdown {
  border-radius: 8px;

  background: $white;
  box-shadow: 0px 2px 16px 0px rgba($gray-1000, 0.12);
}
</style>
