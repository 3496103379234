import { Api } from 'src/api'
import { ApiRequestName } from 'src/types/api'

import {
  CouponResponse,
  CouponsQueryParams,
  CouponsResponse,
  CreateCouponPayload,
  CreateCouponResponse,
  UpdateCouponPayload,
  UpdateCouponResponse,
} from './types'

const getCoupons = async (params: CouponsQueryParams) => {
  const { data } = await Api.get<CouponsResponse>('/v1/coupons', {
    params: {
      ...params,
      abortSignal: true,
      requestName: ApiRequestName.GET_COUPONS,
    },
    headers: { bearer: true },
  })

  return data
}

const getCoupon = async (couponCode: string) => {
  const { data } = await Api.get<CouponResponse>(`/v1/coupons/${couponCode}`, {
    headers: { bearer: true },
  })

  return data
}

const createCoupon = async (payload: CreateCouponPayload) => {
  const { data } = await Api.post<CreateCouponResponse>(
    '/v1/coupons',
    payload,
    { headers: { bearer: true } }
  )

  return data
}

const updateCoupon = async (
  couponCode: string,
  payload: UpdateCouponPayload
) => {
  const { data } = await Api.patch<UpdateCouponResponse>(
    `/v1/coupons/${couponCode}`,
    payload,
    { headers: { bearer: true } }
  )

  return data
}

const deleteCoupon = async (couponCode: string) => {
  const { data } = await Api.delete(`/v1/coupons/${couponCode}`, {
    headers: { bearer: true },
  })

  return data
}

export { getCoupons, getCoupon, createCoupon, updateCoupon, deleteCoupon }
