import { includes, map } from 'lodash-es'
import { ref, watch } from 'vue'

const possibleContentValues = ref<number[]>([])
const steps = ref<Array<{ value: number; label: string }>>([])

watch(
  possibleContentValues,
  (val) => {
    const length = val.length
    steps.value = map(val, (value, i) => ({
      value,
      label: includes([2, Math.floor(length / 2), length - 3], i)
        ? String(value)
        : '',
    }))
  },
  { immediate: true }
)

export const useLocal = () => ({
  steps,
  possibleContentValues,
})
