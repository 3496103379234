import { App } from 'vue'

import { install as installErrorHandler } from './errorHandler'
import { install as installGtm } from './gtm'
import { install as installHead } from './head'
import { install as installLukompo } from './lukompo'
import { install as installLukosiris } from './lukosiris'
import { install as installSentry } from './sentry'
import { install as installStorage } from './storage'
import { install as installVSanitize } from './v-sanitize'

export const installPlugins = (app: App) => {
  const plugins = [
    installSentry,
    installErrorHandler,
    installStorage,
    installGtm,
    installHead,
    installLukompo,
    installLukosiris,
    installVSanitize,
  ]

  plugins.forEach(app.use)
}
