<template>
  <div :class="$style.appLoader">
    <LukoSpinner size="40" />
    <span :class="$style.text">{{ t('App.message.loading') }}</span>
  </div>
</template>

<script setup lang="ts">
import { LukoSpinner } from '@demain-es/lukompo'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>

<style lang="scss" module>
.appLoader {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  background-color: $gray-50;
}

.text {
  @include typo-large-title;
  text-align: center;
}
</style>
