import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'

export const findRoutes: RouteRecordRaw[] = [
  {
    path: '/find',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const findModule = await import('../Find.vue')
        return findModule
      } finally {
        await finishProgress()
      }
    },
  },
]
