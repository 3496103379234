interface Address {
  street_and_number?: string // for contracts
  address_main?: string // for prospects
  address?: string // for consumers
  complement?: string | null
  postcode: string
  city: string
  country: Country | ''
  country_code?: CountryCode | ''
}

interface AddressFull extends Address {
  latitude: string
  longitude: string
}

enum CountryCode {
  FRANCE = 'FR',
  GERMANY = 'DE',
}

enum Country {
  FRANCE = 'France',
  GERMANY = 'Germany',
}

export { Address, AddressFull, CountryCode, Country }
