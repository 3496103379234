import '@stripe/stripe-js'

import { createApp } from 'vue'

import 'src/scss/globals/_module.scss'
import { RootModule } from 'src/modules/Root'
import { installPlugins } from 'src/plugins'
import { router } from 'src/router'

import { i18n } from './i18n'

const app = createApp(RootModule)

app.use(i18n)
app.use(router)

installPlugins(app)

app.mount('#app')

export { app }
