import { ref } from 'vue'

import { useLocalStorage } from 'src/lib/localStorage'

import { usePartner } from './partner'

const { getStorageItem, setStorageItem } = useLocalStorage()

// Set default value
if (getStorageItem('testEnv') === null) {
  setStorageItem('testEnv', 'false')
}
const hasTestData = ref(getStorageItem('testEnv') === 'true')

const switchTestData = async () => {
  setStorageItem('testEnv', String(hasTestData.value))
  const { refreshCurrentKey } = usePartner()
  await refreshCurrentKey()
}

const useApp = () => ({
  hasTestData,
  switchTestData,
})

export { useApp }
