import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'

export const authRoutes: RouteRecordRaw[] = [
  {
    path: '/auth',
    name: 'auth',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const authModule = await import('../Auth.vue')
        return authModule
      } finally {
        await finishProgress()
      }
    },
    meta: {
      isPlain: true,
    },
    children: [
      {
        path: 'signup',
        name: 'signup',
        beforeEnter: ({ query: { email, invitation_token } }, _, next) => {
          if (email && invitation_token) next()
          else next({ name: 'login' })
        },
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const signupView = await import('../views/Signup/Signup.vue')
            return signupView
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'Auth.signup.title',
        },
      },
      {
        path: 'login',
        name: 'login',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const loginView = await import('../views/Login/Login.vue')
            return loginView
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'Auth.login.title',
        },
      },
      {
        path: 'logout',
        name: 'logout',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const logoutView = await import('../views/Logout/Logout.vue')
            return logoutView
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'Auth.logout.title',
        },
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const forgotPasswordView = await import(
              '../views/ForgotPassword/ForgotPassword.vue'
            )
            return forgotPasswordView
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'Auth.forgot_password.title',
        },
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const resetPasswordView = await import(
              '../views/ResetPassword/ResetPassword.vue'
            )
            return resetPasswordView
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'Auth.reset_password.title',
        },
      },
    ],
  },
]
