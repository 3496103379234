import { Warning } from '@demain-es/lukompo'
import * as Sentry from '@sentry/vue'
import { computed, readonly, ref, watch } from 'vue'

import { useLoader } from 'src/composables/useLoader'
import { useNotification } from 'src/composables/useNotification'
import { i18n, setI18nLanguage } from 'src/i18n'
import { getOtpJwtPayload } from 'src/lib/auth'
import { getDefaultLocale } from 'src/lib/locale'
import { useLocalStorage } from 'src/lib/localStorage'
import { Acl, Language, User } from 'src/types/user'

import { login, logoutAPI, sendOtp, ssoLogin, verifyOtp } from '../api/auth'
import { LoginParams } from '../api/auth/types'
import { getUser, updateUserLanguage, uploadAvatarAPI } from '../api/user'
import { usePartner } from './partner'
import { useWorkspace } from './workspace'

const user = ref<User | null>(null)
const userJwt = ref<string | null>(null)
const currentACL = ref<Acl | null>(null)
const lang = ref<Language>(getDefaultLocale())

const isLogged = ref(false)
const isNoveliaSalesUser = computed(() => {
  const { isNoveliaWs } = useWorkspace()

  return isNoveliaWs.value && currentACL.value === Acl.SALES
})
const isWorkspaceSuperAdmin = computed(
  () => !!user.value?.is_workspace_super_admin
)

const hasSoftDeletedAccess = computed(
  () => !!user.value?.has_soft_deleted_access
)

watch(lang, (val) => setI18nLanguage(val), { immediate: true })

const postLoginUser = async () => {
  const { setStorageItem } = useLocalStorage()
  setStorageItem('isLogged', 'true')

  const { showLoader } = useLoader()
  showLoader()

  clearUserJwt()
  await refreshUser()

  window.LukoTracking.trackEvent({ id: 'Login' })
}

const loginUser = async (credentials: LoginParams) => {
  const { partner_jwt } = await login(credentials)
  userJwt.value = partner_jwt
  return getOtpJwtPayload(userJwt.value)
}

const { showNotification } = useNotification()
const { t } = i18n.global

const verifyLoginOtp = async (code: string) => {
  if (!userJwt.value) {
    showNotification({
      message: t('Errors.401'),
      icon: Warning,
      theme: 'danger',
    })
    return
  }

  await verifyOtp(code, userJwt.value)
}

const sendLoginOtp = async () => {
  if (!userJwt.value) {
    showNotification({
      message: t('Errors.401'),
      icon: Warning,
      theme: 'danger',
    })
    return
  }

  await sendOtp(userJwt.value)
}

const ssoLoginUser = async (ssoToken: string) => await ssoLogin(ssoToken)

const refreshUser = async () => {
  const userData = await getUser()
  user.value = userData
  lang.value = (userData.lang as Language) || getDefaultLocale()
  isLogged.value = true

  Sentry.setUser({
    name: `${userData.firstname} ${userData.lastname}`,
    email: userData.email,
    id: String(userData.id),
  })

  const { updateCurrentUserPartners, setCurrentPartner } = usePartner()
  const {
    set: { currentWorkspace: setcurrentWorkspace },
  } = useWorkspace()

  await setCurrentPartner()
  await setcurrentWorkspace()
  await updateCurrentUserPartners()
}

const uploadAvatar = async (file: File) => {
  await uploadAvatarAPI(file)
  user.value = await getUser()
}

const changeLang = async (code: Language) => {
  await updateUserLanguage(code)
  lang.value = code
}

const resetUser = () => {
  const { removeStorageItem } = useLocalStorage()
  removeStorageItem('isLogged')
  user.value = null
  isLogged.value = false
}

const clearUserJwt = () => (userJwt.value = null)

const logoutUser = async () => {
  await logoutAPI()
  resetUser()

  const { resetPartner } = usePartner()
  resetPartner()

  const { resetWorkspace } = useWorkspace()
  resetWorkspace()
}

export const useUser = () => ({
  user: readonly(user),
  isLogged: readonly(isLogged),
  isWorkspaceSuperAdmin: readonly(isWorkspaceSuperAdmin),
  hasSoftDeletedAccess: readonly(hasSoftDeletedAccess),
  currentACL,
  isNoveliaSalesUser,
  lang,
  loginUser,
  postLoginUser,
  refreshUser,
  uploadAvatar,
  changeLang,
  resetUser,
  logoutUser,
  ssoLoginUser,
  verifyLoginOtp,
  sendLoginOtp,
  clearUserJwt,
})
