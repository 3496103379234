import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'

export const consumerDetailsRoutes: RouteRecordRaw[] = [
  {
    path: '/consumer/:consumerUuid',
    component: () => import('../ConsumerDetails.vue'),
    name: 'consumer',
    children: [
      {
        path: '',
        name: 'consumerDetails',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const consumerOverview = await import(
              '../components/ConsumerOverview/ConsumerOverview.vue'
            )
            return consumerOverview
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'ConsumerDetails.title',
        },
      },
      {
        path: 'contracts',
        name: 'consumerContracts',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const consumerContractsList = await import(
              '../components/ConsumerContracts/ConsumerContractsList/ConsumerContractsList.vue'
            )
            return consumerContractsList
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'ConsumerDetails.title',
        },
      },
      {
        path: 'prospects',
        name: 'consumerProspects',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const consumerProspectsList = await import(
              '../components/ConsumerProspects/ConsumerProspectsList/ConsumerProspectsList.vue'
            )
            return consumerProspectsList
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'ConsumerDetails.title',
        },
      },
      {
        path: 'invoices/:selectedInvoiceId?',
        name: 'consumerInvoices',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const consumerInvoicesList = await import(
              '../components/ConsumerInvoices/ConsumerInvoicesList/ConsumerInvoicesList.vue'
            )
            return consumerInvoicesList
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'ConsumerDetails.title',
        },
      },
      {
        path: 'activities/:selectedActivityUuid?',
        name: 'consumerActivities',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const consumerActivityList = await import(
              '../components/ConsumerActivity/ConsumerActivityList/ConsumerActivityList.vue'
            )
            return consumerActivityList
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'ConsumerDetails.title',
        },
      },
      {
        path: 'receipts/:selectedReceiptId?',
        name: 'consumerReceipts',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const consumerReceiptList = await import(
              '../components/ConsumerReceipts/ConsumerReceiptList/ConsumerReceiptList.vue'
            )
            return consumerReceiptList
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'ConsumerDetails.title',
        },
      },
    ],
  },
]
