import { Api } from 'src/api'

import { WorkspaceLogoResponse } from './types'

const uploadLogo = async (workspaceId: number, file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  const { data } = await Api.post<WorkspaceLogoResponse>(
    `/v1/workspace/${workspaceId}/logo`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data.logo_url
}

const updateMainColor = async (workspaceId: number, mainColor: string) =>
  Api.patch(`/v1/workspace/${workspaceId}/config`, {
    main_color: mainColor,
  })

export { updateMainColor, uploadLogo }
