import { RouteRecordRaw } from 'vue-router'

import Error from '../Error.vue'

export const errorRoutes: RouteRecordRaw[] = [
  {
    path: '/:pathMatch(.*)*',
    component: Error,
    meta: {
      isPlain: true,
      titleKey: 'Errors.title',
    },
  },
]
