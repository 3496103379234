import { split, toString } from 'lodash-es'
import { reactive } from 'vue'

export const usePriceParsing = (rawPrice: string | number) => {
  let price: number

  if (typeof rawPrice === 'string')
    price = parseFloat(rawPrice.replace(',', '.'))
  else price = rawPrice

  const formattedPrice =
    split(toString(price), '.')[1]?.length > 2
      ? (Math.trunc(price * 100) / 100).toFixed(2)
      : price.toFixed(2)

  const decimalPart = formattedPrice.slice(-2)
  const decimalWithSep = formattedPrice.slice(-3)
  const integerPart = formattedPrice.slice(0, -3)

  return {
    parsedPrice: reactive({
      full: formattedPrice,
      withoutCents: integerPart,
      cents: decimalPart,
      centsWithSeparator: decimalWithSep,
    }),
  }
}
