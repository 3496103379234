import { debounce } from 'lodash'
import { ref } from 'vue'

import { getCoupons } from 'src/api/coupon'
import { useFilters } from 'src/composables/useFilters'
import { usePartner } from 'src/store/partner'

import { Coupon } from '../types'

const isFetching = ref(false)
const totalCoupons = ref(0)
const coupons = ref<Coupon[]>([])
let apiCallsCounter = 0 // allows having loader when a call is cancelled

const fetchCoupons = debounce(async () => {
  try {
    clearCoupons()

    apiCallsCounter = apiCallsCounter + 1

    const { isLukoPartner } = usePartner()
    if (!isLukoPartner.value) return

    isFetching.value = true

    const { filters } = useFilters()

    const searchParams: Record<string, string> = {}
    if (filters.textSearch) {
      if (filters.textSearch.search(/ /) === -1)
        searchParams['code'] = filters.textSearch
      else searchParams['description'] = filters.textSearch
    }
    const couponsData = await getCoupons({
      page: filters.page,
      ...searchParams,
      ...(filters.acquisitionChannel && {
        acquisition_channel: filters.acquisitionChannel,
      }),
      ...(filters.validityFcts && { validity_fcts: filters.validityFcts }),
    })

    if (!couponsData) return

    coupons.value = couponsData.coupons
    totalCoupons.value = couponsData.count
  } finally {
    apiCallsCounter = apiCallsCounter - 1

    if (apiCallsCounter === 0) isFetching.value = false
  }
}, 400)

const clearCoupons = () => {
  coupons.value = []
  totalCoupons.value = 0
}

export const useCoupons = () => ({
  coupons,
  totalCoupons,
  fetchCoupons,
  isFetching,
  clearCoupons,
})
