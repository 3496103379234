import {
  filter,
  forEach,
  get,
  has,
  includes,
  isNil,
  isNumber,
  keys,
  lowerCase,
  reduce,
  set,
  toLower,
  unset,
} from 'lodash-es'
import { computed, reactive, ref } from 'vue'

import { getContract } from 'src/api/contract'
import { storeProspect } from 'src/api/prospect'
import { isFRHomeRepriseProduct } from 'src/lib/product'
import { useProduct } from 'src/store/product'
import {
  BundleCode,
  OutbuildingSize,
  PackageCode,
  PolicyType,
  Role,
} from 'src/types/enums'
import { ProductCode } from 'src/types/product'

const prospectData: Record<string, any> = reactive({})
const isProspectSaved = ref(false)
const isProspectMovingWithLuko = computed(
  () =>
    toLower(getProspectData('termination_reason')) ===
    toLower(PolicyType.MOVING_WITH_LUKO)
)

const setProspectData = (path: string, data: any) => {
  isProspectSaved.value = false
  if (path) {
    set(prospectData, path, data)

    if (path !== 'product') return

    const { setProduct } = useProduct()
    setProduct(data)

    return
  }
  keys(prospectData).forEach((key) => {
    if (has(data, key)) setProspectData(key, get(data, key))
    else deleteProspectData(key)
  })
  keys(data).forEach((key) => {
    if (has(prospectData, key)) return
    setProspectData(key, get(data, key))
  })
}

const getProspectData = (path: string = '') =>
  path === '' ? prospectData : get(prospectData, path)

const deleteProspectData = (path: string) => unset(prospectData, path)

const saveProspectData = async () => {
  const prospect = await storeProspect(prospectData)
  setProspectData('key', prospect.key)
}

const validateProspectData = () => {
  translateProductCode()
  removeInvalidUsers()
  removeInvalidValuables()
  addMissingOutbuildingBundles()
}

const translateProductCode = () => {
  const productCode = getProspectData('product')
  if (!productCode) return

  const {
    FR_HOME_FLAT,
    FR_HOME_HOUSE,
    FR_SECONDARY,
    FR_HOME,
    FR_LANDLORD,
    FR_LANDLORD_FLAT,
    FR_LANDLORD_HOUSE,
  } = ProductCode
  if (includes([FR_HOME_FLAT, FR_HOME_HOUSE, FR_SECONDARY], productCode))
    setProspectData('product', FR_HOME)
  if (includes([FR_LANDLORD_FLAT, FR_LANDLORD_HOUSE], productCode))
    setProspectData('product', FR_LANDLORD)
}

const removeInvalidValuables = () => {
  const valuableList = getProspectData('valuable.valuable')
  if (!valuableList || valuableList.length === 0) return

  const validatedList = [] as any[]
  let totalValuablesAmount = 0

  forEach(valuableList, (valuable) => {
    if (valuable?.type && isNumber(valuable?.value)) {
      validatedList.push(valuable)
      totalValuablesAmount += valuable.value
    }
  })

  if (totalValuablesAmount > 60000) {
    setProspectData('valuable.valuable', [])
    return
  }

  setProspectData('valuable.valuable', validatedList)
}

const removeInvalidUsers = () => {
  const userDetailsKeys = [
    'user.child',
    'user.roommate',
    'user.subscriber',
    'user.spouse',
  ]

  const validateUserWithEmail = (user: any) =>
    !!user.first_name && !!user.last_name && !!user.email

  const validateUserWithBirthdate = (user: any) =>
    !!user.first_name && !!user.last_name && !!user.birth_date

  forEach(userDetailsKeys, (key) => {
    if (key === 'user.child' && !!getProspectData(key)) {
      const childList = [...getProspectData(key)]

      const validatedList = reduce(
        childList,
        (acc: any[], cur) => {
          if (validateUserWithBirthdate(cur)) acc.push(cur)
          return acc
        },
        []
      )

      setProspectData(key, validatedList)
    }

    if (key === 'user.roommate' && !!getProspectData(key)) {
      const roommateList = [...getProspectData(key)]

      const validatedList = reduce(
        roommateList,
        (acc: any[], cur) => {
          if (validateUserWithEmail(cur)) acc.push(cur)
          return acc
        },
        []
      )

      setProspectData(key, validatedList)
    }

    if (
      key === 'user.spouse' &&
      !!getProspectData(key) &&
      !validateUserWithEmail(getProspectData(key))
    )
      deleteProspectData(key)

    const { product, other_info } = getProspectData()
    if (
      key === 'user.subscriber' &&
      !!getProspectData(key) &&
      !validateUserWithEmail(getProspectData(key)) &&
      !isFRHomeRepriseProduct(product, other_info?.external_operation)
    )
      deleteProspectData(key)
  })
}

const addMissingOutbuildingBundles = () => {
  const productName = getProspectData('product')
  const optionalBundles = getProspectData('optional_bundles') ?? []
  const packageCode = getProspectData('package')

  if (productName === ProductCode.FR_HOME && isNil(packageCode)) return

  const bundleMap = {
    [ProductCode.FR_HOME]: BundleCode.FR_HOME_OUTBUILDINGS,
    [ProductCode.FR_LANDLORD]: BundleCode.FR_LANDLORD_OUTBUILDINGS,
  }

  if (!includes(keys(bundleMap), productName)) return

  const outbuildingKeyMap = {
    [ProductCode.FR_HOME]: 'home.main_asset.outbuilding_size',
    [ProductCode.FR_LANDLORD]: 'home.main_asset.outbuilding_size',
  }

  const outbuildingValue = getProspectData(
    outbuildingKeyMap[productName as keyof typeof outbuildingKeyMap]
  )
  if (isNil(outbuildingValue) || outbuildingValue === OutbuildingSize.NONE) {
    setProspectData(
      'optional_bundles',
      filter(
        optionalBundles,
        (bundle) => bundle !== bundleMap[productName as keyof typeof bundleMap]
      )
    )
    return
  }

  if (
    includes(optionalBundles, bundleMap[productName as keyof typeof bundleMap])
  )
    return

  setProspectData('optional_bundles', [
    ...optionalBundles,
    bundleMap[productName as keyof typeof bundleMap],
  ])
}

const clearProductAssociatedValues = (product?: ProductCode) => {
  deleteProspectData('package')
  deleteProspectData('home.main_asset.content_value')
  deleteProspectData('home.main_asset.furnished_status')
  deleteProspectData('valuable')
  deleteProspectData('deductible')
  setProspectData('optional_bundles', [])
  deleteProspectData('outbuilding')
  deleteProspectData('home.main_asset.outbuilding_size')
  deleteProspectData('home.main_asset.outbuilding_flat_types')
  deleteProspectData('termination_reason')
  if (
    product === ProductCode.FR_LANDLORD_HOUSE ||
    product === ProductCode.FR_LANDLORD_FLAT ||
    product === ProductCode.FR_LANDLORD
  ) {
    deleteProspectData('user.child')
    deleteProspectData('user.spouse')
    deleteProspectData('user.roommate')
    deleteProspectData('user.other')
  }
}

const populateProspectDataFromContract = async (contractUuid: string) => {
  const contract: Record<string, any> = await getContract(contractUuid)

  setProspectData('moving_contract_id', contract.id)

  setProspectData('need_termination', false)
  setProspectData('termination_reason', PolicyType.MOVING_WITH_LUKO)

  setProspectData('user.subscriber', {
    first_name: contract.subscriber.first_name,
    last_name: contract.subscriber.last_name,
    email: contract.subscriber.email,
    phone: contract.subscriber.phone,
    is_sci: !!contract.subscriber.company,
    company: contract.subscriber.company,
    birth_date: contract.subscriber.birth_date,
    birth_country_code: contract.subscriber.birth_country_code,
  })

  forEach(contract.beneficiaries, (user) => {
    if (user.role === Role.CHILD) {
      const childList = getProspectData('user.child') ?? []
      childList.push({
        first_name: user.first_name,
        last_name: user.last_name,
        birth_date: user.birth_date,
      })
      setProspectData('user.child', childList)
    }
    if (user.role === Role.ROOMMATE) {
      const roommateList = getProspectData('user.roommate') ?? []
      roommateList.push({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
      })
      setProspectData('user.roommate', roommateList)
    }
    if (user.role === Role.SPOUSE)
      setProspectData('user.spouse', {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
      })
  })

  setProspectData('product', ProductCode.FR_HOME)
  setProspectData('home.main_asset.is_main', contract.home.is_main)

  const packageCode = includes(lowerCase(contract.package_code), 'pom')
    ? PackageCode.FR_HOME
    : PackageCode.FR_HOME_MINLEG
  setProspectData('package', packageCode)

  const optionalBundles = reduce(
    contract.options,
    (acc: string[], option) => {
      if (!option.subscribed) return acc

      if (includes(option.tracking_code, 'pipe-repair'))
        acc.push(BundleCode.FR_HOME_PIPE_REPAIR)
      if (includes(option.tracking_code, 'parents'))
        acc.push(BundleCode.FR_HOME_PARENTS)
      if (includes(option.tracking_code, 'legal'))
        acc.push(option.tracking_code)
      if (includes(option.tracking_code, 'replacement'))
        acc.push(BundleCode.FR_HOME_REPLACEMENT)
      if (includes(option.tracking_code, 'outdoor'))
        acc.push(BundleCode.FR_HOME_OUTDOOR_FACILITIES)
      if (
        includes(option.tracking_code, 'airbnb') &&
        packageCode === PackageCode.FR_HOME
      )
        acc.push(BundleCode.FR_HOME_AIRBNB)

      return acc
    },
    []
  )
  setProspectData('optional_bundles', optionalBundles)

  setProspectData('billing_frequency', contract.billing_frequency)
  setProspectData(
    'broker_surcommission_percentage',
    contract.broker_surcommission_percentage
  )
  setProspectData('deductible', contract.deductible)
}

export const useProspect = () => ({
  isProspectSaved,
  prospectData,
  isProspectMovingWithLuko,
  setProspectData,
  getProspectData,
  saveProspectData,
  deleteProspectData,
  validateProspectData,
  clearProductAssociatedValues,
  populateProspectDataFromContract,
})
