import {
  Bill,
  Briefcase,
  ChartPie,
  Code,
  Cog,
  Document,
  Gift,
  GroupOfPeople,
  LargeGroupOfPeople,
  Person,
  Pest,
  Question,
} from '@demain-es/lukompo'
import { computed } from 'vue'

import { useEmbeddedTypeform } from 'src/composables/useEmbeddedTypeform'
import { i18n } from 'src/i18n'
import { isAuthorized } from 'src/store/auth'
import { REPORT_BUGS_TYPEFORM_ID, REPORT_BUGS_URL } from 'src/store/constants'
import { usePartner } from 'src/store/partner'
import { useUser } from 'src/store/user'
import { useWorkspace } from 'src/store/workspace'

import { NavItem } from '../types'

const { t } = i18n.global
const { isNoveliaWs } = useWorkspace()
const { currentPartner, isGermanPartner, isLukoPartner, isRealEstatePartner } =
  usePartner()

const realEstateMainList = computed((): NavItem[] => [
  {
    routeName: 'sunset',
    text: t('Navbar.contracts'),
    icon: Document,
    isAuthorized: isAuthorized('contracts'),
    dataLukoTracking: 'Contracts',
  },
  {
    routeName: 'tenantFiles',
    text: t('Navbar.tenantFiles'),
    icon: LargeGroupOfPeople,
    isAuthorized: isAuthorized('tenantFiles'),
    isDisabled: true,
    dataLukoTracking: 'TenantFiles',
  },
  {
    routeName: 'resources',
    text: t('Navbar.resources'),
    icon: Question,
    isAuthorized: isAuthorized('resources'),
    isDisabled: true,
    dataLukoTracking: 'Resources',
  },
])

const mainList = computed((): NavItem[] => [
  {
    routeName: 'dashboard',
    text: t('Navbar.dashboard'),
    icon: ChartPie,
    isAuthorized: isAuthorized('dashboard') && !isNoveliaWs.value,
    dataLukoTracking: 'Dashboard',
  },
  {
    routeName: 'resources',
    text: t('Navbar.resources'),
    icon: Question,
    isAuthorized: isAuthorized('resources'),
    dataLukoTracking: 'Resources',
  },
  {
    routeName: 'prospects',
    text: t('Navbar.prospects'),
    icon: GroupOfPeople,
    isAuthorized: isAuthorized('prospects'),
    isDisabled: isNoveliaWs.value,
    isUnavailable: isNoveliaWs.value,
    dataLukoTracking: 'Prospects',
  },
  {
    routeName: 'contracts',
    text: t('Navbar.contracts'),
    icon: Document,
    isComing: false,
    isAuthorized: isAuthorized('contracts'),
    dataLukoTracking: 'Contracts',
  },
  {
    routeName: 'consumers',
    text: t('Navbar.consumers'),
    icon: Person,
    isComing: false,
    isAuthorized: isAuthorized('consumers'),
    dataLukoTracking: 'Consumers',
  },
  {
    routeName: 'partners',
    text: t('Navbar.partners'),
    icon: Briefcase,
    isComing: false,
    isAuthorized: isAuthorized('partners'),
    dataLukoTracking: 'Partners',
  },
  {
    routeName: 'coupons',
    text: t('Navbar.coupons'),
    icon: Gift,
    isComing: false,
    isAuthorized: isAuthorized('coupons'),
    dataLukoTracking: 'Coupons',
  },
])

const techList = computed((): NavItem[] => [
  ...(isGermanPartner.value
    ? []
    : [
        {
          routeName: 'developers',
          text: t('Navbar.developers'),
          icon: Code,
          isComing: false,
          isAuthorized: isAuthorized('developers'),
          isDisabled: isRealEstatePartner.value,
          dataLukoTracking: 'DeveloperIntegrations',
        },
      ]),
  /* {
          routeName: 'logs',
          text: t('Navbar.logs'),
          icon: 'Terminal',
        }, */
  /* {
          routeName: 'webhooks',
          text: t('Navbar.webhooks'),
          icon: 'Webhook',
          isComing: true,
          isAuthorized: isAuthorized('webhooks'),
          dataLukoTracking: 'Webhooks',
        }, */
])

const secondaryList = computed((): NavItem[] => [
  {
    routeName: 'settings',
    text: t('Navbar.settings'),
    icon: Cog,
    isAuthorized: isAuthorized('settings'),
    isDisabled: isRealEstatePartner.value,
    dataLukoTracking: 'Settings',
  },
  {
    routeName: 'billing',
    text: t('Navbar.billing'),
    icon: Bill,
    isAuthorized:
      isAuthorized('billing') &&
      !isNoveliaWs.value &&
      !!currentPartner.value?.has_billing_info,
    dataLukoTracking: 'Billing',
  },
])

const workspaceList = computed((): NavItem[] => [
  {
    routeName: 'dashboard',
    text: t('Navbar.dashboard'),
    icon: ChartPie,
    isComing: true,
    isAuthorized: isNoveliaWs.value,
    dataLukoTracking: 'WorkspaceOverview',
  },
  {
    routeName: 'workspaceSettings',
    text: t('Navbar.settings'),
    icon: Cog,
    isAuthorized: true,
    dataLukoTracking: 'WorkspaceSettings',
  },
])

const externalLinksList = computed((): NavItem[] => {
  if (isRealEstatePartner.value || isLukoPartner.value) return []

  if (isNoveliaWs.value)
    return [
      {
        url: REPORT_BUGS_URL,
        text: t('Navbar.bugs'),
        icon: Pest,
        isAuthorized: true,
        dataLukoTracking: 'BugsReport',
      },
    ]

  return [
    {
      text: t('Navbar.bugs'),
      icon: Pest,
      isAuthorized: true,
      onClick: () => {
        const { user } = useUser()
        const { showTypeform } = useEmbeddedTypeform()
        showTypeform({
          formId: REPORT_BUGS_TYPEFORM_ID,
          height: 600,
          width: 1000,
          hidden: { email: user.value!.email },
        })
      },
      dataLukoTracking: 'BugsReport',
    },
  ]
})

const shortList = computed((): NavItem[] => [
  {
    routeName: 'dashboard',
    text: t('Navbar.dashboard'),
    icon: ChartPie,
    isAuthorized: isAuthorized('dashboard') && !isNoveliaWs.value,
    dataLukoTracking: 'WorkspaceOverview',
  },
  {
    routeName: 'contracts',
    text: t('Navbar.contracts'),
    icon: Document,
    isComing: false,
    isAuthorized: isAuthorized('contracts'),
    dataLukoTracking: 'Contracts',
  },
  ...(isGermanPartner.value
    ? []
    : [
        {
          routeName: 'developers',
          text: t('Navbar.developers'),
          icon: Code,
          isComing: false,
          isAuthorized: isAuthorized('developers'),
          dataLukoTracking: 'DeveloperIntegrations',
        },
      ]),
  {
    routeName: 'settings',
    text: t('Navbar.settings'),
    icon: Cog,
    isAuthorized: isAuthorized('settings'),
    dataLukoTracking: 'Settings',
  },
  {
    routeName: 'billing',
    text: t('Navbar.billing'),
    icon: Bill,
    isAuthorized:
      isAuthorized('billing') &&
      !isNoveliaWs.value &&
      !!currentPartner.value?.has_billing_info,
    dataLukoTracking: 'Billing',
  },
])

export const useLists = () => ({
  mainList,
  secondaryList,
  shortList,
  workspaceList,
  externalLinksList,
  techList,
  realEstateMainList,
})
