<template>
  <Menu
    v-model:isOpened="isOpened"
    data-luko-tracking="CompanyMenu"
    direction="bottom-right"
    :is-disabled="isMenuDisabled"
    :class="[$style.menu, isOpened && $style.menuOpened]"
  >
    <template #selector>
      <div :class="$style.menuSelector">
        <template v-if="isWorkspaceContext">
          <Logo
            :class="$style.avatar"
            :logo-url="currentWorkspace?.logo_url || ''"
            :name="currentWorkspace?.name || ''"
          />
          <div :class="$style.menuLabel">
            {{ currentWorkspace?.name || '' }}
          </div>
        </template>
        <template v-if="!isWorkspaceContext && !!currentPartner">
          <Logo
            :class="$style.avatar"
            :name="currentPartner.name"
            :logo-url="currentPartner.logo_url"
          />
          <div :class="$style.menuLabel">
            {{ currentPartner.name }}
            <div
              v-if="isNoveliaWs && currentBrokerCode"
              :class="$style.partnerId"
            >
              #{{ currentBrokerCode }}
            </div>
          </div>
        </template>
      </div>
      <LukoIcon
        v-if="!isMenuDisabled"
        :svg="ChevronDown"
        color="gray400"
        :size="16"
        :class="[$style.chevronIcon, isOpened && $style.chevronIconReversed]"
      />
    </template>
    <template #dropdown>
      <div :class="$style.dropdown">
        <div v-if="userWorkspaces?.length" :class="$style.workspaces">
          <span :class="$style.title">
            {{ t('Navbar.CompanyMenu.workspaces') }}
          </span>
          <LukoSearchField
            v-if="!isNoveliaWs"
            v-model="workspaceSearchValue"
            data-luko-tracking="SearchWorkspace-Input"
            :placeholder="
              t('Navbar.CompanyMenu.searchbar_placeholder_workspace')
            "
            small
            @click.stop
            @keydown.stop
            @keypress.stop
            @keyup.stop
          />
          <div v-if="filteredWorkspaces?.length" :class="$style.list">
            <div
              v-for="workspace in filteredWorkspaces"
              :key="workspace.id"
              :class="[
                $style.listItem,
                isCurrentWorkspace(workspace.id) && $style.isActive,
              ]"
              @click="onWorkspaceClick(workspace.id)"
            >
              <Logo
                :name="workspace.name"
                :logo-url="workspace.logo_url || ''"
                size="24"
              />
              <span>{{ workspace.name }}</span>
              <LukoIcon
                v-if="canDeselectWorkspace(workspace.id)"
                :svg="Cross"
                :size="12"
                color="gray400"
                :class="$style.cancel"
                @click.stop="onDeselectWorkspace"
              />
            </div>
          </div>
          <span v-else :class="$style.noPartner" @click.stop>
            {{ t('Navbar.CompanyMenu.no_workspace_found') }}
          </span>
        </div>
        <Separator
          v-if="!isNoveliaSalesUser || userWorkspaces?.length"
          thin
          light
        />
        <div :class="$style.partners">
          <span :class="$style.title">
            {{ t('Navbar.CompanyMenu.partners') }}
          </span>
          <LukoSearchField
            v-model="partnerSearchValue"
            data-luko-tracking="SearchEntity-Input"
            :placeholder="t('Navbar.CompanyMenu.searchbar_placeholder_partner')"
            small
            @click.stop
            @keydown.stop
            @keypress.stop
            @keyup.stop
          />
          <div v-if="filteredPartners?.length" :class="$style.list">
            <div
              v-for="partner in filteredPartners"
              :key="partner.id"
              :class="[
                $style.listItem,
                isCurrentPartner(partner.id) && $style.isActive,
              ]"
              @click="onPartnerClick(partner.id)"
            >
              <Logo
                :name="partner.name"
                :logo-url="partner.logo_url"
                size="24"
              />
              <div :class="$style.partnerInfo">
                <span>{{ partner.name }}</span>
                <span
                  v-if="isNoveliaWs && partner.broker?.broker_code"
                  :class="$style.partnerIdDropdown"
                  >#{{ partner.broker.broker_code }}</span
                >
              </div>
            </div>
            <span
              v-if="isShowMore"
              :class="$style.showMore"
              @click.stop="onShowMorePartners"
            >
              {{ t('Navbar.CompanyMenu.show_more') }}
            </span>
            <ListPlaceholder v-else-if="isLoadingPartners" />
          </div>
          <ListPlaceholder v-else-if="isLoadingPartners" />
          <span v-else :class="$style.noPartner" @click.stop>
            {{ t('Navbar.CompanyMenu.no_partner_found') }}
          </span>
        </div>
      </div>
    </template>
  </Menu>
</template>

<script setup lang="ts">
import {
  ChevronDown,
  Cross,
  LukoIcon,
  LukoSearchField,
} from '@demain-es/lukompo'
import { debounce, filter, includes, sortBy, toLower } from 'lodash-es'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { UserPartnersSearchParams } from 'src/api/user/types'
import { Logo } from 'src/components/Logo'
import { Menu } from 'src/components/Menu'
import { Separator } from 'src/components/Separator'
import { useTheme } from 'src/composables/useTheme'
import { ListPlaceholder } from 'src/modules/Navbar/components'
import { useQuote } from 'src/modules/NewQuote/composables/useQuote'
import { getCurrentPartnerDefaultRoute } from 'src/store/auth'
import { usePartner } from 'src/store/partner'
import { useUser } from 'src/store/user'
import { useWorkspace } from 'src/store/workspace'
import { SimpleUserPartner } from 'src/types/partner'
import { Workspace } from 'src/types/workspace'

const isOpened = ref(false)
const { t } = useI18n()
const { push } = useRouter()
const { resetQuote } = useQuote()
const { user, isNoveliaSalesUser, isWorkspaceSuperAdmin } = useUser()
const {
  currentPartner,
  currentUserPartners,
  updateCurrentUserPartners,
  setCurrentPartner,
} = usePartner()
const {
  isWorkspaceContext,
  currentWorkspace,
  isNoveliaWs,
  resetWorkspace,
  set: setWs,
} = useWorkspace()

const partnersListOffset = ref(0)
const isLoadingPartners = ref(false)
const userWorkspaces = computed(() => user?.value?.workspaces)
const partnerSearchValue = ref('')
const isCurrentPartner = (partnerId: SimpleUserPartner['id']) =>
  partnerId === currentPartner.value?.id
const isShowMore = computed(() => {
  const { totalCurrentUserPartners } = usePartner()

  return filteredPartners.value.length < totalCurrentUserPartners.value
})

const onPartnerClick = async (partnerId: SimpleUserPartner['id']) => {
  if (isCurrentPartner(partnerId) && !isWorkspaceContext.value) return

  await setCurrentPartner(partnerId)

  push({ name: getCurrentPartnerDefaultRoute() })
}

const filteredPartners = computed(() => {
  return sortBy(
    filter(currentUserPartners.value ?? [], ({ workspace_id }) => {
      if (!currentWorkspace.value?.id) return true

      return workspace_id === currentWorkspace.value?.id
    }),
    ({ id }) => !isCurrentPartner(id)
  )
})

const isCurrentWorkspace = (workspaceId: Workspace['id']) => {
  return workspaceId === currentWorkspace.value?.id
}

const canDeselectWorkspace = (workspaceId: Workspace['id']) => {
  if (!isCurrentWorkspace(workspaceId)) return false
  return isWorkspaceSuperAdmin.value || !isNoveliaWs.value
}

const onWorkspaceClick = (workspaceId: Workspace['id']) => {
  resetQuote()

  if (!isCurrentWorkspace(workspaceId)) {
    setWs.currentWorkspace(workspaceId)

    refreshPartnersList()
  }

  push({ name: 'workspaceSettings', force: true })
}

const workspaceSearchValue = ref('')
const filteredWorkspaces = computed(() =>
  filter(userWorkspaces.value ?? [], ({ name }) =>
    includes(toLower(name), toLower(workspaceSearchValue.value))
  )
)

const onDeselectWorkspace = async () => {
  resetWorkspace()
  if (isWorkspaceContext.value) {
    await push({ name: getCurrentPartnerDefaultRoute(), force: true })
    setWs.isWorkspaceContext(false)
  }
  refreshPartnersList()
}

const onShowMorePartners = async () => {
  isLoadingPartners.value = true

  partnersListOffset.value += 1

  await updateCurrentUserPartners({
    offset: partnersListOffset.value,
    ...(partnerSearchValue.value && {
      name: partnerSearchValue.value,
    }),
  })

  isLoadingPartners.value = false
}

const refreshPartnersList = async (params?: UserPartnersSearchParams) => {
  isLoadingPartners.value = true

  currentUserPartners.value = []
  partnersListOffset.value = 0

  await updateCurrentUserPartners(params ? { ...params } : {})

  isLoadingPartners.value = false
}

watch(
  partnerSearchValue,
  debounce((val: string) => {
    refreshPartnersList({
      ...(val && { name: val }),
    })
  }, 800),
  { immediate: true }
)

watch(isOpened, (val) => {
  if (!val && partnerSearchValue.value) partnerSearchValue.value = ''
})

const currentBrokerCode = computed(
  () => currentPartner.value?.broker?.broker_code
)
const isMenuDisabled = computed(() => {
  if (isNoveliaSalesUser.value) return true
  return (
    currentUserPartners.value.length === 1 && userWorkspaces.value?.length === 0
  )
})

const { currentTheme } = useTheme()
</script>

<style lang="scss" module>
.menu {
  @include typo-body-bold;
  z-index: 3;

  padding: 0px 8px;

  &:hover {
    background-color: transparent;

    & .menuLabel {
      color: $gray-700;
    }
  }
}
.menuSelector {
  display: inline-flex;
  gap: 12px;
  align-items: center;
  margin-top: auto;
}
.menuSelectorLarge {
  align-items: center;
}

.menuLabel {
  @include typo-headline-bold;
  display: flex-inline;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 136px;

  overflow: hidden;

  white-space: nowrap;

  text-align: left;
  text-overflow: ellipsis;
}

.menuOpened .avatar {
  border: 4px solid $gray-200;
}
.dropdown {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 85vh;
  padding: 8px 4px;
  overflow-y: auto;
}

.workspaces,
.partners {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  @include typo-caption-bold;

  padding: 0 8px;

  color: $gray-400;

  text-align: left;
}

.avatar {
  border: 4px solid transparent;
  border-radius: 50%;

  color: $gray-400;

  background-color: $gray-100;

  background-size: cover;
  transform: translateY(-2px);

  transition: border-color 0.3s ease-in;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 300px;
  overflow: auto;
}

.listItem {
  @include typo-sub-text;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 6px 12px;
  border-radius: 8px;

  color: $gray-700;

  cursor: pointer;

  transition: background-color $short-transition;

  &.isActive {
    @include typo-sub-text-bold;
    color: v-bind('currentTheme.backgroundColor');
  }

  &:hover {
    color: $gray-1000;

    background-color: $gray-50;

    &.isActive {
      color: v-bind('currentTheme.backgroundColor');
    }
  }
}
.partnerInfo {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.partnerId {
  @include typo-sub-text;
  color: $gray-700;
}
.partnerIdDropdown {
  @include typo-sub-text;
  color: $gray-500;
}
.noPartner {
  @include typo-sub-text;
  padding: 16px 48px;

  color: $gray-700;
}

.cancel {
  align-self: center;
  margin-left: auto;
  &:hover {
    transform: scale(1.1);
  }
}
.showMore {
  @include typo-sub-text;
  display: flex;
  justify-content: center;
  width: 100%;

  padding: 6px 12px;
  border-radius: 8px;

  color: $gray-700;

  &:hover {
    color: $gray-1000;

    background-color: $gray-50;
  }
  transition: color $short-transition;
}

.chevronIcon {
  margin-left: 7px;

  transition: transform 0.3s ease;
}

.chevronIconReversed {
  transform: rotate(180deg);
}
</style>
