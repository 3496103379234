<template>
  <div :class="[$style.separator, light && $style.separatorLight]" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  vertical: { type: Boolean, default: false },
  dotted: { type: Boolean, default: false },
  thin: { type: Boolean, default: false },
  light: { type: Boolean, default: false },
})

const borderWidth = computed(() => (props.thin ? '1px' : '3px'))
</script>

<style lang="scss" module>
.separator {
  width: v-bind('vertical ? `0px` : `100%`');
  height: v-bind('vertical ? `100%` : `0px`');
  border-color: $gray-200;
  border-style: v-bind('dotted ? `dotted` : `solid`');
  border-width: 0px;
  border-top-width: v-bind('vertical ? `0px` : borderWidth');
  border-left-width: v-bind('vertical ? borderWidth : `0px`');
}

.separatorLight {
  border-color: $gray-100;
}
</style>
