import { entries, forEach, isArray, join, reduce } from 'lodash-es'

const getBrowserName = () => {
  const userAgent = window.navigator.userAgent
  if (/chrome|chromium|crios/i.exec(userAgent)) return 'chrome'
  if (/firefox|fxios/i.exec(userAgent)) return 'firefox'
  if (/safari/i.exec(userAgent)) return 'safari'
  if (/opr\//i.exec(userAgent)) return 'opera'
  if (/edg/i.exec(userAgent)) return 'edge'
  return ''
}

interface URLParams {
  baseUrl: string
  path?: string | string[]
  query?: Record<string, string>
}

const getUrlString = (params: URLParams) => {
  const pathName = isArray(params.path)
    ? join(params.path, '/')
    : params.path ?? ''

  const url = new URL(pathName, params.baseUrl)

  if (!params.query) return url.toString()

  forEach(entries(params.query), ([k, v]) => url.searchParams.set(k, v))

  return url.toString()
}

const getSerializedParams = (params: Record<string, any>) =>
  reduce(
    entries(params),
    (qs, [k, v]) => {
      if (isArray(v)) {
        forEach(v, (p) => {
          if (!qs) qs += `${k}=${encodeURIComponent(p)}`
          else qs += `&${k}=${encodeURIComponent(p)}`
        })
        return qs
      }

      if (!qs) qs += `${k}=${encodeURIComponent(v)}`
      else qs += `&${k}=${encodeURIComponent(v)}`

      return qs
    },
    ''
  )

const sleep = async (ms: number) => await new Promise((r) => setTimeout(r, ms))

const pipe = (...ops: Function[]) => {
  return (arg: any) => reduce(ops, (acc, op) => op(acc), arg)
}

export { getBrowserName, getUrlString, sleep, getSerializedParams, pipe }
