import { PartnerType } from 'src/types/partner'

import { ProductCode } from './product'
import { Workspace } from './workspace'

interface User {
  avatar_url: string | null
  email: string
  firstname: string
  id: number
  intercom_tokens: {
    android: string
    ios: string
    web: string
  }
  is_workspace_super_admin?: boolean
  has_soft_deleted_access?: boolean
  lang: Language
  lastname: string
  uuid: string
  workspaces: Workspace[]
}

interface AuthRole {
  userRole: Acl[]
  userRoleNovelia: Acl[]
  userRoleCheck24: Acl[]
  userRoleRealEstate: Acl[]
  partnerRole: PartnerType[]
}
interface PartnerUser {
  acl: Acl
  avatar_url: string | null
  email: string
  firstname: string
  id: number
  lang: Language
  lastname: string
  products: ProductCode[]
  uuid: string
}

enum Language {
  FR = 'fr',
  EN = 'en',
  ES = 'es',
  DE = 'de',
}

enum Acl {
  ADMIN = 'admin',
  DEVELOPER = 'developer',
  SALES = 'sales',
  CS_ADMIN = 'cs_admin',
  CS_USER = 'cs_user',
  CS_LEAD = 'cs_lead',
  SALES_LEAD = 'sales_lead',
  ACCOUNTANT = 'accountant',
  PROPERTY_MANAGER = 'property_manager',
  EXTERNAL_PROPERTY_MANAGER = 'external_property_manager',
}

export { Acl, AuthRole, User, PartnerUser, Language }
