import { NavigationGuard, RouteLocationNormalized } from 'vue-router'

import { getUserPartners } from 'src/api/user/partners'
import { useLocalStorage } from 'src/lib/localStorage'
import { AUTH_ROLES, getCurrentPartnerDefaultRoute } from 'src/store/auth'
import { usePartner } from 'src/store/partner'
import { useWorkspace } from 'src/store/workspace'
import { PartnerIndustry } from 'src/types/partner'

const rbacGuard: NavigationGuard = async (to: RouteLocationNormalized) => {
  try {
    if (!AUTH_ROLES[to.name as keyof typeof AUTH_ROLES]) return true

    const {
      partnerRole,
      userRole,
      userRoleNovelia,
      userRoleCheck24,
      userRoleRealEstate,
    } = AUTH_ROLES[to.name as keyof typeof AUTH_ROLES]

    const { isNoveliaWs } = useWorkspace()
    const { currentPartnerId, currentUserPartners, isCheck24Partner } =
      usePartner()

    let simpleUserPartner = currentUserPartners.value?.find(
      (partner) => partner.id === currentPartnerId.value
    )

    if (!simpleUserPartner) {
      const { partners } = await getUserPartners({
        id: currentPartnerId.value,
      })

      simpleUserPartner = partners?.[0]
    }

    if (simpleUserPartner.industry === PartnerIndustry.REAL_ESTATE)
      return (
        userRoleRealEstate.includes(simpleUserPartner?.acl) || {
          name: getCurrentPartnerDefaultRoute(),
        }
      )

    const hasPartnerAccess = partnerRole.includes(simpleUserPartner?.type)

    let hasUserAccess = userRole.includes(simpleUserPartner?.acl)
    if (isNoveliaWs.value)
      hasUserAccess = userRoleNovelia.includes(simpleUserPartner?.acl)
    if (isCheck24Partner.value)
      hasUserAccess = userRoleCheck24.includes(simpleUserPartner?.acl)

    if (hasPartnerAccess && hasUserAccess) return true

    return { name: getCurrentPartnerDefaultRoute() }
  } catch {
    const { removeStorageItem } = useLocalStorage()
    removeStorageItem('isLogged')

    return { name: 'login' }
  }
}

export { rbacGuard }
