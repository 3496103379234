import { useConfig } from 'src/store/config'

import { Api } from '..'
import {
  GetIntentPayload,
  GoCardlessBankAccountTokenResponse,
  GoCardlessPayload,
  IntentResponse,
} from './types'

const fetchCustomerBankAccountToken = async (payload: GoCardlessPayload) => {
  const { goCardlessKey, goCardlessUrl } = useConfig()

  const { data } = await Api.post<GoCardlessBankAccountTokenResponse>(
    `/customer_bank_account_tokens`,
    { customer_bank_account_tokens: payload },
    {
      baseURL: goCardlessUrl.value as string,
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${goCardlessKey.value}`,
        'GoCardless-Version': '2015-07-06',
        'Content-Type': 'application/json',
      },
    }
  )

  return data
}

const getPaymentIntent = async (
  prospectStateKey: string,
  payload: GetIntentPayload
) => {
  const { data } = await Api.post<IntentResponse>(
    `/v1/prospect/${prospectStateKey}/payment/intent`,
    payload,
    { params: { lang: true }, headers: { bearer: true } }
  )
  return data
}

export { fetchCustomerBankAccountToken, getPaymentIntent }
