import { ApiRequestName } from 'src/types/api'

const abortSignalHandler: Partial<Record<ApiRequestName, AbortController>> = {}

const getAbortSignal = (request: ApiRequestName) => {
  abortSignalHandler[request] = new AbortController()
  return abortSignalHandler[request]!.signal
}

const abortRequest = (request: ApiRequestName) =>
  abortSignalHandler[request]?.abort()

export const useApiController = () => ({
  getAbortSignal,
  abortRequest,
})
