import { addDays, format } from 'date-fns'
import { isArray, isNil, isObject, mergeWith } from 'lodash-es'
import { computed } from 'vue'

import { getContentValues } from 'src/api/prospect'
import { useLocal as useGoodsLocal } from 'src/modules/NewQuote/components/steps/Goods/useLocal'
import { useProspect } from 'src/modules/NewQuote/composables/useProspect'
import { DATE_API_FORMAT, DEFAULT_DEDUCTIBLE } from 'src/store/constants'
import { usePartner } from 'src/store/partner'
import { useProduct } from 'src/store/product'
import { EScooterIndividualAccidentLimit, PackageCode } from 'src/types/enums'
import { PaymentFrequency } from 'src/types/payment'
import { ProductCode } from 'src/types/product'
import { Prospect } from 'src/types/prospect'
import { DefaultProspectValues } from 'src/types/quote'

/**
 * Generates default values
 * @param prospectData an object containing prospect data
 * @returns `DefaultProspectValues` an object containing default values for the prospect
 */
const getDefaultValues = async (
  prospectData: Prospect
): Promise<DefaultProspectValues> => {
  const { isCurrentProductMortgage, isCurrentProductEScooter } = useProduct()

  const defaultValues: DefaultProspectValues = {
    package: getPackage(prospectData.product),
    optional_bundles: getDefaultOptionalBundles(prospectData.product),
    billing_frequency: PaymentFrequency.MONTHLY,
  }

  if (!isNil(defaultCommission.value))
    defaultValues['broker_surcommission_percentage'] = defaultCommission.value

  if (!isCurrentProductMortgage.value)
    defaultValues['start_date'] = format(
      addDays(new Date(), 2),
      DATE_API_FORMAT
    )

  if (!isCurrentProductMortgage.value && !isCurrentProductEScooter.value) {
    defaultValues['deductible'] = getDefaultDeductible(prospectData.product)
    defaultValues['home'] = {
      main_asset: {
        content_value: await getDefaultContentValue(),
      },
    }
  }

  if (defaultCouponCode.value) defaultValues['coupon'] = defaultCouponCode.value

  if (isCurrentProductEScooter.value) {
    defaultValues['deductible'] = getDefaultDeductible(prospectData.product)
    defaultValues['parameters'] = {
      individual_accident_limit: EScooterIndividualAccidentLimit.LEVEL_1,
    }
  }

  return defaultValues
}

const getDefaultDeductible = (product: ProductCode) => {
  if (product === ProductCode.FR_HOME) return
  if (product === ProductCode.FR_ESCOOTER) return 0

  return DEFAULT_DEDUCTIBLE
}

const getDefaultOptionalBundles = (product: ProductCode) => {
  if (product === ProductCode.FR_HOME) return
  return []
}

const getDefaultContentValue = async () => {
  const { getProspectData } = useProspect()
  const { possibleContentValues } = useGoodsLocal()

  const contentValue = getProspectData('home.main_asset.content_value')

  const { default_value: defaultValue, possible_values: possibleValues } =
    await getContentValues(getProspectData())

  possibleContentValues.value = possibleValues

  if (!contentValue || !possibleValues.includes(contentValue))
    return defaultValue

  return contentValue
}

const defaultCommission = computed(() => {
  const { isCommissionEnabled, currentPartner } = usePartner()

  if (!isCommissionEnabled.value) return

  const { default_commission, min_commission } = currentPartner.value!.config!

  return default_commission ?? min_commission
})

const defaultCouponCode = computed(() => {
  const { currentPartner } = usePartner()

  return currentPartner.value?.default_coupon
})

const getPackage = (product: ProductCode) => {
  if (product === ProductCode.FR_HOME) return
  return PackageCode[product as keyof typeof PackageCode]
}

const nilMerge = <Type>(a: Type, b: Type) => (isNil(b) ? a : b)

const nilMergeDeep = <Type>(a: Type, b: Type): Type | typeof mergeWith =>
  isObject(a) && !isArray(a)
    ? mergeWith({}, a, b, nilMergeDeep)
    : nilMerge(a, b)

const mergeDefaults = async () => {
  const { getProspectData, setProspectData } = useProspect()
  const defaultValues = await getDefaultValues(getProspectData())
  // merge the missing keys in prospectData from defaultValues
  setProspectData(
    '',
    mergeWith({}, defaultValues, getProspectData(), nilMergeDeep)
  )
}

export const useDefaults = () => ({ mergeDefaults })
