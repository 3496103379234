import {
  PaymentIntent,
  Stripe,
  StripeCardNumberElement,
} from '@stripe/stripe-js'
import { find, split } from 'lodash-es'
import { computed, ref } from 'vue'

import { createContract } from 'src/api/contract'
import {
  fetchCustomerBankAccountToken,
  getPaymentIntent,
} from 'src/api/payment'
import { GoCardlessBankAccountTokenResponse } from 'src/api/payment/types'
import { getExistingPaymentMethods } from 'src/api/prospect'
import { useError } from 'src/composables/useError'
import { useNotification } from 'src/composables/useNotification'
import { i18n } from 'src/i18n'
import { getCodeForCountry } from 'src/lib/address'
import { useProspect } from 'src/modules/NewQuote/composables/useProspect'
import { useQuote } from 'src/modules/NewQuote/composables/useQuote'
import {
  Mandate,
  PaymentMethod,
  PaymentMethodType,
  PaymentServiceProvider,
} from 'src/types/payment'

const { getProspectData } = useProspect()

const currentProspectKey = ref<string | null>(null)
const card = ref<StripeCardNumberElement | null>(null)
const iban = ref<string>('')
const paymentIntent = ref<PaymentIntent | null>(null)
const mandate = ref<Mandate | null>(null)
const stripe = ref<Stripe | null>(null)
const currentPaymentMethod = ref<PaymentMethodType | string>() // can be 'card/sepa_debit/card-<id>/sepa_debit<id>'
const existingPaymentMethods = ref<PaymentMethod[]>([])
const isPaymentConfirmed = ref(false)
const isContractSubscribed = ref(false)
const isFetchingExistingPaymentMethods = ref(false)
const isPaymentByCard = computed(
  () => split(currentPaymentMethod.value, '-')?.[0] === PaymentMethodType.CARD
)
const selectedExistingPaymentMethod = computed(() =>
  find(
    existingPaymentMethods.value,
    ({ id }) => split(currentPaymentMethod.value, '-')?.[1] === String(id)
  )
)
const isExistingPaymentMethodSelected = computed(
  () => !!selectedExistingPaymentMethod.value
)
const billingDay = ref(8)

const resetPaymentInfo = () => {
  card.value = null
  iban.value = ''
  paymentIntent.value = null
  mandate.value = null
  stripe.value = null
  currentPaymentMethod.value = undefined
  existingPaymentMethods.value = []
  isPaymentConfirmed.value = false
  isContractSubscribed.value = false
  billingDay.value = 8
}

const fetchExistingPaymentMethods = async () => {
  const prospectEmail = getProspectData('user.subscriber.email')

  try {
    isFetchingExistingPaymentMethods.value = true
    const { sources } = await getExistingPaymentMethods(prospectEmail)
    if (!sources.length) return
    existingPaymentMethods.value = sources
  } finally {
    isFetchingExistingPaymentMethods.value = false
  }
}

const createPaymentIntentForProspect = async () => {
  try {
    const { doQuoteNow } = useQuote()
    await doQuoteNow()
    const prospectKey = getProspectData('key')
    const { prospect, intent } = await getPaymentIntent(prospectKey, {
      ...(!isPaymentByCard.value && { intent: false }),
      ...(isPaymentByCard.value &&
        isExistingPaymentMethodSelected.value && {
          existing_card_payment_method_id:
            selectedExistingPaymentMethod.value!.id,
        }),
    })

    if (!prospect.key) return

    currentProspectKey.value = prospect.key

    if (!intent) return

    paymentIntent.value = intent
  } catch (error) {
    const { parseError } = useError()
    const { message } = parseError(error)

    window.LukoTracking.trackEvent({
      id: 'Payment Failed',
      properties: {
        error: {
          source: 'Luko',
          message: message ?? 'Error creating payment intent',
        },
      },
    })

    throw error
  }
}

const makeCardPayment = async () => {
  await createPaymentIntentForProspect()

  if (!paymentIntent.value?.client_secret) return

  if (!isExistingPaymentMethodSelected.value) {
    if (!stripe.value) return

    const { error, paymentMethod } = await stripe.value.createPaymentMethod({
      type: 'card',
      card: card.value!,
    })

    if (error) {
      const { t } = i18n.global
      const { showNotification } = useNotification()

      showNotification({
        message: error.message ?? t('Errors.generic'),
        theme: 'danger',
      })

      const errorMessage = error.message ?? 'Error creating payment method'

      window.LukoTracking.trackEvent({
        id: 'Payment Failed',
        properties: {
          error: {
            source: 'Stripe',
            message: errorMessage,
          },
        },
      })

      throw Error(errorMessage)
    }

    if (!paymentMethod) return

    const confirmPaymentResponse = await stripe.value.confirmCardPayment(
      paymentIntent.value.client_secret,
      { payment_method: paymentMethod.id }
    )

    if (confirmPaymentResponse.error) {
      const { t } = i18n.global
      const { showNotification } = useNotification()

      showNotification({
        message: confirmPaymentResponse.error.message ?? t('Errors.generic'),
        theme: 'danger',
      })

      const errorMessage =
        confirmPaymentResponse.error.message ?? 'Error confirming card payment'

      window.LukoTracking.trackEvent({
        id: 'Payment Failed',
        properties: {
          error: {
            source: 'Stripe',
            message: errorMessage,
          },
        },
      })

      throw Error(errorMessage)
    }
  }

  isPaymentConfirmed.value = true
}

const makeCardlessPayment = async () => {
  await createPaymentIntentForProspect()

  if (!isExistingPaymentMethodSelected.value) {
    const gcPayload = {
      iban: iban.value,
      account_holder_name: `${getProspectData(
        'user.subscriber.first_name'
      )} ${getProspectData('user.subscriber.last_name')}`,
      currency: 'EUR',
    }

    let cgResponse: GoCardlessBankAccountTokenResponse
    try {
      cgResponse = await fetchCustomerBankAccountToken(gcPayload)
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.error?.message ??
        'Error in fetching customer bank account token'

      window.LukoTracking.trackEvent({
        id: 'Payment Failed',
        properties: {
          error: {
            source: 'GoCardless',
            message: errorMessage,
          },
        },
      })

      throw error
    }

    mandate.value = {
      payment_service_provider: PaymentServiceProvider.GOCARDLESS,
      customer_bank_account_token: cgResponse?.customer_bank_account_tokens?.id,
      email: getProspectData('user.subscriber.email'),
      first_name: getProspectData('user.subscriber.first_name'),
      last_name: getProspectData('user.subscriber.last_name'),
      address: getProspectData('home.main_asset.address_main'),
      city: getProspectData('home.main_asset.city'),
      postal_code: getProspectData('home.main_asset.postcode'),
      country_code:
        getProspectData('home.main_asset.country_code') ||
        getCodeForCountry(getProspectData('home.main_asset.country_code')),
      psp_id: null,
    }
  }

  isPaymentConfirmed.value = true
}

const makePayment = async () => {
  isContractSubscribed.value = false

  isPaymentByCard.value ? await makeCardPayment() : await makeCardlessPayment()

  if (!isPaymentConfirmed.value) return

  const createdContract = await createContract(currentProspectKey.value!, {
    intent_id: isPaymentByCard.value ? paymentIntent.value!.id! : null,
    ...(!isPaymentByCard.value &&
      !isExistingPaymentMethodSelected.value && {
        mandate: mandate.value!,
      }),
    ...(!isPaymentByCard.value &&
      isExistingPaymentMethodSelected.value && {
        existing_mandate_payment_method_id: String(
          selectedExistingPaymentMethod.value!.id
        ),
      }),
    signature_mode: 'luko_email',
    billing_day: billingDay.value,
  })

  const { setProspectData } = useProspect()
  setProspectData('key', currentProspectKey.value)
  setProspectData('paid_date', createdContract.paid_date)
  setProspectData('contract', createdContract.contract)
  setProspectData('sign_url', createdContract.signature_request.data.sign_url)

  window.LukoTracking.trackEvent({ id: 'Contract Paid' })

  isContractSubscribed.value = true
}

export const usePayment = () => ({
  card,
  iban,
  stripe,
  mandate,
  paymentIntent,
  isPaymentByCard,
  isContractSubscribed,
  currentProspectKey,
  currentPaymentMethod,
  existingPaymentMethods,
  isExistingPaymentMethodSelected,
  selectedExistingPaymentMethod,
  billingDay,
  isFetchingExistingPaymentMethods,
  makePayment,
  makeCardPayment,
  makeCardlessPayment,
  fetchExistingPaymentMethods,
  resetPaymentInfo,
})
