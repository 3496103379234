import { PartnerType } from './partner'

interface Workspace {
  acl: string
  default_partner_type: PartnerType
  id: number
  logo_url: string | null
  main_color: string
  name: string
}

enum WorkspaceId {
  LUKO = 1,
  NOVELIA = 7,
}

export { Workspace, WorkspaceId }
