import { App } from 'vue'

const forbiddenTags =
  /<\/?(script|iframe|object|embed|applet|frame|frameset|base|form)(\s|\/|>)?/gi

const forbiddenAttributes = /<(?=[^>]+\son[^>]*?>)/gi

export const sanitizeHtml = (val: string) => {
  if (!val) {
    return ''
  }
  return val
    .replace(forbiddenTags, '&lt;')
    .replace(forbiddenAttributes, '&lt;')
    .replace(/\n/g, '<br />')
}

export const install = (app: App): void => {
  app.directive('sanitize', (el, binding) => {
    if (binding.value !== binding.oldValue) {
      el.innerHTML = sanitizeHtml(binding.value)
    }
  })
}
