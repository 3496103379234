import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import { includes } from 'lodash-es'
import { App } from 'vue'

import { ApiErrorStatusCode } from 'src/types/error'

export const install = (app: App) => {
  if (!import.meta.env.VITE_APP_SENTRY_DSN) return

  Sentry.init({
    app: app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN as string,
    environment:
      import.meta.env.VITE_IS_DEV === 'true' ? 'development' : 'production',
    debug: import.meta.env.VITE_IS_DEV === 'true' ? true : false,
    tracesSampleRate: 0.25,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['api.partner.luko.eu', 'api-dev.partner.luko.eu'],
      }),
    ],
    beforeSend(event) {
      let analyticsErrorMessage = 'Unknown error'

      if (event.message) {
        analyticsErrorMessage = event.message
      } else if (event.extra?.message) {
        analyticsErrorMessage = event.extra?.message as string
      } else if (event?.exception?.values) {
        analyticsErrorMessage = `${event.exception.values[0].type}: ${event.exception.values[0].value}`
      }

      const { UNAUTHORIZED, FORBIDDEN, INTERNAL_SERVER } = ApiErrorStatusCode
      const logToSentry =
        !includes(
          [UNAUTHORIZED, FORBIDDEN, INTERNAL_SERVER],
          event.extra?.status
        ) &&
        !includes(
          analyticsErrorMessage,
          'Failed to fetch dynamically imported module:'
        )

      window.LukoTracking.trackEvent({
        id: event.extra?.api ? 'API Error' : 'Client Error',
        properties: {
          error: {
            message: analyticsErrorMessage,
            status: event.extra?.status,
            type: event.extra?.type,
            ...(logToSentry && {
              sentry: `https://sentry.io/organizations/demain-es-luko/?query=${event.event_id}`,
            }),
          },
        },
      })

      if (logToSentry) return event

      return null
    },
  })
}
