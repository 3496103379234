import { useConfig } from 'src/store/config'
import { usePartner } from 'src/store/partner'
import { useUser } from 'src/store/user'
import { useWorkspace } from 'src/store/workspace'

const launchIntercom = () => {
  const { isLogged, user } = useUser()
  const { currentWsMainColor } = useWorkspace()
  const { currentPartnerId, currentPartner } = usePartner()
  const { intercomKey } = useConfig()

  const intercomId = user.value?.intercom_tokens.web

  if (!isLogged.value || !intercomId) return

  window.Intercom('boot', {
    user_hash: intercomId,
    app_id: intercomKey,
    name: `${user.value?.firstname} ${user.value?.lastname}`,
    email: user.value?.email!,
    created_at: new Date().toDateString(),
    action_color: currentWsMainColor.value,
    background_color: currentWsMainColor.value,
    company: {
      company_id: currentPartnerId.value,
      name: currentPartner.value?.name as string,
      industry: currentPartner.value?.industry as string,
    },
    partner_role: currentPartner.value?.type as string,
  })
}

window.Intercom('onShow', () =>
  window.LukoTracking.trackEvent({ id: 'Open Intercom' })
)

const openIntercom = () => window.Intercom('show')
const shutdownIntercom = () => window.Intercom('shutdown')

export { launchIntercom, shutdownIntercom, openIntercom }
