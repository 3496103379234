import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'

export const consumersRoutes: RouteRecordRaw[] = [
  {
    path: '/consumers',
    name: 'consumers',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const consumersModule = await import('../Consumers.vue')
        return consumersModule
      } finally {
        await finishProgress()
      }
    },
    meta: {
      titleKey: 'Consumers.metaTitle',
    },
  },
]
