import { ref } from 'vue'

// import { useLocalStorage } from 'src/lib/localStorage'

// const { getStorageItem } = useLocalStorage()

// const isNpsBannerDisplayed = ref(
//   !JSON.parse(getStorageItem('npsBannerClosed') ?? 'false')
// )

const isNpsBannerDisplayed = ref(false)

export const useNpsBanner = () => ({
  isNpsBannerDisplayed,
})
