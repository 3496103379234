<template>
  <div
    :data-luko-tracking="dataLukoTracking"
    :class="[
      { [$style.secondaryLight]: isSecondaryLight },
      { [$style.secondary]: isSecondary },
      { [$style.selected]: isSelected },
      { [$style.disabled]: isDisabled },
      $style.root,
    ]"
  >
    <slot name="left" />

    <div :class="$style.content">
      <slot name="content" />
    </div>

    <div v-if="$slots.right">
      <slot name="right" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTheme } from 'src/composables/useTheme'

const { currentTheme } = useTheme()

defineProps({
  dataLukoTracking: {
    type: String,
    default: 'FramedOption',
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
  isSecondaryLight: {
    type: Boolean,
    default: false,
  },
  isSecondary: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
})
</script>

<style lang="scss" module>
.root {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 16px 24px;

  border: 2px solid $gray-100;
  border-radius: 8px;

  background: $white;
  cursor: pointer;
}
.secondary {
  border: $gray-100;

  background: $gray-75;
}
.secondaryLight {
  border-color: $gray-50;

  background: $gray-50;
}

.selected {
  border-color: v-bind('currentTheme.backgroundColor');
}
.disabled {
  pointer-events: none;
}
.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;

  text-align: left;
}
</style>
