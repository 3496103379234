import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'
import { sleep } from 'src/lib/webUtils'
import { usePartner } from 'src/store/partner'

export const contractsRoutes: RouteRecordRaw[] = [
  {
    path: '/contracts',
    name: 'contracts',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const contractModule = await import('../Contracts.vue')
        return contractModule
      } finally {
        await finishProgress()
      }
    },
    meta: {
      titleKey: 'Contracts.metaTitle',
    },
    beforeEnter: async () => {
      const { currentPartner, isRealEstatePartner } = usePartner()

      while (!currentPartner.value) {
        await sleep(100)
      }

      if (!isRealEstatePartner.value) return

      return { name: 'sunset' }
    },
  },
]
