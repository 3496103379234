import { Gender, Role } from 'src/types/enums'

enum OwnerType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LANDLORD = 'landlord',
  LANDLORD_FOR_TENANT = 'landlord_for_tenant',
}

enum ValuableType {
  ART = 'art',
  JEWELS = 'jewels',
  MUSIC = 'music',
  DEVICE = 'device',
  OTHER = 'other',
}

enum ClaimNumber {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE_PLUS = '3+',
  MISSING = 'Missing',
}

enum MortgageType {
  NEW = 'new',
  INSURED = 'insured',
  REINSURED = 'reinsured',
}

enum BorrowerNumber {
  ONE = '1',
  TWO = '2',
  THREE_PLUS = '3+',
}

enum OutbuildingType {
  CELLAR = 'cellar',
  GARAGE = 'garage',
  ATTIC = 'attic',
  OTHER = 'other',
}

interface Outbuilding {
  name: string
  surface: number | null
  type: string
  address_main: string | null
  postcode: string | null
  city: string | null
  country: string | null
  country_code: string | null
  is_same_location: boolean
}

interface Beneficiary {
  last_name: string
  first_name: string
  type: Role
  email?: string
  birth_date?: string | null
}

interface Borrower {
  first_name: string
  last_name: string
  email: string
  phone: string
  birth_date: string
  gender: Gender
  is_smoker: boolean
  profession_category: string

  mortgage_quotity: number
  initial_loan_quotity: number
  address_main: string
  address_complement: string | null
  postcode: string
  city: string
  country: string
}

enum Purpose {
  MAIN = 'main',
  SECONDARY = 'secondary',
  INVESTMENT = 'investment',
}

enum ProspectType {
  INDIVIDUAL = 'individual',
  SCI = 'sci',
}

enum FurnishedStatus {
  FURNISHED = 'furnished',
  NOT_FURNISHED = 'not_furnished',
  MISSING = 'missing', // only to handle missing field from aggregators
}

export {
  OwnerType,
  Beneficiary,
  ValuableType,
  Outbuilding,
  ClaimNumber,
  MortgageType,
  BorrowerNumber,
  Borrower,
  Purpose,
  ProspectType,
  FurnishedStatus,
  OutbuildingType,
}
