const env = import.meta.env

const DOT_SEPARATOR = ' · '

const EMAIL_REGEX = /^[^@\s]{1,64}@[^@\s]{1,127}\.[^@\s]{2,63}$/

const REPORT_BUGS_URL = env.VITE_APP_REPORT_BUGS_URL
const LUKROME_EXTENSION_URL = env.VITE_APP_LUKROME_EXTENSION_URL
const API_DOCUMENTATION_URL = 'https://developer.luko.eu/'
const SUNSET_FAQ_URL =
  'https://luko.notion.site/Le-rachat-de-Luko-Real-Estate-par-Solly-Azar-05e73c22d50f4a19b63e870be0a61f98'

const TYPEFORM_CS_EN_ID = env.VITE_APP_TYPEFORM_CS_FR_ID
const TYPEFORM_CS_FR_ID = env.VITE_APP_TYPEFORM_CS_EN_ID
const TYPEFORM_PARTNER_ID = env.VITE_APP_TYPEFORM_PARTNER_ID

const DASHBOARD_FEEDBACK_TYPEFORM_ID = 'BZgll9Oi'
const REPORT_BUGS_TYPEFORM_ID = 'itkeUaHg'

const IS_DEV = env.VITE_IS_DEV === 'true'

const DEFAULT_DEDUCTIBLE = 225

const DATE_API_FORMAT = 'yyyy-MM-dd'
const DATE_DISPLAY_FORMAT = 'dd/MM/yyyy'
const DATE_WITHOUT_DAY_DISPLAY_FORMAT = 'MM/yyyy'

const MIN_NB_ROOMS = 1
const MAX_NB_ROOMS = 10
const MAX_NB_ROOMS_FR_LANDLORD_HOUSE = 5
const MAX_NB_ROOMS_FR_HOME_HOUSE = 7

const MAX_SIZE_HOUSE_FR = 400
const MIN_SIZE_HOUSE_FR = 40

const MAX_SIZE_FLAT_FR = 250
const MIN_SIZE_FLAT_FR = 5

const MAX_SIZE_LANDLORD_FR = 200
const MAX_SIZE_FR_HOME_HOUSE = 300

const MAX_SIZE_OUTBUILDING = 30

const MIN_SIZE_HOME_CONTENT_DE = 10
const MAX_SIZE_HOME_CONTENT_DE = 300

const MORTGAGE_LOAN_MAX_TOTAL_AMOUNT = 5000000
const MORTGAGE_LOAN_MIN_TOTAL_AMOUNT = 20000

const MORTGAGE_LOAN_MAX_RATE = 20
const MORTGAGE_LOAN_MAX_DEFERRAL_DURATION = 36

const MORTGAGE_LOAN_BRIDGE_MAX_DURATION = 36
const MORTGAGE_LOAN_INTEREST_ONLY_MAX_DURATION = 240
const MORTGAGE_LOAN_DEPRECIABLE_MAX_DURATION = 420

const MORTGAGE_LOAN_QUOTITY_MIN_AMOUNT = 20000

const MIN_NVEI_PRICE = 50
const MAX_NVEI_PRICE = 8000

export {
  DOT_SEPARATOR,
  EMAIL_REGEX,
  IS_DEV,
  DEFAULT_DEDUCTIBLE,
  DATE_API_FORMAT,
  REPORT_BUGS_URL,
  MIN_NB_ROOMS,
  MAX_NB_ROOMS,
  MAX_NB_ROOMS_FR_LANDLORD_HOUSE,
  MAX_SIZE_FLAT_FR,
  MIN_SIZE_FLAT_FR,
  MAX_SIZE_LANDLORD_FR,
  MAX_SIZE_HOUSE_FR,
  MIN_SIZE_HOUSE_FR,
  MAX_SIZE_OUTBUILDING,
  MIN_SIZE_HOME_CONTENT_DE,
  MAX_SIZE_HOME_CONTENT_DE,
  LUKROME_EXTENSION_URL,
  API_DOCUMENTATION_URL,
  MORTGAGE_LOAN_MAX_TOTAL_AMOUNT,
  MORTGAGE_LOAN_MIN_TOTAL_AMOUNT,
  MORTGAGE_LOAN_MAX_RATE,
  MORTGAGE_LOAN_MAX_DEFERRAL_DURATION,
  MORTGAGE_LOAN_BRIDGE_MAX_DURATION,
  MORTGAGE_LOAN_INTEREST_ONLY_MAX_DURATION,
  MORTGAGE_LOAN_DEPRECIABLE_MAX_DURATION,
  MORTGAGE_LOAN_QUOTITY_MIN_AMOUNT,
  MIN_NVEI_PRICE,
  MAX_NVEI_PRICE,
  DATE_DISPLAY_FORMAT,
  TYPEFORM_CS_EN_ID,
  TYPEFORM_CS_FR_ID,
  TYPEFORM_PARTNER_ID,
  DATE_WITHOUT_DAY_DISPLAY_FORMAT,
  DASHBOARD_FEEDBACK_TYPEFORM_ID,
  REPORT_BUGS_TYPEFORM_ID,
  SUNSET_FAQ_URL,
  MAX_NB_ROOMS_FR_HOME_HOUSE,
  MAX_SIZE_FR_HOME_HOUSE,
}
