enum ApiErrorStatusCode {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  FORBIDDEN = 403,
  INTERNAL_SERVER = 500,
  TIMEOUT = 504,
}

enum ApiErrorType {
  NOT_ELIGIBLE_TO_LINK_TO_CONTRACT = 'NotEligibleToLinkToContractError',
  MISSING_ACTIVATION_ERROR = 'MissingActivationError',
  ACTIVE_MANDATE_ALREADY_EXISTING = 'ActiveMandateAlreadyExisting',
  COMPLY_ADVANTAGE = 'ComplyAdvantageError',
  UNAUTHORIZED_IP_OPERATOR = 'UnauthorizedIPOperator',
  PROSPECT_LOCKED = 'ProspectLocked',
  INVALID_POLICY_ERROR = 'InvalidPolicyError',
  SIGNATURE_REQUEST_FOR_THE_PAST_ERROR = 'SignatureRequestForThePastError',
  ONBOARDING_TIME_EXPIRED_ERROR = 'OnboardingTimeExpiredError',
  START_DATE_REQUEST_IN_THE_PAST_ERROR = 'StartDateRequestInThePastError',
  USER_ALREADY_EXISTS = 'UserAlreadyExists',
}

interface IErrorInfo {
  status?: ApiErrorStatusCode
  message: string
  type: string
  api: boolean
  redirectTo?: string
  action?: Function
}

export { IErrorInfo, ApiErrorStatusCode, ApiErrorType }
