import { ref } from 'vue'

interface NotificationOptions {
  message?: string
  theme?: string
  icon?: Function
}

const NOTIFICATION_DURATION = 5000
const isNotificationDisplayed = ref(false)

const notificationOptions = ref<NotificationOptions>()
let timeout: ReturnType<typeof setTimeout>

const showNotification = (options: NotificationOptions) => {
  notificationOptions.value = options

  window.LukoTracking.trackEvent({
    id: 'Banner Displayed',
    properties: {
      message: notificationOptions.value.message,
      isError: notificationOptions.value.theme === 'danger',
    },
  })

  if (isNotificationDisplayed.value) hideNotification()
  isNotificationDisplayed.value = true

  timeout = setTimeout(hideNotification, NOTIFICATION_DURATION)
}

const hideNotification = () => {
  isNotificationDisplayed.value = false
  clearTimeout(timeout)
}

const useNotification = () => ({
  isNotificationDisplayed,
  notificationOptions,
  showNotification,
  hideNotification,
})

export { useNotification }
