import { Country } from './address'
import { ProductCode } from './product'
import { Acl } from './user'

interface PartnerConfig {
  allow_commission: boolean | null
  eligible_products: ProductCode[] | null
  eligible_product_funnel: ProductCode[] | null
  default_commission?: number
  default_formule: {
    id: number
    name: string
  }
  max_commission?: number
  min_commission?: number
  min_price: number | null
}

interface Broker {
  address: string | null
  address_complement_1: string | null
  address_complement_2: string | null
  broker_closing_date: string | null
  broker_code: string | null
  broker_created_date: string | null
  broker_id: string | null
  broker_last_updated_date: string | null
  broker_name: string | null
  city: string | null
  company_name: string
  contact_cellphone_number: string | null
  contact_fax_number: string | null
  contact_name: string | null
  contact_phone_number: string | null
  email_address: string
  email_address_2: string | null
  fax_number: string | null
  is_active: boolean
  legal_accountable_name: string | null
  orias_number: string | null
  phone_number: string | null
  postal_code: string | null
  rcs_city_number: string | null
}

interface Partner {
  id: number
  name: string
  logo_url: string
  industry: PartnerIndustry | null
  orias_number: string
  country: Country
  activity_timeline?: ActivityTimeline
  config: PartnerConfig | null
  formules: []
  type: PartnerType
  main_color: string
  workspace_id: number
  broker?: Broker
  default_coupon: string | null
  default_coupon_price_impact: string | null
  is_solicitation_disabled: boolean
  has_billing_info: boolean
  has_group_contract: boolean
  real_estate_top_agency?: boolean | null
  uuid: string
}

interface PricingConfig {
  degradations_limit_in_euros: number
  degradations_permyriad: number
  effort_rate_threshold_percentage: number
  group_contract_document_url: string
  group_contract_luko_id: string
  legal_protection_limit_in_euros: number
  legal_protection_permyriad: number
  mandatory_bundle_codes: string[]
  minimum_price_threshold_in_cents: number
  package_code: string
  product: number
  risk_carrier: 'MILA' | 'SADA'
  unpaid_rent_limit_in_euros: number
  unpaid_rent_permyriad: number
}

interface PartnerSelf extends Partner {
  billing_info: BillingInfo
  pricing_config: PricingConfig
}

interface BillingInfo {
  billing_address: string
  billing_city: string
  billing_country: string
  billing_emails: string[]
  billing_postcode: string
  corporate_name: string
  iban_mask: string
  channel: PartnerChannel
  legal_entity: string
  legal_representative_firstname: string
  legal_representative_lastname: string
  legal_representative_email: string
  siret?: string
  tva_number: string
}

interface ActivityTimeline {
  closed_steps: string[]
  completed_steps: string[]
  partner_id: number
  user_id: number
}
interface SimpleUserPartner {
  id: number
  config: PartnerConfig
  name: string
  logo_url: string
  acl: Acl
  industry: PartnerIndustry | null
  type: PartnerType
  workspace_id: number | null
  broker?: Broker
  products: ProductCode[]
}

enum PartnerId {
  LUKO = 1,
  CHECK24 = 6623,
  FRONT_TEST = 10020,
}

enum PartnerType {
  CONTRACT_PROVIDER = 'contract_provider',
  TRAFFIC_PROVIDER = 'traffic_provider',
  QUOTE_PROVIDER = 'quote_provider',
  SUBSCRIBER = 'subscriber',
  DISTRIBUTOR = 'distributor',
}

enum PartnerIndustry {
  REAL_ESTATE = 'REAL_ESTATE',
  OTHER = 'OTHER',
}

enum PartnerChannel {
  SMB = 'SMB',
  CallCenter = 'Call Center',
  Broker = 'Broker',
}

export {
  Partner,
  SimpleUserPartner,
  PartnerId,
  PartnerType,
  PartnerIndustry,
  PartnerSelf,
}
